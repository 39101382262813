import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import { UserUpsert, ProfileForm } from "@/store/user/actions";

/* api calls */

export const getUser = (): AxiosPromise => {
  return apiClient.get(`/user`);
};

export const deleteUser = (): AxiosPromise => {
  return apiClient.delete(`/user`);
};

export const getUserPicture = (): AxiosPromise => {
  return apiClient.get(`/user/picture`);
};

// includes override of apiClient content-type
export const updateUserPicture = (payload: FormData): AxiosPromise => {
  return apiClient.post(`/user/picture`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateUserOnboarding = (payload: UserUpsert): AxiosPromise => {
  return apiClient.post(`/user/onboarding/${payload.key}`, payload.data);
};

export const updateUserFlow = (payload: UserUpsert): AxiosPromise => {
  return apiClient.post(`/user/flow/${payload.key}`, payload.data);
};

export const updateUserProfile = (payload: ProfileForm): AxiosPromise => {
  return apiClient.post(`/user`, payload.data);
};

// called from auth
export const updateUserSettings = (payload: UserUpsert): AxiosPromise => {
  return apiClient.post(`/user/settings/${payload.key}`, payload.data);
};

export const bootstrapUser = (): AxiosPromise => {
  return apiClient.put(`/user/bootstrap`);
};
