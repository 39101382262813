// Mutations enums
export enum MutationTypes {
  SET_ACTIVE_CHALLENGES = "SET_ACTIVE_CHALLENGES",
  SET_RESOLVED_CHALLENGES = "SET_RESOLVED_CHALLENGES",
  SET_CHALLENGE_TYPES = "SET_CHALLENGE_TYPES",
  SET_SUBJECT_TYPES = "SET_SUBJECT_TYPES",
  SET_CHALLENGE_TAGS = "SET_CHALLENGE_TAGS",
  CLEAR_CHALLENGE_TAGS = "CLEAR_CHALLENGE_TAGS",
  SET_OUTCOME_TAGS_HELPS = "SET_OUTCOME_TAGS_HELPS",
  SET_OUTCOME_TAGS_PREVENTS = "SET_OUTCOME_TAGS_PREVENTS",
  SET_LOADING_ACTIVE_CHALLENGES = "SET_LOADING_ACTIVE_CHALLENGES",
  SET_LOADING_RESOLVED_CHALLENGES = "SET_LOADING_RESOLVED_CHALLENGES",
}
