
import { defineComponent, onBeforeMount, computed } from "vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import AppContent from "@/components/layout/app-content/AppContent.vue";

export default defineComponent({
  components: {
    AppContent,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const challengeId = computed(() => route.params.id);

    // The selected challenge is needed for all routes that use this view,
    // we request it here so that it is available everywhere.
    onBeforeMount(() => {
      store.commit("challenge/CLEAR_CHALLENGE");
      store.commit("sparks/CLEAR_SPARKS");
      store.commit("learning/CLEAR_LEARNING");
      store.dispatch("challenge/GET_CHALLENGE", challengeId.value);
    });

    return {};
  },
});
