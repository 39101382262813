
import { defineComponent, computed, reactive } from "vue";
import { useStore } from "@/store";
import { useModal } from "@/composables/useModal";
import UserSettingsItem from "./UserSettingsItem.vue";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";

export default defineComponent({
  components: {
    UserSettingsItem,
    ModalContent,
    Editorial,
    AppButton,
  },
  props: {
    logout: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    // modals
    const modalState = reactive({
      deleteAccount: false,
    });
    const { isShown, showModal, closeModal } = useModal(modalState);

    // delete user account
    const isDeletingUser = computed(() => store.state.user.isDeletingUser);

    const handleDeleteAccount = async () => {
      const deleted = await store.dispatch("user/DELETE_USER");
      if (deleted) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `USER_SETTING_DELETE_ACCOUNT`,
        });
        props.logout();
      } else {
        closeModal("deleteAccount");
      }
    };

    return {
      handleDeleteAccount,
      isShown,
      showModal,
      closeModal,
      isDeletingUser,
    };
  },
});
