import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { Notification, State as LocalState } from "./state";

// Getters types
export type Getters = {
  getNotifications(state: LocalState): Notification[];
  getUnreadNotifications(state: LocalState): Notification[];
  getTotalNotificationsUnread(
    state: LocalState,
    getters: { getUnreadNotifications(state: LocalState): Notification[] }
  ): number;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getNotifications: (state) => {
    return state.notifications;
  },

  getUnreadNotifications: (state) => {
    return state.notifications.filter(
      (notification) => notification?.read === false
    );
  },

  getTotalNotificationsUnread: (state, getters) => {
    return getters.getUnreadNotifications.length;
  },
};
