
import { defineComponent, computed, onMounted, ref } from "vue";
import { getDateOrdinal } from "@/utils/date";
import { useStore } from "@/store";
import { auth0 } from "@/auth";
import { useOnClickOutside } from "@/composables/useOnClickOutside";
import NotificationsIcon from "@/assets/images/icon-notifications.svg";

export default defineComponent({
  components: {
    NotificationsIcon,
  },
  setup() {
    const store = useStore();

    const toggleUnread = ref(false);

    const notifications = computed(() => {
      if (toggleUnread.value) {
        return store.getters["notifications/getUnreadNotifications"];
      } else {
        return store.getters["notifications/getNotifications"];
      }
    });

    const totalUnread = computed(
      () => store.getters["notifications/getTotalNotificationsUnread"],
    );

    const el = ref<HTMLElement | null>(null);

    const isOpen = ref(false);
    const toggleOpenState = () => {
      isOpen.value = !isOpen.value;

      if (isOpen.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `NOTIFICATIONS_MODAL_SHOW`,
        });
      }
    };

    const markAsRead = (id: string) => {
      store.dispatch("notifications/SET_NOTIFICATION_AS_READ", id);

      store.dispatch("tracking/TRACK_EVENT", {
        action: `NOTIFICATIONS_MARK_AS_READ`,
      });
    };

    const markAllRead = () => {
      store.dispatch("notifications/SET_ALL_NOTIFICATIONS_AS_READ");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `NOTIFICATIONS_MARK_ALL_AS_READ`,
      });
    };

    useOnClickOutside(el, () => {
      isOpen.value = false;
    });

    const timeout = ref();
    const getNotifications = () => {
      if (auth0.isAuthenticated.value) {
        store.dispatch("notifications/GET_NOTIFICATIONS");
      }
      window.clearTimeout(timeout.value);
      timeout.value = window.setTimeout(getNotifications, 30000);
    };

    onMounted(() => {
      getNotifications();
    });

    return {
      isAuthenticated: auth0.isAuthenticated,
      el,
      totalUnread,
      markAsRead,
      isOpen,
      toggleOpenState,
      notifications,
      toggleUnread,
      getDateOrdinal,
      markAllRead,
    };
  },
});
