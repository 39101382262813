
import { defineComponent, computed, ref, PropType } from "vue";
import ChallengesListDropdownFilterOption from "./ChallengesListDropdownFilterOption.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import DropdownArrowIcon from "@/assets/images/icon-dropdown-triangle.svg";
import { useOnClickOutside } from "@/composables/useOnClickOutside";

export default defineComponent({
  components: {
    ChallengesListDropdownFilterOption,
    DropdownArrowIcon,
    AppButton,
  },
  props: {
    filterTagOptions: {
      type: Object,
      required: false,
      default: null,
    },
    selectedFilterTagOptions: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    filterTopicOptions: {
      type: Object,
      required: false,
      default: null,
    },
    selectedFilterTopicOptions: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    filterSubjectOptions: {
      type: Object,
      required: false,
      default: null,
    },
    selectedFilterSubjectOptions: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    toggleClearFilter: {
      type: Function as PropType<() => void>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const el = ref<HTMLElement | null>(null);
    const isOpen = ref(false);
    const toggleOpenState = () => (isOpen.value = !isOpen.value);
    const close = () => (isOpen.value = false);

    const hasFilterTagOptions = computed(
      () => props.filterTagOptions.length > 0,
    );

    const hasFilterTopicOptions = computed(
      () => props.filterTopicOptions.length > 0,
    );

    const hasFilterSubjectOptions = computed(
      () => props.filterSubjectOptions.length > 0,
    );

    const hasFilter = computed(
      () =>
        props.selectedFilterTagOptions.length > 0 ||
        props.selectedFilterTopicOptions.length > 0 ||
        props.selectedFilterSubjectOptions.length > 0,
    );

    useOnClickOutside(el, () => {
      isOpen.value = false;
    });

    return {
      el,
      isOpen,
      close,
      toggleOpenState,
      hasFilterTagOptions,
      hasFilterTopicOptions,
      hasFilterSubjectOptions,
      hasFilter,
    };
  },
});
