import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", {
    ref: "textareaEl",
    class: "resize-none",
    value: _ctx.textareaValue,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (
      _ctx.$emit('update:modelValue', _ctx.handleTextareaChange($event)),
        (event) =>
          (_ctx.textareaValue = (event.currentTarget as HTMLTextAreaElement).value)
    ))
  }, null, 40, _hoisted_1))
}