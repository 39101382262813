import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mt-6 pt-16 pb-8 border-t-2 border-t-cebai-grey-light sm:border-t-0" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "md:flex md:justify-between" }
const _hoisted_5 = { class: "mb-6 md:mb-0" }
const _hoisted_6 = ["id", "href"]
const _hoisted_7 = { class: "text-sm text-cebai-grey-dark whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContentBlock = _resolveComponent("ContentBlock")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_ContentBlock, null, {
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.primaryLinks, (primaryLink) => {
            return (_openBlock(), _createElementBlock("li", {
              key: primaryLink.url,
              class: "mr-6 mb-1 inline-block text-lg"
            }, [
              _createElementVNode("a", {
                href: primaryLink.url,
                target: "_blank",
                class: "font-robotoBold text-cebai-grey-dark hover:text-cebai-base-dark"
              }, _toDisplayString(primaryLink.title), 9, _hoisted_3)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondaryLinks, (secondaryLink) => {
              return (_openBlock(), _createElementBlock("li", {
                key: secondaryLink.title,
                class: "mr-6 mb-1 inline-block text-sm"
              }, [
                (secondaryLink.internal)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: secondaryLink.name },
                      class: "text-cebai-grey-dark hover:text-cebai-base-dark"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(secondaryLink.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      id: secondaryLink.id || undefined,
                      href: secondaryLink.url,
                      target: "_blank",
                      class: "text-cebai-grey-dark hover:text-cebai-base-dark"
                    }, _toDisplayString(secondaryLink.title), 9, _hoisted_6))
              ]))
            }), 128))
          ]),
          _createElementVNode("p", _hoisted_7, " © " + _toDisplayString(_ctx.year) + " CebAI ", 1)
        ])
      ]),
      _: 1
    })
  ]))
}