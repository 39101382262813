import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Idea, Solution, Entity } from "./state";
import { Challenge } from "../challenges/state";

type IdeaFavorite = {
  _id: string;
  isFavorite: boolean;
};

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_LOADING](state: S, isLoading: boolean): void;
  [MutationTypes.SET_LOADING_CHALLENGE_TITLE](
    state: S,
    isLoadingChallengeTitle: boolean,
  ): void;
  [MutationTypes.SET_CHALLENGE_TITLE](state: S, challengeTitle: string): void;
  [MutationTypes.SET_DELETING](state: S, isDeleting: boolean): void;
  [MutationTypes.SET_EXAMPLE_BUTTON_SHOWN](state: S, isShown: boolean): void;
  [MutationTypes.SET_CHALLENGE](state: S, challenge: Challenge | null): void;
  [MutationTypes.CLEAR_CHALLENGE](state: S): void;
  [MutationTypes.ADD_IDEA](state: S, idea: Idea): void;
  [MutationTypes.ADD_SOLUTION](state: S, solution: Solution): void;
  [MutationTypes.SET_SOLUTION](state: S, solution: Solution): void;
  [MutationTypes.UPDATE_IDEA_FAVORITE](
    state: S,
    ideaFavorite: IdeaFavorite,
  ): void;
  [MutationTypes.SET_LOADING_PLAN](state: S, isLoadingPlan: boolean): void;
  [MutationTypes.SET_ENTITIES](state: S, entities: Entity[]): void;
  [MutationTypes.CLEAR_ENTITIES](state: S): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MutationTypes.SET_LOADING_CHALLENGE_TITLE](state, isLoadingChallengeTitle) {
    state.isLoadingChallengeTitle = isLoadingChallengeTitle;
  },
  [MutationTypes.SET_CHALLENGE_TITLE](state, challengeTitle) {
    if (state.challenge) {
      state.challenge.title = challengeTitle;
    }
  },
  [MutationTypes.SET_DELETING](state, isDeleting) {
    state.isDeleting = isDeleting;
  },
  [MutationTypes.SET_EXAMPLE_BUTTON_SHOWN](state, isShown) {
    state.isShownExampleButton = isShown;
  },
  [MutationTypes.SET_CHALLENGE](state, challenge) {
    state.challenge = challenge;
  },
  [MutationTypes.CLEAR_CHALLENGE](state) {
    state.challenge = null;
  },
  [MutationTypes.ADD_IDEA](state: LocalState, idea) {
    state.challenge?.ideas.unshift(idea);
  },
  [MutationTypes.ADD_SOLUTION](state: LocalState, solution) {
    state.challenge?.solutions.unshift(solution);
  },
  [MutationTypes.SET_SOLUTION](state: LocalState, solution) {
    if (state.challenge) {
      state.challenge.solutions[0] = solution;
    }
    return null;
  },
  [MutationTypes.UPDATE_IDEA_FAVORITE](state: LocalState, ideaFavorite) {
    if (!state.challenge) return;
    const ideaIndex = state.challenge.ideas.findIndex(
      (idea) => idea._id === ideaFavorite._id,
    );
    if (ideaIndex > -1) {
      state.challenge.ideas[ideaIndex].isFavorite = ideaFavorite.isFavorite;
    }
  },
  [MutationTypes.SET_LOADING_PLAN](state, isLoadingPlan) {
    state.isLoadingPlan = isLoadingPlan;
  },
  [MutationTypes.SET_ENTITIES](state, entities) {
    state.entities = entities;
  },
  [MutationTypes.CLEAR_ENTITIES](state) {
    state.entities = [];
  },
};
