
import { defineComponent, PropType, computed, ref } from "vue";
import { useStore } from "@/store";
import { UserData } from "@/store/user/state";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import UserProfileImage from "./UserProfileImage.vue";

export default defineComponent({
  components: {
    AppButton,
    UserProfileImage,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    user: {
      type: Object as PropType<UserData>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const inputFirstName = ref(props.user?.firstName || "");
    const inputLastName = ref(props.user?.lastName || "");
    const inputJobTitle = ref(props.user?.jobTitle || "");

    const isSavingProfile = computed(() => store.state.user.isSavingProfile);

    const submitIsDisabled = computed(() => {
      return !inputFirstName.value || !inputLastName.value;
    });

    const handleFormSubmit = async () => {
      await store.dispatch("user/UPDATE_USER_PROFILE", {
        data: {
          firstName: inputFirstName.value,
          lastName: inputLastName.value,
          jobTitle: inputJobTitle.value,
        },
      });
      props.closeModal();
      store.dispatch("tracking/TRACK_EVENT", {
        action: `USER_PROFILE_UPDATE`,
      });
    };

    return {
      inputFirstName,
      inputLastName,
      inputJobTitle,
      isSavingProfile,
      submitIsDisabled,
      handleFormSubmit,
    };
  },
});
