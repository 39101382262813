
import { defineComponent, onMounted, watch } from "vue";
import { useStore } from "@/store";
import { useAuth0 } from "@auth0/auth0-vue";
import TheHeader from "@/components/layout/the-header/TheHeader.vue";
import TheFooter from "@/components/layout/the-footer/TheFooter.vue";
import UserSignup from "@/components/content/user-signup/UserSignup.vue";
import Alert from "@/components/ui/alert/Alert.vue";
import CookieBanner from "@/components/ui/cookie-banner/CookieBanner.vue";

export default defineComponent({
  components: {
    TheHeader,
    TheFooter,
    UserSignup,
    CookieBanner,
    Alert,
  },
  setup() {
    const store = useStore();
    const auth0 = useAuth0();

    // When users log in via auth0, fetch their data from our API
    // (they may be logged in on initial load, or they may log in after)
    // this data is used in several places in the app so we request it once here
    watch(
      () => auth0.isAuthenticated.value,
      async (isLoggedIn, wasLoggedIn) => {
        if (isLoggedIn && !wasLoggedIn) {
          const user = await store.dispatch("user/GET_USER");
          if (!user) {
            store.dispatch("user/BOOTSTRAP_USER");
          }
        }
      },
      { immediate: true },
    );

    onMounted(() => {
      store.dispatch("tracking/UPDATE_TRACKING_STATE");
    });

    return {};
  },
});
