
import { defineComponent, computed, watch, reactive } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "@/store";
import { useModal } from "@/composables/useModal";
import UserSignupContent from "./UserSignupContent.vue";

export default defineComponent({
  components: {
    UserSignupContent,
  },
  setup() {
    const store = useStore();
    const auth0 = useAuth0();

    // modals
    const modalState = reactive({
      userSignup: false,
    });

    const { isShown, showModal, closeModal } = useModal(modalState);

    // force the user signup modal to ignore clicks on the outer background
    // or pressing the escape key
    const userSignupModalOptions = {
      transition: false,
      closeClickDimmed: false,
      closeKeyCode: false,
    };

    const userOnboarding = computed(
      () => store.getters["user/getUserOnboarding"],
    );
    const userId = computed(() => store.state.user.user?._id);
    const isLoadingUser = computed(() => store.state.user.isLoadingUser);

    // when a user logs in, check whether to display user signup modal
    const checkSignupState = async () => {
      // stop if the user hasn't loaded yet, if this is a guest user, or if
      // no user is returned (e.g. if there is an issue with the API/response)
      if (
        isLoadingUser.value ||
        !auth0.isAuthenticated.value ||
        !userId.value
      ) {
        return;
      }

      // if the user hasn't completed their profile, do that now
      // (users may have been bootstrapped but not completed their profile)
      const profileKey = "onboarded_profile";
      if (
        userOnboarding?.value &&
        (typeof userOnboarding?.value[profileKey] === "undefined" ||
          !userOnboarding?.value[profileKey])
      ) {
        showModal("userSignup");

        store.dispatch("tracking/TRACK_EVENT", {
          action: `USER_SIGNUP_START`,
        });
      }
    };

    // There are several different events that trigger the sign-up check:
    //  - when a user logs in via auth0
    //  - when the user request API has responded
    //  - when the user onboarding state has been updated
    watch(() => isLoadingUser.value, checkSignupState, { immediate: true });
    watch(() => userOnboarding.value, checkSignupState, { immediate: true });
    watch(() => auth0.isAuthenticated.value, checkSignupState, {
      immediate: true,
    });

    return {
      isShown,
      showModal,
      closeModal,
      userSignupModalOptions,
    };
  },
});
