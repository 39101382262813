import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-full p-6 flex items-center border-[3px] border-cebai-grey-lighter rounded-2xl" }
const _hoisted_2 = { class: "flex-1 text-lg mr-3" }
const _hoisted_3 = {
  key: 0,
  class: "text-base mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddIcon = _resolveComponent("AddIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_CreateIdea = _resolveComponent("CreateIdea")!
  const _component_ModalContent = _resolveComponent("ModalContent")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.spark.sparkText), 1),
      _createVNode(_component_AppButton, {
        id: `add-spark-${_ctx.index}`,
        type: "outline",
        padding: "narrow",
        onClick: _ctx.showIdeaModal
      }, {
        iconBefore: _withCtx(() => [
          _createVNode(_component_AddIcon, { class: "fill-current" })
        ]),
        _: 1
      }, 8, ["id", "onClick"])
    ]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShown.addIdea,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShown.addIdea) = $event)),
      close: () => _ctx.closeModal('addIdea')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ModalContent, {
          close: () => _ctx.closeModal('addIdea'),
          title: "Develop your idea"
        }, {
          default: _withCtx(() => [
            (!_ctx.isAuthenticated)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                  _createTextVNode(" Please "),
                  _createElementVNode("button", {
                    class: "text-cebai-primary font-robotoMedium",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSignUp && _ctx.toggleSignUp(...args)))
                  }, " sign up "),
                  _createTextVNode(" or "),
                  _createElementVNode("button", {
                    class: "text-cebai-primary font-robotoMedium",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleLogin && _ctx.toggleLogin(...args)))
                  }, " log in "),
                  _createTextVNode(" to start developing and saving ideas ")
                ]))
              : (_openBlock(), _createBlock(_component_CreateIdea, {
                  key: 1,
                  spark: _ctx.spark,
                  "close-modal": () => _ctx.closeModal('addIdea')
                }, null, 8, ["spark", "close-modal"]))
          ]),
          _: 1
        }, 8, ["close"])
      ]),
      _: 1
    }, 8, ["modelValue", "close"])
  ], 64))
}