import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Prompt } from "./state";
import { Spark } from "../sparks/state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_TRIZ_PROMPTS](state: S, trizPrompts: Prompt[]): void;
  [MutationTypes.SET_SCAMPER_PROMPTS](state: S, scamperPrompts: Prompt[]): void;
  [MutationTypes.SET_LEARNING_PROMPTS](
    state: S,
    learningPrompts: Spark[],
  ): void;
  [MutationTypes.SET_IDEATION_GUIDANCE](
    state: S,
    ideationGuidance: Prompt[],
  ): void;
  [MutationTypes.SET_REFINEMENT_GUIDANCE](
    state: S,
    refinementGuidance: Prompt[],
  ): void;
  [MutationTypes.SET_SPARKS_GUIDANCE](state: S, sparksGuidance: Prompt[]): void;
  [MutationTypes.SET_LEARNING_GUIDANCE](
    state: S,
    learningGuidance: Prompt[],
  ): void;
  [MutationTypes.SET_IDEAS_GUIDANCE](state: S, ideasGuidance: Prompt[]): void;
  [MutationTypes.SET_PLAN_GUIDANCE](state: S, planGuidance: Prompt[]): void;
  [MutationTypes.SET_LOADING_TRIZ_PROMPTS](
    state: S,
    isLoadingTrizPrompts: boolean,
  ): void;
  [MutationTypes.SET_LOADING_SCAMPER_PROMPTS](
    state: S,
    isLoadingScamperPrompts: boolean,
  ): void;
  [MutationTypes.SET_LOADING_LEARNING_PROMPTS](
    state: S,
    isLoadingLearningPrompts: boolean,
  ): void;
  [MutationTypes.CLEAR_TRIZ_PROMPTS](state: S): void;
  [MutationTypes.CLEAR_SCAMPER_PROMPTS](state: S): void;
  [MutationTypes.CLEAR_LEARNING_PROMPTS](state: S): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_TRIZ_PROMPTS](state, trizPrompts) {
    state.trizPrompts = trizPrompts;
  },
  [MutationTypes.SET_SCAMPER_PROMPTS](state, scamperPrompts) {
    state.scamperPrompts = scamperPrompts;
  },
  [MutationTypes.SET_LEARNING_PROMPTS](state, learningPrompts) {
    state.learningPrompts = learningPrompts;
  },
  [MutationTypes.SET_IDEATION_GUIDANCE](state, ideationGuidance) {
    state.ideationGuidance = ideationGuidance;
  },
  [MutationTypes.SET_REFINEMENT_GUIDANCE](state, refinementGuidance) {
    state.refinementGuidance = refinementGuidance;
  },
  [MutationTypes.SET_SPARKS_GUIDANCE](state, sparksGuidance) {
    state.sparksGuidance = sparksGuidance;
  },
  [MutationTypes.SET_LEARNING_GUIDANCE](state, learningGuidance) {
    state.learningGuidance = learningGuidance;
  },
  [MutationTypes.SET_IDEAS_GUIDANCE](state, ideasGuidance) {
    state.ideasGuidance = ideasGuidance;
  },
  [MutationTypes.SET_PLAN_GUIDANCE](state, planGuidance) {
    state.planGuidance = planGuidance;
  },
  [MutationTypes.SET_LOADING_TRIZ_PROMPTS](state, isLoadingTrizPrompts) {
    state.isLoadingTrizPrompts = isLoadingTrizPrompts;
  },
  [MutationTypes.SET_LOADING_SCAMPER_PROMPTS](state, isLoadingScamperPrompts) {
    state.isLoadingScamperPrompts = isLoadingScamperPrompts;
  },
  [MutationTypes.SET_LOADING_LEARNING_PROMPTS](
    state,
    isLoadingLearningPrompts,
  ) {
    state.isLoadingLearningPrompts = isLoadingLearningPrompts;
  },
  [MutationTypes.CLEAR_TRIZ_PROMPTS](state) {
    state.trizPrompts = [];
  },
  [MutationTypes.CLEAR_SCAMPER_PROMPTS](state) {
    state.scamperPrompts = [];
  },
  [MutationTypes.CLEAR_LEARNING_PROMPTS](state) {
    state.learningPrompts = [];
  },
};
