
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    starred: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
