
import { defineComponent, ref, reactive, PropType, computed } from "vue";
import { useStore } from "@/store";
import { Idea } from "@/store/challenge/state";
import { useOnClickOutside } from "@/composables/useOnClickOutside";
import { useModal } from "@/composables/useModal";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import ActionMenuIcon from "@/assets/images/icon-action-menu.svg";

export default defineComponent({
  components: {
    ModalContent,
    Editorial,
    AppButton,
    ActionMenuIcon,
  },
  props: {
    idea: {
      type: Object as PropType<Idea>,
      required: true,
    },
    expandIdea: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const modalState = reactive({
      deleteIdea: false,
    });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const showDeleteIdeaModal = () => {
      showModal("deleteIdea");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `IDEA_DELETE_MODAL_SHOW`,
      });
    };

    const handleExpandIdea = () => {
      props.expandIdea();
      close();
    };

    const handleDeleteIdea = () => {
      if (!props.idea) return;
      store.dispatch("challenge/DELETE_IDEA", props.idea);
      closeModal("deleteIdea");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `IDEA_DELETE`,
      });
    };

    const el = ref<HTMLElement | null>(null);
    const isOpen = ref(false);
    const toggleOpenState = () => (isOpen.value = !isOpen.value);
    const close = () => (isOpen.value = false);

    useOnClickOutside(el, () => {
      isOpen.value = false;
    });

    const challengeIsActive = computed(
      () => store.getters["challenge/getIsActive"],
    );

    return {
      showDeleteIdeaModal,
      handleExpandIdea,
      handleDeleteIdea,
      isShown,
      showModal,
      closeModal,
      el,
      toggleOpenState,
      isOpen,
      close,
      challengeIsActive,
    };
  },
});
