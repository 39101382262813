
import { defineComponent, computed, watch, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "@/store";
import ChallengeNavigation from "@/components/content/challenge-navigation/ChallengeNavigation.vue";
import ChallengeHeader from "@/components/content/challenge-header/ChallengeHeader.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import Ribbon from "@/components/ui/ribbon/Ribbon.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import ArrowRightIcon from "@/assets/images/icon-arrow-right.svg";

export default defineComponent({
  components: {
    ContentBlock,
    ChallengeNavigation,
    ChallengeHeader,
    Ribbon,
    Tabs,
    AppButton,
    AppButtonRouterLink,
    ArrowRightIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const auth0 = useAuth0();
    const store = useStore();

    const challenge = computed(() => store.getters["challenge/getChallenge"]);
    const challengeType = computed(() => route.meta.challengeType);

    const tabs = computed(() => [
      {
        slug: "sparks",
        label: "Sparks",
        route: {
          name: `challenge-${challengeType.value}-inspiration-sparks`,
        },
      },
      {
        slug: "learning",
        label: "Learning",
        route: {
          name: `challenge-${challengeType.value}-inspiration-learning`,
        },
      },
    ]);

    const redirectToDefaultTab = () => {
      if (route.name === `challenge-${challengeType.value}-inspiration`) {
        router.replace({
          name: `challenge-${challengeType.value}-inspiration-sparks`,
        });
      }
    };

    // ensure that a tab is displayed by selecting the first as default
    watch(() => route.name, redirectToDefaultTab, { immediate: true });

    const activeTab = computed(() => {
      const routeName = (route.name as string) || "";
      return routeName.substring(routeName.indexOf("-"));
    });

    const toggleSignUp = async () => {
      auth0.loginWithRedirect({
        authorizationParams: { action: "signup" },
        appState: { target: route.fullPath },
      });
    };

    const toggleLogin = async () => {
      auth0.loginWithRedirect({
        appState: { target: route.fullPath },
      });
    };

    const ideaCount = computed(() => store.getters["challenge/getIdeaCount"]);
    const challengeIsResolved = computed(
      () => store.getters["challenge/getIsResolved"],
    );

    // don't allow resolved challenges to access inspiration
    watch(
      challengeIsResolved,
      () => {
        if (challengeIsResolved.value) {
          router.replace({ name: "challenge-saved-ideas" });
        }
      },
      { immediate: true },
    );

    // If an authenticated user is accessing the 'new' route, they have logged
    // in after entering a challenge in the initial form.
    // Save their challenge and redirect them to the saved challenge route
    onBeforeMount(async () => {
      const isAuthenticated = await auth0.isAuthenticated.value;
      if (isAuthenticated && challengeType.value === "new") {
        const id = await store.dispatch(
          "challenges/ADD_CHALLENGE",
          challenge.value,
        );
        if (id) {
          router.push({
            name: `challenge-saved`,
            params: { id },
          });
        }
      }
    });

    return {
      isAuthenticated: auth0.isAuthenticated,
      challenge,
      tabs,
      activeTab,
      toggleSignUp,
      toggleLogin,
      ideaCount,
    };
  },
});
