import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState, PossibleIdea } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { getPossibleIdeas } from "@/services/possible-ideas.service";
import { captureException } from "@/utils/errors";
import axios from "axios";

export type PossibleIdeasPayload = {
  challenge: string;
  spark: string;
  idea: string;
  object: string;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_POSSIBLE_IDEAS](
    { commit }: AugmentedActionContext,
    payload: PossibleIdeasPayload
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_POSSIBLE_IDEAS](
    { commit, dispatch },
    payload: PossibleIdeasPayload
  ) {
    commit(MutationTypes.SET_LOADING_POSSIBLE_IDEAS, true);
    commit(MutationTypes.CLEAR_POSSIBLE_IDEAS);

    return getPossibleIdeas(payload)
      .then((response) => {
        const ideas: PossibleIdea[] = response.data.results;
        commit(MutationTypes.SET_POSSIBLE_IDEAS, ideas);
        commit(MutationTypes.SET_LOADING_POSSIBLE_IDEAS, false);
      })
      .catch((error) => {
        // ignore if failure is a cancellation (loading continues)
        if (!axios.isCancel(error)) {
          captureException(error);
          dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", {
            root: true,
          });
          commit(MutationTypes.SET_LOADING_POSSIBLE_IDEAS, false);
        }
      });
  },
};
