// see:
// https://dev.to/shubhadip/vue-3-vuex-4-modules-typescript-2i2o

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import {
  messages,
  Store as MessagesStore,
  State as MessagesState,
} from "./messages";
import {
  cookies,
  Store as CookiesStore,
  State as CookiesState,
} from "./cookies";
import {
  tracking,
  Store as TrackingStore,
  State as TrackingState,
} from "./tracking";
import {
  challenges,
  Store as ChallengesStore,
  State as ChallengesState,
} from "./challenges";
import {
  challenge,
  Store as ChallengeStore,
  State as ChallengeState,
} from "./challenge";
import {
  learning,
  Store as LearningStore,
  State as LearningState,
} from "./learning";
import {
  caseStudy,
  Store as CaseStudyStore,
  State as CaseStudyState,
} from "./case-study";
import { sparks, Store as SparksStore, State as SparksState } from "./sparks";
import {
  ideaSparks,
  Store as IdeaSparksStore,
  State as IdeaSparksState,
} from "./idea-sparks";
import {
  guidedTour,
  Store as GuidedTourStore,
  State as GuidedTourState,
} from "./guided-tour";
import { user, Store as UserStore, State as UserState } from "./user";
import {
  notifications,
  Store as NotificationsStore,
  State as NotificationsState,
} from "./notifications";
import {
  possibleIdeas,
  Store as PossibleIdeasStore,
  State as PossibleIdeasState,
} from "./possible-ideas";

export type State = {
  messages: MessagesState;
  cookies: CookiesState;
  tracking: TrackingState;
  challenges: ChallengesState;
  challenge: ChallengeState;
  learning: LearningState;
  caseStudy: CaseStudyState;
  sparks: SparksState;
  ideaSparks: IdeaSparksState;
  guidedTour: GuidedTourState;
  user: UserState;
  notifications: NotificationsState;
  possibleIdeas: PossibleIdeasState;
};

export type Store = CookiesStore<Pick<State, "cookies">> &
  TrackingStore<Pick<State, "tracking">> &
  MessagesStore<Pick<State, "messages">> &
  ChallengesStore<Pick<State, "challenges">> &
  ChallengeStore<Pick<State, "challenge">> &
  LearningStore<Pick<State, "learning">> &
  CaseStudyStore<Pick<State, "caseStudy">> &
  SparksStore<Pick<State, "sparks">> &
  IdeaSparksStore<Pick<State, "ideaSparks">> &
  GuidedTourStore<Pick<State, "guidedTour">> &
  UserStore<Pick<State, "user">> &
  NotificationsStore<Pick<State, "notifications">> &
  PossibleIdeasStore<Pick<State, "possibleIdeas">>;

// persisted data (kept in localStorage)
const persistedState = createPersistedState({
  paths: ["cookies", "challenge"],
});

export const store = createStore({
  modules: {
    messages,
    cookies,
    tracking,
    challenges,
    challenge,
    learning,
    caseStudy,
    sparks,
    ideaSparks,
    guidedTour,
    user,
    notifications,
    possibleIdeas,
  },
  plugins: [persistedState],
});

export function useStore(): Store {
  return store as Store;
}

export default store;
