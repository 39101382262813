import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_LogoMobile = _resolveComponent("LogoMobile")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'home' },
    class: "flex items-center lg:mr-6 transition-all"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Logo, { class: "md:mr-3 hidden md:block" }),
      _createVNode(_component_LogoMobile, { class: "md:mr-3 md:hidden" })
    ]),
    _: 1
  }))
}