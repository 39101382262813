
import { defineComponent, computed } from "vue";
import MarkdownIt from "markdown-it";

export default defineComponent({
  components: {},
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const md = new MarkdownIt({
      breaks: true,
    });
    const content = computed(() => md.render(props.source));
    return {
      content,
    };
  },
});
