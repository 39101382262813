import type { StepEntity } from "v-onboarding/src/types/StepEntity";

export type GuidedTourData = {
  [key: string]: StepEntity[];
};

// Declare state
export type State = {
  startTour: boolean;
};

// Create initial state
export const state = {
  startTour: false,
} as State;
