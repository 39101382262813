
import { defineComponent, computed, inject, provide, ref } from "vue";

export default defineComponent({
  components: {},
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // get the current active tab
    const activeTab = inject("activeTab", ref(""));

    // check if the current tab is the active tab
    const isActiveTab = computed(() => activeTab.value === props.slug);

    // allow child components to detect whether this is the active tab
    provide("isActiveTab", isActiveTab);

    return {
      isActiveTab,
    };
  },
});
