
import { defineComponent, PropType, computed, onMounted } from "vue";
import { useStore } from "@/store";
import { Spark } from "@/store/sparks/state";
import { PossibleIdeasPayload } from "@/store/possible-ideas/actions";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import RefreshIcon from "@/assets/images/icon-refresh.svg";

export default defineComponent({
  components: {
    AppButton,
    LoadingIcon,
    RefreshIcon,
  },
  props: {
    spark: {
      type: Object as PropType<Spark>,
      required: true,
    },
    hasInitialDescription: {
      type: Boolean,
      required: true,
    },
    adoptPossibleIdea: {
      type: Function,
      required: true,
    },
    handleResetDescription: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isLoading = computed(
      () => store.state.possibleIdeas.isLoadingPossibleIdeas,
    );

    const possibleIdeas = computed(() => store.state.possibleIdeas.ideas);

    const fetchPossibleIdeas = () => {
      const challenge = store.state.challenge.challenge?.description || "";

      // NB we only need the object property for Imagine (constraint) sparks
      const payload: PossibleIdeasPayload = {
        challenge,
        spark: props.spark?.stem.spark_content,
        idea: props.spark.idea,
        object: props.spark.origin === "constraint" ? props.spark.object : "",
      };
      store.dispatch("possibleIdeas/GET_POSSIBLE_IDEAS", payload);
    };

    onMounted(() => {
      fetchPossibleIdeas();
    });

    return {
      isLoading,
      fetchPossibleIdeas,
      possibleIdeas,
    };
  },
});
