
import { defineComponent } from "vue";
import FlatPickr from "vue-flatpickr-component";

export default defineComponent({
  components: {
    FlatPickr,
  },
  props: {
    targetDate: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    // To enable expired target dates to be shown
    const minDate =
      props.targetDate && Date.now() >= new Date(props.targetDate).getTime()
        ? props.targetDate
        : "today";

    const config = {
      altFormat: "J F Y",
      altInput: true,
      dateFormat: "Y-m-d",
      disableMobile: true,
      minDate,
    };

    return {
      config,
    };
  },
});
