export type SparkType = "problem" | "constraint" | "solution";

export type SparksTab = {
  label: string;
  slug: string;
  sparkType: SparkType;
};

export const sparksTabs: SparksTab[] = [
  {
    label: "Explore",
    slug: "explore",
    sparkType: "problem",
  },
  {
    label: "Imagine",
    slug: "imagine",
    sparkType: "constraint",
  },
  {
    label: "Solve",
    slug: "solve",
    sparkType: "solution",
  },
];

export type Spark = {
  origin: string;
  text: string;
  idea: string;
  sparkText: string;
  guidances: string[];
  stem: Record<string, string>;
  object: string;
};

export type Sparks = {
  problem: Spark[];
  constraint: Spark[];
  solution: Spark[];
  [key: string]: Spark[];
};

export type IsLoading = {
  problem: boolean;
  constraint: boolean;
  solution: boolean;
  [key: string]: boolean;
};

export type HasError = {
  problem: boolean;
  constraint: boolean;
  solution: boolean;
  [key: string]: boolean;
};

// Declare state
export type State = {
  sparks: Sparks;
  isLoading: IsLoading;
  hasError: HasError;
};

// Create initial state
export const state = {
  sparks: {
    problem: [],
    constraint: [],
    solution: [],
  },
  isLoading: {
    problem: false,
    constraint: false,
    solution: false,
  },
  hasError: {
    problem: false,
    constraint: false,
    solution: false,
  },
} as State;
