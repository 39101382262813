import { Challenge } from "@/store/challenges/state";
import { Idea } from "@/store/challenge/state";
import { OutputType } from "@/services/pdf.service";
import { captureException } from "@/utils/errors";
import { useStore } from "@/store";

// Download PDF to downloads
// Adapted from https://stackoverflow.com/a/52829183
export const downloadPdfFile = (file: Blob, fileName: string) => {
  const fileURL = URL.createObjectURL(file);
  const openWindow = window.open(fileURL);

  // if window.open blocked, do direct download
  if (!openWindow) {
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;

    // some browsers need the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
  }

  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(fileURL), 7000);
};

// Copy to clipboard using the Clipboard API
export const copyToClipboard = async (
  challenge: Challenge,
  ideas: Idea[],
  outputType: OutputType,
) => {
  const { ClipboardItem } = window;
  const store = useStore();

  try {
    // ClipboardItem non-standard in Firefox (11/01/2024) - use clipboard.writeText
    if (ClipboardItem) {
      const htmlString = buildHtmlString(challenge, ideas, outputType);
      const blobInput = new Blob([htmlString], { type: "text/html" });
      const clipboardInput = new ClipboardItem({ "text/html": blobInput });
      await navigator.clipboard.write([clipboardInput]);
    } else {
      const textString = buildTextString(challenge, ideas, outputType);
      await navigator.clipboard.writeText(textString);
    }
    store.dispatch("messages/ADD_INFO_MESSAGE", "COPIED_TO_CLIPBOARD", {
      root: true,
    });
  } catch (error) {
    // NB error fires for old browsers without the Clipboard API
    captureException(error);
    store.dispatch("messages/ADD_ERROR_MESSAGE", "ERROR_CLIPBOARD", {
      root: true,
    });
  }
};

const buildTextString = (
  challenge: Challenge,
  ideas: Idea[],
  outputType: OutputType,
) => {
  let textString = "";

  // Add heading and date section
  textString += `SPORT SPARKS Action Plan\nDate and time: ${new Date().toLocaleString()}\n\n`;

  // Add challenge section
  const createdAt = challenge.createdAt
    ? new Date(challenge.createdAt).toLocaleString()
    : undefined;
  const updatedAt = challenge.updatedAt
    ? new Date(challenge.updatedAt).toLocaleString()
    : undefined;
  const subject = challenge.subject
    .split(", ")
    .map((a) => a.charAt(0).toUpperCase() + a.slice(1))
    .join(", ");

  textString += "YOUR CHALLENGE\n"; // heading
  textString += `Description: ${challenge.description}\n\n`;
  textString += `Subject(s): ${subject}\n\n`;
  textString += `Type(s): ${challenge.types.join(", ")}\n\n`;
  textString += `Tag(s): ${challenge.tags.join(", ")}\n\n`;
  textString += `Challenge created: ${createdAt}\n`;
  textString += `Last updated: ${updatedAt}\n\n`;

  if (outputType === "ideas") {
    // Add ideas section
    textString += "YOUR IDEAS\n"; // heading
    if (ideas && ideas.length) {
      const ideaDetails = ideas.map(
        (idea) =>
          `${idea.isFavorite ? "* " : ""}${idea.description} ${
            idea.notes !== "" ? "(Notes: " + idea.notes + ")" : ""
          }\n`,
      );
      textString += `${ideaDetails.join("\n")}\n`;
    } else {
      textString += "No ideas added yet.\n\n";
    }
  } else {
    // Add ideas section
    textString += "YOUR SELECTED IDEAS\n"; // heading
    if (ideas && ideas.length) {
      const ideaDetails = ideas.map(
        (idea) =>
          `${idea.isFavorite ? "* " : ""}${idea.description} ${
            idea.notes !== "" ? "(Notes: " + idea.notes + ")" : ""
          }\n`,
      );
      textString += `${ideaDetails.join("\n")}\n`;
    } else {
      textString += "No ideas added yet.\n\n";
    }

    // Add solution section (if it exists)
    if (challenge.solutions && challenge.solutions.length) {
      const targetDate =
        challenge.solutions[0].targetDate &&
        challenge.solutions[0].targetDate !== ""
          ? new Date(challenge.solutions[0].targetDate).toLocaleDateString(
              "en-GB",
            )
          : "";

      const confidenceLevel =
        challenge.solutions[0].confidenceLevel &&
        challenge.solutions[0].confidenceLevel !== -1
          ? challenge.solutions[0].confidenceLevel + "%"
          : "";

      textString += "YOUR PLAN\n"; // heading
      textString += `My action plan for this challenge is: ${challenge.solutions[0].description}\n\n`;
      textString += `How I'll know it worked: ${
        challenge.solutions[0].successCriteria || ""
      }\n\n`;
      textString += `Your confidence level: ${confidenceLevel}\n\n`;
      textString += `Things that help the outcome: ${challenge.solutions[0].helpsOutcome.join(
        ", ",
      )}\n\n`;
      textString += `Things that prevent the outcome: ${challenge.solutions[0].preventsOutcome.join(
        ", ",
      )}\n\n`;
      textString += `When I'll implement it by: ${targetDate}\n\n`;
    }

    // Add resolution section (if it exists - see if rating exists)
    if (challenge.resolution && challenge.resolution.rating) {
      const rating = getRating(challenge.resolution.rating);

      textString += "YOUR RESOLUTION\n"; // heading
      textString += `Your solution rating: ${rating}\n\n`;
      textString += `Your reflections: ${challenge.resolution.reflection}\n\n`;
      textString += `What went well: ${challenge.resolution.positives}\n\n`;
      textString += `What didn't go so well: ${challenge.resolution.negatives}\n\n`;
    }
  }

  return textString;
};

const buildHtmlString = (
  challenge: Challenge,
  ideas: Idea[],
  outputType: OutputType,
) => {
  let htmlString = `<body>`; // define opening html string

  // Add heading and date section
  htmlString += `<h1><b>Sport Sparks Action Plan</b></h1>
    <p>Date and time: ${new Date().toLocaleString()}</p>`;

  // Add challenge section
  const createdAt = challenge.createdAt
    ? new Date(challenge.createdAt).toLocaleString()
    : undefined;
  const updatedAt = challenge.updatedAt
    ? new Date(challenge.updatedAt).toLocaleString()
    : undefined;
  const subject = challenge.subject
    .split(", ")
    .map((a) => a.charAt(0).toUpperCase() + a.slice(1))
    .join(", ");

  const challengeRows = [
    { header: "Description", content: challenge.description },
    { header: "Subject(s)", content: subject },
    { header: "Type(s)", content: challenge.types.join(", ") },
    { header: "Tag(s)", content: challenge.tags.join(", ") },
    { header: "Challenge created", content: createdAt },
    { header: "Last updated", content: updatedAt },
  ];

  htmlString += `<h2><b>Your challenge</b></h2>`; // heading
  htmlString += toTableString(challengeRows); // build challenge table

  if (outputType === "ideas") {
    // Add ideas section
    htmlString += `<h2><b>Your ideas</b></h2>`; // heading
    if (ideas && ideas.length) {
      htmlString += toIdeasTableString(ideas); // build ideas table
    } else {
      htmlString += `<p>No ideas added yet.</p>`;
    }
  } else {
    // Add ideas section
    htmlString += `<h2><b>Your selected ideas</b></h2>`; // heading
    if (ideas && ideas.length) {
      htmlString += toIdeasTableString(ideas); // build ideas table
    } else {
      htmlString += `<p>No ideas added yet.</p>`;
    }

    // Add plan section (if it exists)
    if (challenge.solutions && challenge.solutions.length) {
      const targetDate =
        challenge.solutions[0].targetDate &&
        challenge.solutions[0].targetDate !== ""
          ? new Date(challenge.solutions[0].targetDate).toLocaleDateString(
              "en-GB",
            )
          : "";

      // replace hardcoded line returns in the plan text with html
      const planDescription = challenge.solutions[0].description
        .split("\n")
        .join("<br>");

      const solutionRows = [
        {
          header: "How I'll know it worked",
          content: challenge.solutions[0].successCriteria || "",
        },
        {
          header: "Your confidence level",
          content:
            challenge.solutions[0].confidenceLevel &&
            challenge.solutions[0].confidenceLevel !== -1
              ? challenge.solutions[0].confidenceLevel + "%"
              : "",
        },
        {
          header: "Things that help the outcome",
          content: challenge.solutions[0].helpsOutcome.join(", "),
        },
        {
          header: "Things that prevent the outcome",
          content: challenge.solutions[0].preventsOutcome.join(", "),
        },
        { header: "When I'll implement it by", content: targetDate },
      ];

      htmlString += `<h2><b>Your plan</b></h2>`; // heading
      htmlString += `<p><b>My action plan for this challenge is...</b></p>`; // sub-heading
      htmlString += `<p>${planDescription}</p><br>`; // plan text
      htmlString += toTableString(solutionRows); // build plan attributes table
    }

    // Add resolution section (if it exists - see if rating exists)
    if (challenge.resolution && challenge.resolution.rating) {
      const rating = getRating(challenge.resolution.rating);
      const resolutionRows = [
        { header: "Your solution rating", content: rating },
        {
          header: "Your reflections",
          content: challenge.resolution.reflection,
        },
        {
          header: "What went well",
          content: challenge.resolution.positives,
        },
        {
          header: "What didn't go so well",
          content: challenge.resolution.negatives,
        },
      ];

      htmlString += `<h2><b>Your resolution</b></h2>`; // heading
      htmlString += toTableString(resolutionRows); // build resolution table
    }
  }

  // End html
  htmlString += `</body>`;

  return htmlString;
};

type tableRow = {
  header: string;
  content: string | undefined;
};

const toTableString = (tableRows: tableRow[]): string => {
  let tableString = `<table width="100%"><tbody>`;

  tableRows.forEach((row: tableRow) => {
    tableString += `<tr>
    <td width="40%" style="vertical-align: top;padding:5px;"><b>${
      row.header
    }</b></td>
    <td width="60%" style="vertical-align: top;padding:5px;">${
      row.content ? row.content : "Not specified"
    }</td>
    </tr>`;
  });

  tableString += "</tbody></table>";

  return tableString;
};

const toIdeasTableString = (ideas: Idea[]): string => {
  let tableString = `<table><tbody>`;

  ideas.forEach((idea: Idea) => {
    tableString += `<tr>
      <td style="border-bottom: 1px solid gray;vertical-align: top;padding:5px;">
        ${idea.isFavorite ? "* " : ""}
        ${idea.description}
        ${idea.notes !== "" ? "<br><br>Notes: " + idea.notes : ""}
      </td>
    </tr>`;
  });
  tableString += "</tbody></table>";

  return tableString;
};

const getRating = (rating: number): string => {
  switch (rating) {
    case 1:
      return "Negative";
    case 2:
      return "Neutral";
    case 3:
      return "Positive";
    default:
      return "";
  }
};
