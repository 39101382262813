
import {
  defineComponent,
  provide,
  computed,
  ref,
  toRef,
  PropType,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
} from "vue";
import TabComponent from "./Tab.vue";
import ExpandIcon from "@/assets/images/icon-expand.svg";
import { UpdateActiveTab, TabType } from "@/types/components";
import { RouteLocationRaw } from "vue-router";

type Tab = {
  slug: string;
  label: string;
  route?: RouteLocationRaw;
};

export default defineComponent({
  components: {
    TabComponent,
    ExpandIcon,
  },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: false,
      default: null,
    },
    centred: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabType: {
      type: String as PropType<TabType>,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: true,
    },
    usesRouter: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasContent: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    // if the tabs use the (external) router to navigate, we will receive the
    // active tab updates via props.
    //
    // if the tabs maintain their own internal state, we only want to use
    // the active tab prop to set the initial value rather than tracking it as
    // a reactive prop
    const activeTab = props.usesRouter
      ? toRef(props, "activeTab")
      : ref(props.activeTab);

    const updateActiveTab: UpdateActiveTab = (slug) => {
      if (props.usesRouter) return;
      activeTab.value = slug;
    };

    // responsive tabs, loosely based on:
    // https://blog.logrocket.com/how-to-use-the-resizeobserver-api-a-tutorial-with-examples/
    // https://codepen.io/kevinleedrum/pen/ExmdYNN
    const isMenuOpen = ref(false);

    const menuLinks = ref<Tab[]>([]);
    const tabLinks = computed(
      () => props.tabs?.filter((tab) => !menuLinks.value.includes(tab)),
    );

    const resizeObserver = ref<ResizeObserver | null>(null);
    const containerEl = ref<HTMLElement | null>(null);

    // if the active tab is in the dropdown menu, create an initial tab instead
    const firstTab = computed(() =>
      menuLinks.value.find((menuLink) => menuLink.slug === activeTab.value),
    );

    // update tabs when the selected tab changes
    watch(activeTab, () => {
      requestAnimationFrame(updateTabs);
    });

    // update tabs on window resize
    const onResize = () => {
      requestAnimationFrame(updateTabs);
    };

    const updateTabs = async () => {
      // Place all buttons outside the menu
      if (menuLinks.value.length) {
        menuLinks.value = [];
        await nextTick();
      }

      const isOverflowing = () =>
        containerEl.value
          ? containerEl.value.scrollWidth > containerEl.value.offsetWidth
          : false;

      // Move buttons into the menu until the container is no longer overflowing
      while (isOverflowing() && tabLinks.value?.length) {
        const lastActionButton = tabLinks.value[tabLinks.value.length - 1];
        menuLinks.value.unshift(lastActionButton);
        await nextTick();
      }
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const closeMenu = () => {
      isMenuOpen.value = false;
    };

    onMounted(() => {
      if (containerEl.value) {
        // Attach ResizeObserver to the container
        resizeObserver.value = new ResizeObserver(onResize);
        resizeObserver.value.observe(containerEl.value);

        // Close the menu on any click
        document.addEventListener("click", closeMenu);
      }
    });

    onBeforeUnmount(() => {
      // Clean up the observer and event listener
      resizeObserver.value?.disconnect();
      document.removeEventListener("click", closeMenu);
    });

    provide("activeTab", activeTab);
    provide("updateActiveTab", updateActiveTab);

    return {
      containerEl,
      isMenuOpen,
      menuLinks,
      tabLinks,
      toggleMenu,
      updateActiveTab,
      firstTab,
    };
  },
});
