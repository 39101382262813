export type ToastContent = {
  title: string;
  body: string;
};
export type Toasts = {
  [key: string]: ToastContent;
};

export const toasts: Toasts = Object.freeze({
  CHALLENGE_CREATED: {
    title: "",
    body: "Your challenge has been saved",
  },
  CHALLENGE_DELETED: {
    title: "",
    body: "Your challenge was deleted",
  },
  CHALLENGE_UPDATED: {
    title: "",
    body: "Your challenge was updated",
  },
  CHALLENGE_RESOLVED: {
    title: "Congratulations!",
    body: "Your challenge is now resolved",
  },
  CHALLENGE_REACTIVATED: {
    title: "",
    body: "Your challenge has been reactivated",
  },
  IDEA_CREATED: {
    title: "",
    body: "Your idea has been saved",
  },
  IDEA_DELETED: {
    title: "",
    body: "Your idea was deleted",
  },
  IDEA_UPDATED: {
    title: "",
    body: "Your idea was updated",
  },
  SOLUTION_CREATED: {
    title: "",
    body: "Your plan has been saved",
  },
  SOLUTION_DELETED: {
    title: "",
    body: "Your plan was deleted",
  },
  SOLUTION_UPDATED: {
    title: "",
    body: "Your plan was updated",
  },
  PLAN_NOT_SAVED: {
    title: "Please save your plan",
    body: "You have changes to save before resolving the challenge.",
  },
  FIRST_SOLUTION: {
    title: "Way to go! You created your first plan!",
    body: "Use your plan as a guide and check back regularly to make sure you keep on course.",
  },
  FIRST_RESOLUTION: {
    title: "Awesome! You completed your first challenge!",
    body: "It feels good doesn't it? Use your resolutions to inform your future challenges and to keep learning and growing from your experiences!",
  },
  PROFILE_UPDATED: {
    title: "",
    body: "Your profile was updated",
  },
  COPIED_TO_CLIPBOARD: {
    title: "",
    body: "Content copied to clipboard",
  },
  ERROR: {
    title: "",
    body: "Sorry, something went wrong. Please try again.",
  },
  ERROR_CHALLENGE: {
    title: "Oops!",
    body: "Sorry, we couldn't find that challenge",
  },
  ERROR_CASE_STUDY: {
    title: "Oops!",
    body: "Sorry, we couldn't find that case study",
  },
  ERROR_CLIPBOARD: {
    title: "",
    body: "Sorry, it is not possible to copy to clipboard in your browser",
  },
  ERROR_SETTINGS: {
    title: "",
    body: "Sorry, it was not possible to update your settings",
  },
  ERROR_DELETE_USER: {
    title: "Unable to delete your account",
    body: "Sorry, please contact tech support (link in footer)",
  },
});

export type Message = {
  type: "success" | "error" | "info";
  message: ToastContent;
};
export type Messages = Array<Message>;

// Declare state
export type State = {
  messages: Messages;
};

// Create initial state
export const state = {
  messages: [],
} as State;
