import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "z-10 bg-cebai-primary text-cebai-base-light w-80 rounded-xl relative" }
const _hoisted_2 = { class: "p-5" }
const _hoisted_3 = { class: "inline-block bg-white text-cebai-base-dark text-sm py-0.5 px-2 rounded font-robotoMedium" }
const _hoisted_4 = { class: "mt-3 mr-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-base font-robotoBold"
}
const _hoisted_6 = {
  key: 0,
  class: "text-base mt-2 mr-2"
}
const _hoisted_7 = { class: "flex border-t-[1px] border-cebai-grey-lightest text-base space-x-2 px-4" }
const _hoisted_8 = {
  key: 0,
  class: "inline-flex flex-1 items-center justify-start py-3"
}
const _hoisted_9 = { class: "inline-flex flex-1 items-center justify-end py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_ArrowRightIcon = _resolveComponent("ArrowRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "hover:cursor-pointer p-2 absolute right-2 top-2",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exit')))
    }, [
      _createVNode(_component_CloseIcon, { class: "w-3 w-3 fill-current" })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.index + 1) + "/" + _toDisplayString(_ctx.stepsCount), 1),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.step.content?.title)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.step.content?.title), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.step.content?.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.step.content.description), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (!_ctx.isFirst)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "inline-flex items-center text-cebai-base-light/60",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.previous && _ctx.previous(...args)))
            }, [
              _createVNode(_component_ArrowRightIcon, { class: "mr-2 rotate-180 stroke-current stroke-2" }),
              _createTextVNode(" Back ")
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.isLast)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "inline-flex items-center",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('exit')))
            }, " Finish "))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "inline-flex items-center",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
            }, [
              _createTextVNode(" Next "),
              _createVNode(_component_ArrowRightIcon, { class: "ml-2 stroke-current stroke-2" })
            ]))
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}