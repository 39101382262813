
import { defineComponent } from "vue";
import Logo from "@/assets/images/icon-logo.svg";
import LogoMobile from "@/assets/images/icon-logo-mobile.svg";

export default defineComponent({
  components: {
    Logo,
    LogoMobile,
  },
  setup() {
    return {};
  },
});
