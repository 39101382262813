import { CaseStudy } from "../learning/state";

// Declare state
export type State = {
  isLoading: boolean;
  caseStudy: CaseStudy | null;
};

// Create initial state
export const state = {
  isLoading: false,
  caseStudy: null,
} as State;
