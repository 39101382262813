// Mutations enums
export enum MutationTypes {
  SET_CHALLENGE = "SET_CHALLENGE",
  CLEAR_CHALLENGE = "CLEAR_CHALLENGE",
  SET_LOADING = "SET_LOADING",
  SET_LOADING_CHALLENGE_TITLE = "SET_LOADING_CHALLENGE_TITLE",
  SET_CHALLENGE_TITLE = "SET_CHALLENGE_TITLE",
  SET_DELETING = "SET_DELETING",
  SET_EXAMPLE_BUTTON_SHOWN = "SET_EXAMPLE_BUTTON_SHOWN",
  ADD_IDEA = "ADD_IDEA",
  ADD_SOLUTION = "ADD_SOLUTION",
  SET_SOLUTION = "SET_SOLUTION",
  UPDATE_IDEA_FAVORITE = "UPDATE_IDEA_FAVORITE",
  SET_LOADING_PLAN = "SET_LOADING_PLAN",
  SET_ENTITIES = "SET_ENTITIES",
  CLEAR_ENTITIES = "CLEAR_ENTITIES",
}
