import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "bg-cebai-base-light text-cebai-base-dark p-7 relative w-11/12 sm:w-9/12 max-w-3xl rounded-2xl" }
const _hoisted_2 = {
  key: 0,
  class: "relative bottom-3 uppercase font-latoBlack italic text-xs text-cebai-primary"
}
const _hoisted_3 = {
  key: 2,
  class: "font-normal text-lg md:text-xl text-cebai-grey-dark mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCloseIcon)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "hover:cursor-pointer p-2 absolute right-4 top-4 text-cebai-grey-dark hover:text-cebai-base-dark",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, [
          _createVNode(_component_CloseIcon, { class: "w-4 w-4 fill-current" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 1,
          class: _normalizeClass(["mt-1 font-robotoBold text-xl md:text-2xl text-cebai-base-dark", { 'mb-6': !_ctx.subTitle }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          (_ctx.isBeta)
            ? (_openBlock(), _createElementBlock("strong", _hoisted_2, " BETA "))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.subTitle)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.subTitle), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}