import { Idea, Solution, Resolution } from "../challenge/state";

export type Challenge = {
  _id: string;
  title: string;
  owners: string[];
  types: string[];
  tags: string[];
  subject: string;
  description: string;
  outcome?: string;
  additional: string;
  ideas: Idea[];
  shared: string[];
  status: number;
  solutions: Solution[];
  resolution: Resolution;
  createdAt?: string;
  updatedAt?: string;
};

export type Order = {
  key: "updatedAt" | "subject" | "title";
  value: string;
};

export type SubjectType = {
  value: string;
  label: string;
};

// Declare state
export type State = {
  isLoadingActiveChallenges: boolean;
  isLoadingResolvedChallenges: boolean;
  activeChallenges: Challenge[];
  resolvedChallenges: Challenge[];
  challengeTypes: string[];
  subjectTypes: SubjectType[];
  challengeTags: string[];
  outcomeTagsHelps: string[];
  outcomeTagsPrevents: string[];
};

// Create initial state
export const state = {
  isLoadingActiveChallenges: false,
  isLoadingResolvedChallenges: false,
  activeChallenges: [],
  resolvedChallenges: [],
  challengeTypes: [],
  subjectTypes: [],
  challengeTags: [],
  outcomeTagsHelps: [],
  outcomeTagsPrevents: [],
} as State;
