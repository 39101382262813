
import { defineComponent, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Ribbon from "@/components/ui/ribbon/Ribbon.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import GuidedTour from "@/components/ui/guided-tour/GuidedTour.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import ArrowRightIcon from "@/assets/images/icon-arrow-right.svg";

export default defineComponent({
  components: {
    Ribbon,
    ContentBlock,
    AppButtonRouterLink,
    Tabs,
    GuidedTour,
    Editorial,
    ArrowRightIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const tabs = computed(() => [
      {
        label: "Active challenges",
        slug: "active",
        route: { name: "saved-challenges-tab", params: { slug: "active" } },
      },
      {
        label: "Resolved challenges",
        slug: "resolved",
        route: { name: "saved-challenges-tab", params: { slug: "resolved" } },
      },
    ]);

    const redirectToDefaultTab = () => {
      if (route.name === "saved-challenges-list") {
        router.replace({
          name: "saved-challenges-tab",
          params: { slug: "active" },
        });
      }
    };

    // ensure that a tab is displayed by selecting the first as default
    watch(() => route.params.slug, redirectToDefaultTab, { immediate: true });

    const activeTab = computed(() => (route.params.slug as string) || "");

    const handleNewChallenge = () => {
      router.push({
        name: "challenges-new",
      });
    };

    return {
      handleNewChallenge,
      tabs,
      activeTab,
    };
  },
});
