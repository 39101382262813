
import { defineComponent, PropType, ref, computed } from "vue";
import { useStore } from "@/store";
import { truncate, capitaliseFirstLetter } from "@/utils/text";
import { Challenge } from "@/store/challenges/state";
import BulbIcon from "@/assets/images/icon-bulb.svg";
import BulbFilledIcon from "@/assets/images/icon-bulb-filled.svg";
import PlanIcon from "@/assets/images/icon-plan.svg";
import FaceUnhappyIcon from "@/assets/images/icon-face-unhappy.svg";
import FaceNeutralIcon from "@/assets/images/icon-face-neutral.svg";
import FaceHappyIcon from "@/assets/images/icon-face-happy.svg";

export default defineComponent({
  components: {
    BulbIcon,
    BulbFilledIcon,
    PlanIcon,
  },
  props: {
    challenge: {
      type: Object as PropType<Challenge>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isTitleExpanded = ref(false);
    const showExpandTitleButton = computed(
      () => props.challenge.title.length > 80,
    );
    const title = ref(truncate(props.challenge.title, 80));

    const subjects = computed(() => props.challenge.subject.split(", "));

    const toggleTitle = () => {
      if (isTitleExpanded.value) {
        title.value = truncate(props.challenge.title, 80);
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_ITEM_COLLAPSE`,
        });
      } else {
        title.value = props.challenge.title;
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_ITEM_EXPAND`,
        });
      }
      isTitleExpanded.value = !isTitleExpanded.value;
    };

    const icon = computed(() => {
      switch (
        props.challenge.resolution !== undefined &&
        props.challenge.resolution?.rating
      ) {
        case 1:
          return FaceUnhappyIcon;
        case 2:
          return FaceNeutralIcon;
        case 3:
          return FaceHappyIcon;
        default:
          return null;
      }
    });

    const challengeLink = computed(() => {
      switch (true) {
        case props.challenge.status === 0:
        case props.challenge.resolution !== undefined &&
          typeof props.challenge.resolution !== "string":
          return "challenge-saved-resolution";
        case props.challenge.solutions.length > 0:
          return "challenge-saved-plan";
        case props.challenge.ideas.length > 0:
          return "challenge-saved-ideas";
        default:
          return "challenge-saved-inspiration";
      }
    });

    return {
      title,
      capitaliseFirstLetter,
      subjects,
      showExpandTitleButton,
      toggleTitle,
      isTitleExpanded,
      icon,
      challengeLink,
    };
  },
});
