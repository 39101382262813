
import { defineComponent } from "vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";

export default defineComponent({
  components: {
    AppButtonRouterLink,
  },
  setup() {
    return {};
  },
});
