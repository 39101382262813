type IsShown = {
  [key: string]: boolean;
};

export function useModal(isShown: IsShown) {
  const showModal = (key: keyof IsShown) => {
    isShown[key] = true;
  };
  const closeModal = (key: keyof IsShown) => {
    isShown[key] = false;
  };

  return {
    isShown,
    showModal,
    closeModal,
  };
}
