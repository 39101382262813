import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import { Challenge } from "@/store/challenges/state";
import { Idea } from "@/store/challenge/state";

/* types */

export type OutputType = "ideas" | "plan";

/* api calls */

export const generatePdf = (
  challenge: Challenge | null,
  ideas: Idea[] | null | undefined,
  outputType: OutputType
): AxiosPromise => {
  return apiClient.post(
    `/pdf/ideas`,
    {
      challenge,
      ideas,
      outputType,
    },
    { responseType: "blob" }
  );
};
