import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState, Challenge, SubjectType } from "./state";
import { capitaliseFirstLetter } from "@/utils/text";

type GetChallenges = {
  (
    isResolved: boolean,
    searchText: string,
    searchTags: string[],
    searchTopics: string[],
    searchSubjects: string[]
  ): Challenge[];
};

type GetFilterTags = {
  (isResolved: boolean): string[];
};

type GetFilterTopics = {
  (isResolved: boolean): string[];
};

type GetFilterSubjects = {
  (isResolved: boolean): string[];
};

type GetSubjectLabelFromType = {
  (selectedSubjectType: string): string;
};

type SubjectOption = {
  label: string;
  options: SubjectType[];
};

// Getters types
export type Getters = {
  getChallenges(state: LocalState): GetChallenges;
  getFilterTags(state: LocalState): GetFilterTags;
  getFilterTopics(state: LocalState): GetFilterTopics;
  getFilterSubjects(state: LocalState): GetFilterSubjects;
  getSubjects(state: LocalState): SubjectOption[];
  getSubjectLabelfromType(state: LocalState): GetSubjectLabelFromType;
  getIsLoading(state: LocalState): boolean;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getChallenges:
    (state) =>
    (isResolved, searchText, searchTags, searchTopics, searchSubjects) => {
      const challenges = isResolved
        ? state.resolvedChallenges
        : state.activeChallenges;
      return challenges
        .filter((challenge) => {
          // apply search text filter
          return (
            challenge.subject
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            challenge.title.toLowerCase().includes(searchText.toLowerCase()) ||
            challenge.description
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            (challenge?.solutions.length > 0 &&
              challenge?.solutions[0].description &&
              challenge?.solutions[0].description
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (challenge?.solutions.length > 0 &&
              challenge?.solutions[0].successCriteria &&
              challenge?.solutions[0].successCriteria
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (challenge?.solutions.length > 0 &&
              challenge?.solutions[0].sharedWith &&
              challenge?.solutions[0].sharedWith
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (typeof challenge.resolution !== "string" &&
              challenge.resolution?.reflection &&
              challenge.resolution.reflection
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (typeof challenge.resolution !== "string" &&
              challenge.resolution?.positives &&
              challenge.resolution.positives
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (typeof challenge.resolution !== "string" &&
              challenge.resolution?.negatives &&
              challenge.resolution.negatives
                .toLowerCase()
                .includes(searchText.toLowerCase()))
          );
        })
        .filter((challenge) => {
          // apply tag filter(s)
          if (searchTags.length > 0) {
            return searchTags.every((tag) => challenge.tags.includes(tag));
          } else {
            return true;
          }
        })
        .filter((challenge) => {
          // apply topic filter(s)
          if (searchTopics.length > 0) {
            return searchTopics.every((topic) =>
              challenge.types.includes(topic)
            );
          } else {
            return true;
          }
        })
        .filter((challenge) => {
          // apply subject filter(s)
          if (searchSubjects.length > 0) {
            return searchSubjects.every((topic) =>
              challenge.subject.includes(topic)
            );
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          // apply sort filter
          return b.updatedAt && a.updatedAt
            ? b.updatedAt.localeCompare(a.updatedAt)
            : 1;
        });
    },

  getFilterTags: (state) => (isResolved) => {
    const challenges = isResolved
      ? state.resolvedChallenges
      : state.activeChallenges;

    // start an array to capture all tags
    const filterTags: string[] = [];

    challenges.forEach((challenge) => {
      challenge.tags.forEach((tag) => {
        if (!filterTags.includes(tag)) {
          filterTags.push(tag);
        }
      });
    });
    return filterTags.sort();
  },

  getFilterTopics: (state) => (isResolved) => {
    const challenges = isResolved
      ? state.resolvedChallenges
      : state.activeChallenges;

    // start an array to capture all topics
    const filterTopics: string[] = [];

    challenges.forEach((challenge) => {
      challenge.types.forEach((topic) => {
        if (!filterTopics.includes(topic)) {
          filterTopics.push(topic);
        }
      });
    });
    return filterTopics.sort();
  },

  getFilterSubjects: (state) => (isResolved) => {
    const challenges = isResolved
      ? state.resolvedChallenges
      : state.activeChallenges;

    // start an array to capture all subjects
    const filterSubjects: string[] = [];

    challenges.forEach((challenge) => {
      // subjects are stored as a text string so need converting to an array
      const subjects = challenge.subject.split(",");
      subjects.forEach((subject) => {
        if (subject.length > 0 && !filterSubjects.includes(subject.trim())) {
          filterSubjects.push(subject.trim());
        }
      });
    });

    // ensure capitalisation doesn't impact on alphabetical sorting
    return filterSubjects.sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
  },

  getSubjects: (state) => {
    // extract the top three most frequently used, and then sort alphabetically
    const frequentlyUsedOptions = state.subjectTypes.slice(0, 3);
    const allOptions = state.subjectTypes.sort((a, b) =>
      a.label < b.label ? -1 : 1
    );

    return [
      {
        label: "Frequently Used",
        options: frequentlyUsedOptions,
      },
      {
        label: "All Options",
        options: allOptions,
      },
    ];
  },

  getSubjectLabelfromType: (state) => (selectedSubjectType) => {
    // attempt to retrieve the label from its key,
    // default to the supplied key if a label can't be found
    const subject = state.subjectTypes.find(
      (subjectType) => subjectType.value === selectedSubjectType
    );
    return subject?.label || capitaliseFirstLetter(selectedSubjectType.trim());
  },

  getIsLoading: (state) => {
    return (
      state.isLoadingActiveChallenges === true ||
      state.isLoadingResolvedChallenges === true
    );
  },
};
