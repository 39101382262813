
import { defineComponent, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";
import { useAuth0 } from "@auth0/auth0-vue";
import GuidedTour from "@/components/ui/guided-tour/GuidedTour.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import GuidancePrompts from "@/components/content/guidance/GuidancePrompts.vue";
import WarningIcon from "@/assets/images/icon-warning.svg";

export default defineComponent({
  components: {
    Tabs,
    GuidancePrompts,
    GuidedTour,
    WarningIcon,
  },
  props: {
    slug: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const auth0 = useAuth0();

    const challengeType = computed(() => route.meta.challengeType);

    const tabs = computed(() => [
      {
        slug: "academic",
        label: "Academic",
        route: {
          name: `challenge-${challengeType.value}-inspiration-learning-tab`,
          params: { slug: "academic" },
        },
      },
      {
        slug: "practices",
        label: "Practices",
        route: {
          name: `challenge-${challengeType.value}-inspiration-learning-tab`,
          params: { slug: "practices" },
        },
      },
      {
        slug: "case-studies",
        label: "Case Studies",
        route: {
          name: `challenge-${challengeType.value}-inspiration-learning-tab`,
          params: { slug: "case-studies" },
        },
      },
    ]);

    const redirectToDefaultTab = () => {
      if (
        route.name ===
          `challenge-${challengeType.value}-inspiration-learning` &&
        !route.params.slug
      ) {
        router.replace({
          name: `challenge-${challengeType.value}-inspiration-learning-tab`,
          params: { slug: "academic" },
        });
      }
    };

    // ensure that a tab is displayed by selecting the first as default
    watch(() => route.params.slug, redirectToDefaultTab, { immediate: true });

    const activeTab = computed(() => (route.params.slug as string) || "");

    onMounted(() => {
      if (auth0.isAuthenticated.value) {
        store.dispatch("ideaSparks/GET_GUIDANCE", "learning");
      }
    });

    return {
      isAuthenticated: auth0.isAuthenticated,
      tabs,
      activeTab,
    };
  },
});
