
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import AppContent from "@/components/layout/app-content/AppContent.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import ArrowRightIcon from "@/assets/images/icon-arrow-right.svg";

export default defineComponent({
  components: {
    AppContent,
    ContentBlock,
    Editorial,
    AppButtonRouterLink,
    ArrowRightIcon,
  },
  setup() {
    const auth0 = useAuth0();
    const route = useRoute();
    const router = useRouter();

    const toggleLogin = async () => {
      auth0.loginWithRedirect({
        appState: { target: route.fullPath },
      });
    };

    const toggleSignUp = async () => {
      auth0.loginWithRedirect({
        authorizationParams: { action: "signup" },
        appState: { target: route.fullPath },
      });
    };

    const handleNewChallenge = () => {
      router.push({
        name: "challenges-new",
      });
    };

    return {
      isAuthenticated: auth0.isAuthenticated,
      toggleLogin,
      toggleSignUp,
      handleNewChallenge,
    };
  },
});
