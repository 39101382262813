import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Cookies } from "./state";

type SetCookiePayload = {
  cookie: Cookies;
  value: boolean;
};

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.DISMISS_COOKIE_BANNER](state: S): void;
  [MutationTypes.SET_COOKIE_VALUE](state: S, payload: SetCookiePayload): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.DISMISS_COOKIE_BANNER](state) {
    state.isCookieBannerDismissed = true;
  },
  [MutationTypes.SET_COOKIE_VALUE](state, payload) {
    state[payload.cookie] = payload.value;
  },
};
