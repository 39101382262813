
import { defineComponent, computed, ref } from "vue";
import { useStore } from "@/store";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";

export default defineComponent({
  components: {
    Editorial,
    AppButton,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    // form
    const inputFirstName = ref("");
    const inputLastName = ref("");
    const inputJobTitle = ref("");

    const isSavingProfile = computed(() => store.state.user.isSavingProfile);

    const submitIsDisabled = computed(() => {
      return !inputFirstName.value || !inputLastName.value;
    });

    const handleFormSubmit = async () => {
      await store.dispatch("user/UPDATE_USER_PROFILE", {
        onboarding: true,
        data: {
          firstName: inputFirstName.value,
          lastName: inputLastName.value,
          jobTitle: inputJobTitle.value,
        },
      });

      await store.dispatch("user/UPDATE_USER_ONBOARDING", {
        key: "onboarded_profile",
        data: { value: true },
      });

      store.dispatch("tracking/TRACK_EVENT", {
        action: `USER_SIGNUP_COMPLETE`,
      });

      props.closeModal();
    };

    return {
      inputFirstName,
      inputLastName,
      inputJobTitle,
      isSavingProfile,
      submitIsDisabled,
      handleFormSubmit,
    };
  },
});
