
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  reactive,
  watch,
} from "vue";
import { useStore } from "@/store";
import { useModal } from "@/composables/useModal";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { Idea } from "@/store/challenge/state";
import { GeneratePlan } from "@/store/challenge/actions";
import GuidancePrompts from "@/components/content/guidance/GuidancePrompts.vue";
import Guidance from "@/components/content/guidance/Guidance.vue";
import Download from "@/components/content/download/Download.vue";
import CreateIdea from "@/components/content/create-idea/CreateIdea.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Ribbon from "@/components/ui/ribbon/Ribbon.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import ChallengeIdea from "./ChallengeIdea.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import SelectAllIcon from "@/assets/images/icon-select-all.svg";
import DownloadIcon from "@/assets/images/icon-download.svg";
import BinIcon from "@/assets/images/icon-bin.svg";
import PlusIcon from "@/assets/images/icon-plus.svg";
import ArrowRightIcon from "@/assets/images/icon-arrow-right.svg";

export default defineComponent({
  components: {
    ContentBlock,
    GuidancePrompts,
    Guidance,
    Download,
    CreateIdea,
    Ribbon,
    AppButton,
    AppButtonRouterLink,
    Editorial,
    LoadingIcon,
    ModalContent,
    ChallengeIdea,
    SelectAllIcon,
    DownloadIcon,
    BinIcon,
    PlusIcon,
    ArrowRightIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoading = computed(() => store.state.challenge.isLoading);
    const challenge = computed(() => store.state.challenge.challenge);
    const challengeIsActive = computed(
      () => store.getters["challenge/getIsActive"],
    );

    // favourites
    const showFavouritesOnly = ref(false);
    const toggleShowFavouritesOnly = () => {
      showFavouritesOnly.value = !showFavouritesOnly.value;

      if (showFavouritesOnly.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_IDEAS_SHOW_FAVOURITES_ONLY`,
        });
      } else {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_IDEAS_SHOW_ALL`,
        });
      }
    };

    // ideas
    const ideas = computed(() =>
      store.getters["challenge/getIdeas"](showFavouritesOnly.value),
    );

    const hasIdeas = computed(
      () =>
        store.state.challenge.challenge?.ideas &&
        store.state.challenge.challenge.ideas.length > 0,
    );

    // selected ideas
    const selectedIdeas = ref<Idea[]>([]);
    const hasSelectedIdeas = computed(() => selectedIdeas.value.length > 0);
    const hasSelectedAll = computed(
      () =>
        selectedIdeas.value.length > 0 &&
        selectedIdeas.value.length === ideas.value?.length,
    );

    // edited (and unsaved) ideas
    const editedIdeas = ref<Idea[]>([]);
    const hasEditedIdeas = computed(() => editedIdeas.value.length > 0);
    const startEditingIdea = (idea: Idea) => {
      editedIdeas.value.push(idea);
    };
    const stopEditingIdea = (idea: Idea) => {
      if (editedIdeas.value.includes(idea)) {
        editedIdeas.value.splice(editedIdeas.value.indexOf(idea), 1);
      }
    };

    const isSelectedIdea = (idea: Idea) => selectedIdeas.value.includes(idea);

    // select all
    const handleSelectAllClick = () => {
      if (!ideas.value) return;

      if (hasSelectedAll.value) {
        selectedIdeas.value = [];
      } else {
        selectedIdeas.value = [...ideas.value];
      }
    };

    const updateSelectedEditedIdeas = () => {
      if (!ideas.value) {
        selectedIdeas.value = [];
        editedIdeas.value = [];
        return;
      }

      // if ideas change, we should remove any ideas from the selected array
      // that are no longer present
      selectedIdeas.value = [
        ...selectedIdeas.value.filter((idea) => ideas?.value?.includes(idea)),
      ];
      editedIdeas.value = [
        ...editedIdeas.value.filter((idea) => ideas?.value?.includes(idea)),
      ];
    };
    watch(ideas, updateSelectedEditedIdeas);

    // modals
    const showDownloadIdeasModal = () => {
      showModal("downloadIdeas");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_IDEAS_DOWNLOAD_MODAL_SHOW`,
      });
    };

    const showDeleteIdeasModal = () => {
      showModal("deleteIdeas");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_IDEAS_DELETE_MODAL_SHOW`,
      });
    };

    const showAddIdeaModal = () => {
      showModal("addIdea");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_IDEAS_ADD_MODAL_SHOW`,
      });
    };

    const modalState = reactive({
      deleteIdeas: false,
      downloadIdeas: false,
      addIdea: false,
    });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const handleDeleteClick = () => {
      selectedIdeas.value.forEach((idea) => {
        store.dispatch("challenge/DELETE_IDEA", idea);
      });
      selectedIdeas.value = [];
      closeModal("deleteIdeas");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_IDEAS_DELETE`,
      });
    };

    // guidance
    const hasGuidance = computed(
      () =>
        hasIdeas.value &&
        store.getters["ideaSparks/getGuidance"]("ideas").length > 0,
    );

    onMounted(() => {
      store.dispatch("ideaSparks/GET_GUIDANCE", "ideas");
    });

    // plan
    const isLoadingPlan = computed(() => store.state.challenge.isLoadingPlan);
    const hasPlan = computed(() => store.getters["challenge/getHasSolution"]);

    const handleCreatePlan = async () => {
      const selectedIdeasPlan = selectedIdeas.value.map(
        (idea) => idea.description,
      );
      const payload: GeneratePlan = {
        challenge: challenge.value?.description || "",
        actor: challenge.value?.subject.split(",")[0] || "",
        ideas: selectedIdeasPlan || [],
        steps: 3,
      };

      let plan = await store.dispatch("challenge/GENERATE_PLAN", payload);
      if (!plan) return;

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_SOLUTION_GENERATE_PLAN`,
      });

      // create a list of the selected ideas to append to the plan
      const ideasList = selectedIdeasPlan.join("\n\n");

      if (selectedIdeasPlan.length === 1) {
        plan += "\n\nYour selected idea for this plan was:\n\n" + ideasList;
      } else {
        plan += "\n\nYour selected ideas for this plan were:\n\n" + ideasList;
      }

      const selectedIdeasIds = selectedIdeas.value.map((idea) => idea._id);

      const data = {
        ideas: selectedIdeasIds,
        description: plan || "",
        sharedWith: "",
        successCriteria: "",
        confidenceLevel: -1,
        helpsOutcome: [],
        preventsOutcome: [],
        targetDate: "",
      };

      const solutionId = await store.dispatch("challenge/ADD_SOLUTION", data);
      if (!solutionId) return;

      store.dispatch("tracking/TRACK_EVENT", {
        action: `SOLUTION_ADD`,
      });

      router.push({ name: "challenge-saved-plan" });
    };

    onBeforeRouteLeave(() => {
      if (hasEditedIdeas.value) {
        // unsaved changes, so confirm before navigating away
        const answer = window.confirm(
          "Do you really want to leave? Your changes haven't been saved yet!",
        );
        if (!answer) return false;
      }
    });

    return {
      isLoading,
      showDeleteIdeasModal,
      showDownloadIdeasModal,
      showAddIdeaModal,
      isShown,
      showModal,
      closeModal,
      ideas,
      hasIdeas,
      hasGuidance,
      challenge,
      challengeIsActive,
      hasPlan,
      showFavouritesOnly,
      toggleShowFavouritesOnly,
      selectedIdeas,
      hasSelectedIdeas,
      hasSelectedAll,
      isSelectedIdea,
      startEditingIdea,
      stopEditingIdea,
      hasEditedIdeas,
      handleSelectAllClick,
      handleDeleteClick,
      isLoadingPlan,
      handleCreatePlan,
    };
  },
});
