
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  reactive,
  watch,
} from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import isEqual from "lodash.isequal";
import { useStore } from "@/store";
import { useModal } from "@/composables/useModal";
import { getDateOrdinal } from "@/utils/date";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import ChallengeNavigation from "@/components/content/challenge-navigation/ChallengeNavigation.vue";
import ChallengeHeader from "@/components/content/challenge-header/ChallengeHeader.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import AutoResizeTextarea from "@/components/ui/auto-resize-textarea/AutoResizeTextarea.vue";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Download from "@/components/content/download/Download.vue";
import Ribbon from "@/components/ui/ribbon/Ribbon.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import Multiselect from "@/components/ui/multiselect/Multiselect.vue";
import DateSelect from "@/components/ui/date-select/DateSelect.vue";
import GuidedTour from "@/components/ui/guided-tour/GuidedTour.vue";
import ArrowUpIcon from "@/assets/images/icon-arrow-up.svg";
import ArrowRightIcon from "@/assets/images/icon-arrow-right.svg";
import DownloadIcon from "@/assets/images/icon-download.svg";
import BinIcon from "@/assets/images/icon-bin.svg";

export default defineComponent({
  components: {
    Editorial,
    ContentBlock,
    ChallengeNavigation,
    ChallengeHeader,
    AutoResizeTextarea,
    ModalContent,
    Download,
    Ribbon,
    LoadingIcon,
    AppButton,
    AppButtonRouterLink,
    Multiselect,
    DateSelect,
    GuidedTour,
    ArrowUpIcon,
    ArrowRightIcon,
    BinIcon,
    DownloadIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    // loading
    const isLoading = computed(() => store.state.challenge.isLoading);
    const isLoadingPlan = computed(() => store.state.challenge.isLoadingPlan);

    // plan
    const solution = computed(() => store.getters["challenge/getSolution"]);
    const solutionIdeas = computed(
      () => store.getters["challenge/getSolutionIdeas"],
    );
    const solutionText = computed(
      () => store.getters["challenge/getSolutionText"],
    );

    // resolution
    const hasResolution = computed(
      () => store.getters["challenge/getHasResolution"],
    );

    const challengeIsActive = computed(
      () => store.getters["challenge/getIsActive"],
    );
    const challengeIsResolved = computed(
      () => store.getters["challenge/getIsResolved"],
    );

    // inputs
    const inputPlanText = ref(solutionText.value || "");
    const inputPlanTextEl = ref<typeof AutoResizeTextarea | null>(null);

    // extra input fields
    const confidenceLevel = computed(
      () => store.getters["challenge/getSolutionConfidence"],
    );
    const successCriteria = computed(
      () => solution.value?.successCriteria || "",
    );
    const targetDate = computed(() => solution.value?.targetDate || "");

    const helpsOutcome = computed(() =>
      solution.value?.helpsOutcome?.length ? solution.value?.helpsOutcome : [],
    );
    const preventsOutcome = computed(() =>
      solution.value?.preventsOutcome?.length
        ? solution.value?.preventsOutcome
        : [],
    );

    // add any existing user added tags to the options (by using Set to remove any duplicates)
    const helpsOutcomeOptions = computed(() => [
      ...new Set([
        ...store.state.challenges.outcomeTagsHelps,
        ...helpsOutcome.value,
      ]),
    ]);
    const preventsOutcomeOptions = computed(() => [
      ...new Set([
        ...store.state.challenges.outcomeTagsPrevents,
        ...preventsOutcome.value,
      ]),
    ]);

    // values may exist to populate the form -
    // convert them to references to track their values
    const inputConfidenceLevel = ref(confidenceLevel.value);
    const inputHelpsOutcome = ref(helpsOutcome.value);
    const inputPreventsOutcome = ref(preventsOutcome.value);
    const inputSuccessCriteria = ref(successCriteria.value);
    const inputTargetDate = ref(targetDate.value);

    // has anything changed? check if the initial data matches the current input values.
    const hasInitialDetails = computed(
      () =>
        solutionText.value === inputPlanText.value &&
        confidenceLevel.value === inputConfidenceLevel.value &&
        isEqual(helpsOutcome.value, inputHelpsOutcome.value) &&
        isEqual(preventsOutcome.value, inputPreventsOutcome.value) &&
        successCriteria.value === inputSuccessCriteria.value &&
        targetDate.value === inputTargetDate.value,
    );

    // for saved dates, convert saved string to a date and re-format for display
    const formattedTargetDate = computed(() =>
      solution.value?.targetDate
        ? getDateOrdinal(solution.value?.targetDate)
        : "",
    );

    onMounted(() => {
      store.dispatch("challenges/GET_OUTCOME_TAGS", "helps");
      store.dispatch("challenges/GET_OUTCOME_TAGS", "prevents");
    });

    const hasPlan = computed(() => store.getters["challenge/getHasSolution"]);

    watch(
      isLoading,
      () => {
        // the challenge may load after the form has rendered,
        // so populate form fields when values become available
        if (!isLoading.value) {
          if (solutionText.value) {
            inputPlanText.value = solutionText.value;
          }
          inputConfidenceLevel.value = confidenceLevel.value;
          inputHelpsOutcome.value = helpsOutcome.value;
          inputPreventsOutcome.value = preventsOutcome.value;
          inputSuccessCriteria.value = successCriteria.value;
          inputTargetDate.value = targetDate.value;

          // only allow users to access the plan view if they have a plan
          if (!hasPlan.value) {
            router.replace({ name: "challenge-saved" });
          }
        }
      },
      { immediate: true },
    );

    watch(inputPlanTextEl, () => {
      if (inputPlanTextEl.value && solutionText.value) {
        inputPlanTextEl.value?.scrollToTop();
      }
    });

    // details

    const showDetails = ref(false);
    const toggleDetails = () => {
      showDetails.value = !showDetails.value;

      if (showDetails.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: "PLAN_FORM_SHOW_DETAILS",
        });
      } else {
        store.dispatch("tracking/TRACK_EVENT", {
          action: "PLAN_FORM_HIDE_DETAILS",
        });
      }
    };

    // save plan
    const handleUpdate = () => {
      if (!solution.value?._id) return;

      const data = {
        description: inputPlanText.value,
        successCriteria: inputSuccessCriteria.value,
        confidenceLevel: inputConfidenceLevel.value,
        helpsOutcome: inputHelpsOutcome.value,
        preventsOutcome: inputPreventsOutcome.value,
        targetDate: inputTargetDate.value,
      };
      store.dispatch("challenge/UPDATE_SOLUTION", {
        _id: solution.value._id,
        data,
      });
      store.dispatch("tracking/TRACK_EVENT", {
        action: `SOLUTION_UPDATE`,
      });
    };

    // modals
    const modalState = reactive({
      deletePlan: false,
      downloadPlan: false,
    });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const handleDeletePlan = () => {
      if (!solution.value) return;
      store.dispatch("challenge/DELETE_SOLUTION", solution.value);
      closeModal("deletePlan");
      store.dispatch("tracking/TRACK_EVENT", {
        action: `SOLUTION_DELETE`,
      });
      router.push({ name: "challenge-saved-ideas" });
    };

    const showDownloadPlanModal = () => {
      showModal("downloadPlan");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_SOLUTION_DOWNLOAD_SOLUTION_MODAL_SHOW`,
      });
    };

    const showDeletePlanModal = () => {
      showModal("deletePlan");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_SOLUTION_DELETE_SOLUTION_MODAL_SHOW`,
      });
    };

    onBeforeRouteLeave(() => {
      if (!hasInitialDetails.value) {
        // unsaved changes, so confirm before navigating away
        const answer = window.confirm(
          "Do you really want to leave? Your changes haven't been saved yet!",
        );
        if (!answer) return false;
      }
    });

    return {
      isLoading,
      isLoadingPlan,
      inputPlanTextEl,
      solutionText,
      solutionIdeas,
      inputPlanText,
      showDetails,
      toggleDetails,
      handleUpdate,
      challengeIsActive,
      challengeIsResolved,
      hasResolution,
      inputConfidenceLevel,
      inputHelpsOutcome,
      inputPreventsOutcome,
      inputSuccessCriteria,
      inputTargetDate,
      formattedTargetDate,
      helpsOutcomeOptions,
      preventsOutcomeOptions,
      hasInitialDetails,
      isShown,
      showModal,
      closeModal,
      handleDeletePlan,
      showDownloadPlanModal,
      showDeletePlanModal,
    };
  },
});
