import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuidedTourStep = _resolveComponent("GuidedTourStep")!
  const _component_VOnboardingStep = _resolveComponent("VOnboardingStep")!
  const _component_VOnboardingWrapper = _resolveComponent("VOnboardingWrapper")!

  return (_openBlock(), _createBlock(_component_VOnboardingWrapper, {
    ref: "wrapper",
    steps: _ctx.steps,
    options: _ctx.options
  }, {
    default: _withCtx(({ previous, next, step, isFirst, isLast, index }) => [
      _createVNode(_component_VOnboardingStep, null, {
        default: _withCtx(() => [
          _createVNode(_component_GuidedTourStep, {
            step: step,
            index: index,
            "steps-count": _ctx.steps.length,
            previous: previous,
            next: next,
            "is-first": isFirst,
            "is-last": isLast,
            onExit: _ctx.handleFinish
          }, null, 8, ["step", "index", "steps-count", "previous", "next", "is-first", "is-last", "onExit"])
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["steps", "options"]))
}