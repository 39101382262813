import * as Sentry from "@sentry/vue";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureException = (error: any) => {
  // eslint-disable-next-line no-console
  console.error(error);
  Sentry.captureException(error);
};

export const captureMessage = (message: string) => {
  // eslint-disable-next-line no-console
  console.log(message);
  Sentry.captureMessage(message);
};
