import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { CaseStudy } from "../learning/state";
import { State as LocalState } from "./state";

// Getters types
export type Getters = {
  getCaseStudy(state: LocalState): CaseStudy | null;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getCaseStudy: (state) => {
    return state.caseStudy;
  },
};
