import { Spark } from "../sparks/state";

export type Prompt = {
  stem: Record<string, string>;
  text: string;
  object?: string;
  idea?: string;
};

// Declare state
export type State = {
  isLoadingTrizPrompts: boolean;
  isLoadingScamperPrompts: boolean;
  isLoadingLearningPrompts: boolean;
  trizPrompts: Prompt[];
  scamperPrompts: Prompt[];
  learningPrompts: Spark[];
  ideationGuidance: Prompt[];
  refinementGuidance: Prompt[];
  sparksGuidance: Prompt[];
  learningGuidance: Prompt[];
  ideasGuidance: Prompt[];
  planGuidance: Prompt[];
};

// Create initial state
export const state = {
  isLoadingTrizPrompts: false,
  isLoadingScamperPrompts: false,
  isLoadingLearningPrompts: false,
  trizPrompts: [],
  scamperPrompts: [],
  learningPrompts: [],
  ideationGuidance: [],
  refinementGuidance: [],
  sparksGuidance: [],
  learningGuidance: [],
  ideasGuidance: [],
  planGuidance: [],
} as State;
