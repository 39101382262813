
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import Guidance from "./Guidance.vue";

export default defineComponent({
  components: {
    Guidance,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const prompts = computed(() =>
      store.getters["ideaSparks/getGuidance"](props.type),
    );
    const hasGuidance = computed(() => prompts.value.length > 0);

    return {
      hasGuidance,
      prompts,
    };
  },
});
