import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { CaseStudy } from "../learning/state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import router from "@/router";
import { getCaseStudy } from "@/services/learning.service";
import { captureException } from "@/utils/errors";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_CASE_STUDY](
    { commit, dispatch }: AugmentedActionContext,
    payload: string
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_CASE_STUDY]({ commit, dispatch }, payload) {
    commit(MutationTypes.SET_LOADING, true);
    commit(MutationTypes.SET_CASE_STUDY, null);

    return getCaseStudy(payload)
      .then((response) => {
        const caseStudy: CaseStudy = response.data;

        // add description for "add idea" function
        caseStudy.description = `One or more ideas based on the case study '${caseStudy?.title}'`;

        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY, caseStudy);
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR_CASE_STUDY", {
          root: true,
        });
        router.push({ name: "home" });
        commit(MutationTypes.SET_LOADING, false);
      });
  },
};
