import formatRelative from "date-fns/formatRelative";
import enGB from "date-fns/locale/en-GB";
import format from "date-fns/format";

/**
 * Returns date string in words relative to the given base date e.g. "yesterday at 15:22"
 *
 * @param {string} dateString Date to reformat
 * @return {string} date string in relative date format (enGB - dd-MM-yyyy)
 */
export const getRelativeDateString = (dateString: string) => {
  return formatRelative(new Date(dateString), new Date(), { locale: enGB });
};

/**
 * Returns date string in date ordinal format e.g. "26th June 2022"
 *
 * @param {string} dateString Date to reformat
 * @return {string} date string in date ordinal format "do MMMM y".
 */
export const getDateOrdinal = (dateString: string) => {
  return format(Date.parse(dateString), "do MMMM y");
};
