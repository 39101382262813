import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import { PossibleIdeasPayload } from "@/store/possible-ideas/actions";

/* api calls */

// possible ideas
let ideasController: AbortController | undefined;

export const getPossibleIdeas = (
  payload: PossibleIdeasPayload,
): AxiosPromise => {
  // check if AbortController is already assigned.
  // if true, call abort() to cancel the pending request
  if (ideasController) {
    ideasController.abort();
    ideasController = undefined;
  }
  // create a new instance of the AbortController before making API request
  ideasController = new AbortController();

  // reference to the AbortSignal object via the AbortController.signal property
  const signal = ideasController.signal;

  return apiClient.post(`/nlp/possible-ideas`, payload, { signal });
};
