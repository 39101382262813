import axios, { InternalAxiosRequestConfig, AxiosInstance } from "axios";
import { auth0 } from "@/auth";

export const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

// Add a request interceptor for auth
apiClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    try {
      const isAuthenticated = await auth0.isAuthenticated.value;
      // if logged in, add bearer token to requests
      if (isAuthenticated) {
        const token = await auth0.getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (error) {
      // not logged in
      return config;
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
