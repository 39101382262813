import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    id: _ctx.id,
    class: _normalizeClass(["transition text-base sm:text-lg font-robotoMedium text-center py-2 md:w-auto flex md:flex-none justify-center items-center whitespace-nowrap", {
      'text-cebai-base-dark': _ctx.activeTab?.includes(_ctx.slug),
      'text-cebai-grey-dark hover:text-cebai-base-dark':
        !_ctx.activeTab?.includes(_ctx.slug),
      'mr-3 sm:mr-6 last:mr-0':
        _ctx.tabType === null || _ctx.tabType === undefined || _ctx.tabType === 'underlined',

      // underlined
      'pt-[18px] pb-[13px] border-b-[5px] border-b-cebai-primary text-cebai-base-dark':
        _ctx.activeTab?.includes(_ctx.slug) && _ctx.tabType === 'underlined',
      'py-[18px] border-b-cebai-primary text-cebai-base-dark':
        !_ctx.activeTab?.includes(_ctx.slug) && _ctx.tabType === 'underlined',

      // outlined
      'mr-0.5 border-2 px-4 rounded-t-lg relative -bottom-0.5 pb-2':
        _ctx.tabType === 'outlined',
      'border-cebai-grey-light border-b-cebai-base-light bg-cebai-base-light':
        _ctx.activeTab?.includes(_ctx.slug) && _ctx.tabType === 'outlined',
      'border-transparent hover:bg-cebai-grey-light hover:text-cebai-primary hover:border-cebai-grey-light':
        !_ctx.activeTab?.includes(_ctx.slug) && _ctx.tabType === 'outlined',

      // button
      'rounded-md px-4 mr-3 sm:mr-6 last:mr-0': _ctx.tabType === 'button',
      'bg-cebai-primary text-cebai-base-light':
        _ctx.activeTab?.includes(_ctx.slug) && _ctx.tabType === 'button',
      'bg-cebai-grey-mid text-cebai-base-dark hover:bg-cebai-primary hover:text-cebai-base-light':
        !_ctx.activeTab?.includes(_ctx.slug) && _ctx.tabType === 'button',
    }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTabClick && _ctx.handleTabClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}