
import { defineComponent } from "vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";

export default defineComponent({
  components: {
    ContentBlock,
  },
  setup() {
    const primaryLinks = [
      {
        title: "Sport Sparks website",
        url: "https://www.sportsparks.io/",
      },
    ];

    const secondaryLinks = [
      {
        id: "how-it-works",
        title: "How it works",
        url: "https://www.sportsparks.io/how-to-use-sport-sparks",
      },
      {
        id: "more-guidance",
        title: "More guidance",
        url: "https://www.sportsparks.io/more-guidance",
      },
      {
        id: "tech-support",
        title: "Tech support",
        url: "https://creativityai.atlassian.net/servicedesk/customer/portal/3",
      },
      {
        id: "terms-of-use",
        title: "Terms of use",
        url: "https://www.sportsparks.io/terms_of_use",
      },
      {
        id: "privacy-policy",
        title: "Privacy policy",
        url: "https://www.sportsparks.io/data-protection",
      },
      {
        id: "customer-contact-policy",
        title: "Customer contact policy",
        url: "https://www.sportsparks.io/customer-feedback-policy",
      },
      {
        id: "cookie-policy",
        title: "Cookie policy",
        name: "cookie-policy",
        internal: true,
      },
    ];

    const year = new Date().getFullYear();

    return {
      primaryLinks,
      secondaryLinks,
      year,
    };
  },
});
