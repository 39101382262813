import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TickIcon = _resolveComponent("TickIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "px-4 py-1 flex justify-center items-center text-sm font-robotoMedium cursor-pointer whitespace-nowrap",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-5 h-5 mr-2 rounded-full", {
        border: _ctx.hasBorder,
      }])
    }, [
      (_ctx.selected)
        ? (_openBlock(), _createBlock(_component_TickIcon, {
            key: 0,
            class: "fill-current"
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ]))
}