import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "absolute right-0 top-0 px-8 py-7 text-cebai-primary" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["md:hidden flex items-center justify-center fixed z-50 right-0 left-0 top-0 bottom-0 transition bg-cebai-base-light", {
      'opacity-100 translate-x-0': _ctx.isDropdownShown,
      'opacity-50 translate-x-full': !_ctx.isDropdownShown,
    }])
  }, [
    _createElementVNode("button", _hoisted_1, [
      _createVNode(_component_CloseIcon, { class: "w-6 h-6 fill-current" })
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
        return (_openBlock(), _createElementBlock("li", {
          key: link.name
        }, [
          _createVNode(_component_router_link, {
            to: { name: link.name },
            class: "block py-2 px-4 text-cebai-grey-dark hover:text-cebai-base-dark text-2xl font-robotoBold",
            "exact-active-class": "text-cebai-grey-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(link.label), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ], 2))
}