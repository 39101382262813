// general idea from:
// https://dev.to/sduduzog/creating-an-auto-resizing-textarea-with-vue-composition-api-in-4-easy-steps-i-can-t-wait-for-vuejs-v3-3n8l
// note - it is better to use borders, as margins get eaten up once the scrolling starts

import { onMounted, onBeforeUnmount, Ref, watch, nextTick } from "vue";

export const useAutoResizeTextarea = (
  textarea: Ref<HTMLTextAreaElement | null>,
  textareaValue: Ref<string>,
  maxRows: number,
) => {
  let resizeObserver: ResizeObserver | undefined;

  const resizeTextarea = async () => {
    // wait for any re-rendering or textarea content updates
    await nextTick();

    if (!textarea.value) return;

    const {
      paddingTop,
      paddingBottom,
      borderTopWidth,
      borderBottomWidth,
      lineHeight,
    } = window.getComputedStyle(textarea.value);
    const padding = parseInt(paddingTop) + parseInt(paddingBottom);
    const borders = parseInt(borderTopWidth) + parseInt(borderBottomWidth);
    const maxHeight = maxRows * parseInt(lineHeight) + padding + borders;

    textarea.value.style.height = "auto";
    if (textarea.value.scrollHeight < maxHeight) {
      textarea.value.style.height =
        textarea.value.scrollHeight + borders + "px";
    } else {
      textarea.value.style.height = maxHeight + "px";
    }

    // if entering text at the end of the textarea, scroll down to bottom
    if (textarea.value.selectionEnd === textarea.value.value.length) {
      textarea.value.scrollTop = textarea.value.scrollHeight;
    }
  };

  // resize textarea when value is updated
  watch(textareaValue, resizeTextarea);
  watch(() => textarea.value?.value, resizeTextarea);

  // The resize observer callback should trigger the textarea resize when able.
  // Using requestAnimationFrame avoids ResizeObserver loop issues.
  const handleResizeObserved = () => {
    window.requestAnimationFrame(resizeTextarea);
  };

  onMounted(() => {
    if (!textarea.value) return;

    // resize textarea on initialisation
    resizeTextarea();

    // resize textarea on element resize (e.g. if browser dimensions change)
    resizeObserver = new ResizeObserver(handleResizeObserved);
    resizeObserver.observe(textarea.value);
  });

  // clean up when no longer needed
  onBeforeUnmount(() => {
    if (!textarea.value) return;

    resizeObserver?.unobserve(textarea.value);
  });
};
