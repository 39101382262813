export type Settings = Record<string, boolean>;
export type Onboarding = Record<string, boolean>;

// Data object that comes from the API
export type User = {
  _id: string;
  id: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  picture?: string;
  flow: Record<string, boolean>;
  onboarding: Onboarding;
  settings: Settings;
  createdAt?: string;
  updatedAt?: string;
};

// Data object that comes from auth0
export type Auth0User = {
  email: string;
  picture: string;
};

// Data object we use internally to reference user data
// compiled from a mix of User & Auth0User data
export type UserData = {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  picture?: string;
};

// Declare state
export type State = {
  isLoadingUser: boolean;
  isDeletingUser: boolean;
  isSavingProfile: boolean;
  isUpdatingUserPicture: boolean;
  user: User | null;
  auth0user: Auth0User | null;
  settings: Settings;
};

// Create initial state
export const state = {
  isLoadingUser: false,
  isDeletingUser: false,
  isSavingProfile: false,
  isUpdatingUserPicture: false,
  user: null,
  auth0user: null,
  settings: {},
} as State;
