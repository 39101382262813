import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState, Spark, SparkType } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { buildSparksSet, getSparks } from "@/services/sparks.service";
import { captureException } from "@/utils/errors";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_SPARKS](
    { commit, dispatch }: AugmentedActionContext,
    payload: SparkType,
  ): void;
  [ActionTypes.CLEAR_SPARKS]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_SPARKS]({ commit, dispatch, rootState }, type) {
    const sparkType: SparkType = type;
    const entities = rootState.challenge?.entities || [];
    const label =
      rootState.challenge.challenge?.subject !== ""
        ? rootState.challenge.challenge?.subject.split(", ") || []
        : [];

    // challenge type is required for problem and solution sparks
    // but not for constraint sparks
    const challengeType =
      sparkType === "constraint"
        ? []
        : rootState.challenge.challenge?.types || [];

    commit(MutationTypes.SET_LOADING, { sparkType, loading: true });
    commit(MutationTypes.SET_SPARKS, { sparkType, sparks: [] });
    commit(MutationTypes.SET_ERROR, { sparkType, error: false });

    return getSparks(sparkType, entities, label, challengeType)
      .then((response) => {
        const sparks: Spark[] = buildSparksSet(response.data, sparkType);
        commit(MutationTypes.SET_SPARKS, { sparkType, sparks });
      })
      .catch((error) => {
        captureException(error);
        commit(MutationTypes.SET_ERROR, { sparkType, error: true });
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      })
      .finally(() => {
        commit(MutationTypes.SET_LOADING, { sparkType, loading: false });
      });
  },
  [ActionTypes.CLEAR_SPARKS]({ commit }) {
    commit(MutationTypes.CLEAR_SPARKS);
  },
};
