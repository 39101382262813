
import {
  defineComponent,
  PropType,
  ref,
  reactive,
  computed,
  onMounted,
} from "vue";
import { useStore } from "@/store";
import { Resource, CaseStudy } from "@/store/learning/state";
import { truncate, generateUrlParamsString } from "@/utils/text";
import { useModal } from "@/composables/useModal";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import Markdown from "@/components/ui/markdown/Markdown.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import Guidance from "@/components/content/guidance/Guidance.vue";
import CreateIdea from "@/components/content/create-idea/CreateIdea.vue";
import ChallengeSparksListItem from "@/components/content/challenge-sparks-list/ChallengeSparksListItem.vue";
import RefreshIcon from "@/assets/images/icon-refresh.svg";
import AddIcon from "@/assets/images/icon-add.svg";
import TwitterLogoIcon from "@/assets/images/icon-twitter-logo.svg";
import TwitterReplyIcon from "@/assets/images/icon-twitter-reply.svg";
import TwitterRetweetIcon from "@/assets/images/icon-twitter-retweet.svg";
import TwitterLikeIcon from "@/assets/images/icon-twitter-like.svg";
import MediumIcon from "@/assets/images/icon-medium.svg";
import UKCoachingIcon from "@/assets/images/icon-uk-coaching.svg";
import BlogIcon from "@/assets/images/icon-blog.svg";
import TrophyIcon from "@/assets/images/icon-trophy.svg";
import PeopleIcon from "@/assets/images/icon-people.svg";

export default defineComponent({
  components: {
    AppButton,
    Guidance,
    Markdown,
    ModalContent,
    CreateIdea,
    ChallengeSparksListItem,
    AddIcon,
    TwitterLogoIcon,
    TwitterReplyIcon,
    TwitterRetweetIcon,
    TwitterLikeIcon,
    MediumIcon,
    UKCoachingIcon,
    BlogIcon,
    TrophyIcon,
    PeopleIcon,
    RefreshIcon,
    LoadingIcon,
  },
  props: {
    item: {
      type: Object as PropType<Resource | CaseStudy>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    // modals
    const modalState = reactive({
      addIdea: false,
      learningSpark: false,
    });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const isUKCoachingResource = computed(
      () =>
        props.item.source === "uk_coaching" ||
        props.item.uri?.includes("ukcoaching.org"),
    );

    const isLoadingLearningPrompts = computed(
      () => store.state.ideaSparks.isLoadingLearningPrompts,
    );

    const learningSparks = computed(
      () => store.getters["ideaSparks/getLearningPrompts"],
    );

    const challenge = computed(() => store.state.challenge.challenge);

    // calculate context
    const context = computed(() =>
      store.getters["learning/getContextForResource"](props.item),
    );
    const isShowingContext = ref(false);
    const toggleContext = () => {
      isShowingContext.value = !isShowingContext.value;

      if (isShowingContext.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_LEARNING_TEXT_IDEA_SHOW_CONTEXT`,
        });
      }
    };

    // themes - limit to n initially, expand for more
    const initialThemeCount = 5;
    const themeCount = computed(() => props.item.themes.length);
    const initialThemes = computed(() =>
      props.item.themes.slice(
        0,
        themeCount.value > initialThemeCount
          ? initialThemeCount
          : themeCount.value,
      ),
    );
    const extraThemes = computed(() =>
      themeCount.value > initialThemeCount
        ? props.item.themes.slice(initialThemeCount, themeCount.value)
        : [],
    );
    const isShowingExtraThemes = ref(false);
    const toggleExtraThemes = () => {
      isShowingExtraThemes.value = !isShowingExtraThemes.value;

      if (isShowingExtraThemes.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_LEARNING_TEXT_IDEA_SHOW_MORE_THEMES`,
        });
      }
    };

    // theme selection
    const selectedTheme = ref("");
    const handleThemeClick = (theme: string) => {
      selectedTheme.value = theme;
      if (challenge.value) {
        store.dispatch("ideaSparks/GET_LEARNING_PROMPTS", {
          text: challenge.value.description,
          label: challenge.value.subject.split(","),
          ref: selectedTheme.value,
        });
      }
      showModal("learningSpark");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_LEARNING_TEXT_THEME_MODAL_SHOW`,
      });
    };

    const showIdeaModal = () => {
      showModal("addIdea");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_LEARNING_TEXT_IDEA_ADD_MODAL_SHOW`,
      });
    };

    const handleLearnMoreClick = () => {
      const params = generateUrlParamsString({ q: selectedTheme.value });
      const url = `https://www.google.co.uk/search?${params}`;
      window.open(url, "_blank");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_LEARNING_LEARN_MORE_CLICK`,
      });
    };

    const handleLinkClick = () => {
      if (isUKCoachingResource.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_LEARNING_UK_COACHING_LINK_CLICK`,
          eventParams: { url: props.item.uri },
        });
      } else {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_LEARNING_LINK_CLICK`,
          eventParams: { url: props.item.uri },
        });
      }
    };

    onMounted(() => {
      // Request themes if needed
      if (
        props.item.themes.length === 0 &&
        ["papers", "resources"].includes(props.item.learningType)
      ) {
        store.dispatch("learning/GET_LEARNING_ENTITIES", props.item);
      }
    });

    return {
      truncate,
      context,
      isUKCoachingResource,
      isShowingContext,
      toggleContext,
      initialThemes,
      extraThemes,
      isShowingExtraThemes,
      toggleExtraThemes,
      selectedTheme,
      handleThemeClick,
      handleLearnMoreClick,
      handleLinkClick,
      isLoadingLearningPrompts,
      learningSparks,
      isShown,
      showIdeaModal,
      showModal,
      closeModal,
    };
  },
});
