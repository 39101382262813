
import { defineComponent, PropType, ref, computed, watch } from "vue";
import { useStore } from "@/store";
import { Idea } from "@/store/challenge/state";
import { getRelativeDateString } from "@/utils/date";
import AutoResizeTextarea from "@/components/ui/auto-resize-textarea/AutoResizeTextarea.vue";
import ChallengeIdeaMenu from "./ChallengeIdeaMenu.vue";
import ChallengeIdeaFavourite from "./ChallengeIdeaFavourite.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import CheckIcon from "@/assets/images/icon-check.svg";
import ArrowUpIcon from "@/assets/images/icon-arrow-up.svg";
import ChallengeIdeaGuidance from "./ChallengeIdeaGuidance.vue";

export default defineComponent({
  components: {
    AutoResizeTextarea,
    ChallengeIdeaMenu,
    ChallengeIdeaFavourite,
    AppButton,
    CheckIcon,
    ArrowUpIcon,
    ChallengeIdeaGuidance,
  },
  props: {
    idea: {
      type: Object as PropType<Idea>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    startEditingIdea: {
      type: Function,
      required: true,
    },
    stopEditingIdea: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const challengeIsActive = computed(
      () => store.getters["challenge/getIsActive"],
    );

    const inputDescriptionEl = ref<typeof AutoResizeTextarea | null>(null);
    const inputDescription = ref(props.idea.description);
    const inputNotes = ref(props.idea.notes);

    const starred = ref(props.idea.isFavorite);

    const isExpanded = ref(false);
    const isShowingGuidance = ref(false);

    const hasInitialDetails = computed(
      () =>
        inputDescription.value === props.idea.description &&
        inputNotes.value === props.idea.notes,
    );
    watch(hasInitialDetails, () => {
      if (hasInitialDetails.value) {
        props.stopEditingIdea(props.idea);
      } else {
        props.startEditingIdea(props.idea);
      }
    });

    const handleFavourite = () => {
      starred.value = !starred.value;
      store.dispatch("challenge/SET_IDEA_FAVORITE", {
        _id: props.idea._id,
        data: {
          isFavorite: starred.value,
        },
      });

      if (starred.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_IDEA_FAVOURITE_ADD`,
        });
      } else {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_IDEA_FAVOURITE_REMOVE`,
        });
      }
    };

    const handleExpand = () => {
      isExpanded.value = true;
      inputDescriptionEl.value?.focus();
    };

    const handleToggleGuidance = () => {
      isShowingGuidance.value = !isShowingGuidance.value;
    };

    const handleCancel = () => {
      isExpanded.value = false;
      isShowingGuidance.value = false;
      inputDescription.value = props.idea.description;
      inputNotes.value = props.idea.notes;
    };
    const handleClose = () => {
      isExpanded.value = false;
    };

    const handleUpdate = () => {
      store.dispatch("challenge/UPDATE_IDEA", {
        _id: props.idea._id,
        data: {
          description: inputDescription.value,
          notes: inputNotes.value,
        },
      });
      isExpanded.value = false;
      isShowingGuidance.value = false;

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_IDEA_UPDATE`,
      });
    };

    return {
      challengeIsActive,
      getRelativeDateString,
      inputDescription,
      inputDescriptionEl,
      inputNotes,
      starred,
      isExpanded,
      isShowingGuidance,
      handleToggleGuidance,
      handleFavourite,
      handleExpand,
      handleCancel,
      handleUpdate,
      handleClose,
      hasInitialDetails,
    };
  },
});
