// Mutations enums
export enum MutationTypes {
  SET_TRIZ_PROMPTS = "SET_TRIZ_PROMPTS",
  SET_SCAMPER_PROMPTS = "SET_SCAMPER_PROMPTS",
  SET_LEARNING_PROMPTS = "SET_LEARNING_PROMPTS",
  SET_IDEATION_GUIDANCE = "SET_IDEATION_GUIDANCE",
  SET_REFINEMENT_GUIDANCE = "SET_REFINEMENT_GUIDANCE",
  SET_SPARKS_GUIDANCE = "SET_SPARKS_GUIDANCE",
  SET_LEARNING_GUIDANCE = "SET_LEARNING_GUIDANCE",
  SET_IDEAS_GUIDANCE = "SET_IDEAS_GUIDANCE",
  SET_PLAN_GUIDANCE = "SET_PLAN_GUIDANCE",
  SET_LOADING_TRIZ_PROMPTS = "SET_LOADING_TRIZ_PROMPTS",
  SET_LOADING_SCAMPER_PROMPTS = "SET_LOADING_SCAMPER_PROMPTS",
  SET_LOADING_LEARNING_PROMPTS = "SET_LOADING_LEARNING_PROMPTS",
  CLEAR_TRIZ_PROMPTS = "CLEAR_TRIZ_PROMPTS",
  CLEAR_SCAMPER_PROMPTS = "CLEAR_SCAMPER_PROMPTS",
  CLEAR_LEARNING_PROMPTS = "CLEAR_LEARNING_PROMPTS",
}
