
import { defineComponent, inject, PropType } from "vue";
import { UpdateActiveTab, TabType } from "@/types/components";

export default defineComponent({
  components: {},
  props: {
    slug: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: "",
    },
    tabType: {
      type: String as PropType<TabType>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const activeTab = inject<string>("activeTab");
    const updateActiveTab = inject<UpdateActiveTab>("updateActiveTab");

    const handleTabClick = () => {
      if (!updateActiveTab) return;
      updateActiveTab(props.slug);
    };

    return {
      activeTab,
      handleTabClick,
    };
  },
});
