
import { defineComponent, computed, ref, reactive, watch } from "vue";
import { useStore } from "@/store";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useModal } from "@/composables/useModal";
import ChallengeNavigation from "@/components/content/challenge-navigation/ChallengeNavigation.vue";
import ChallengeHeader from "@/components/content/challenge-header/ChallengeHeader.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Ribbon from "@/components/ui/ribbon/Ribbon.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import AutoResizeTextarea from "@/components/ui/auto-resize-textarea/AutoResizeTextarea.vue";
import GuidedTour from "@/components/ui/guided-tour/GuidedTour.vue";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Download from "@/components/content/download/Download.vue";
import DownloadIcon from "@/assets/images/icon-download.svg";
import ArrowRightIcon from "@/assets/images/icon-arrow-right.svg";
import FaceUnhappyIcon from "@/assets/images/icon-face-unhappy.svg";
import FaceNeutralIcon from "@/assets/images/icon-face-neutral.svg";
import FaceHappyIcon from "@/assets/images/icon-face-happy.svg";

export default defineComponent({
  components: {
    ContentBlock,
    ChallengeNavigation,
    ChallengeHeader,
    Editorial,
    Ribbon,
    AppButton,
    AppButtonRouterLink,
    AutoResizeTextarea,
    GuidedTour,
    ModalContent,
    Download,
    DownloadIcon,
    ArrowRightIcon,
    FaceUnhappyIcon,
    FaceNeutralIcon,
    FaceHappyIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const challenge = computed(() => store.state.challenge.challenge);
    const solution = computed(() => store.getters["challenge/getSolution"]);
    const confidenceLevel = computed(() => solution.value?.confidenceLevel);

    const challengeIsActive = computed(
      () => store.getters["challenge/getIsActive"],
    );
    const challengeIsResolved = computed(
      () => store.getters["challenge/getIsResolved"],
    );

    const isResolvingChallenge = ref(false);

    const inputReflection = ref(challenge.value?.resolution?.reflection || "");
    const inputPositives = ref(challenge.value?.resolution?.positives || "");
    const inputNegatives = ref(challenge.value?.resolution?.negatives || "");
    const inputRating = ref(challenge.value?.resolution?.rating || 0);
    const submitIsDisabled = computed(() => {
      return (
        (confidenceLevel.value &&
          confidenceLevel.value !== -1 &&
          !inputReflection.value) ||
        !inputPositives.value ||
        !inputNegatives.value ||
        !inputRating.value
      );
    });

    // has anything changed? check if the initial data matches the current input values.
    const hasInitialDetails = computed(
      () =>
        inputReflection.value ===
          (challenge.value?.resolution?.reflection || "") &&
        inputPositives.value ===
          (challenge.value?.resolution?.positives || "") &&
        inputNegatives.value ===
          (challenge.value?.resolution?.negatives || "") &&
        inputRating.value === (challenge.value?.resolution?.rating || 0),
    );

    const solutionIdeas = computed(
      () => store.getters["challenge/getSolutionIdeas"],
    );

    // modals
    const modalState = reactive({
      download: false,
    });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const showDownloadModal = () => {
      showModal("download");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_RESOLUTION_DOWNLOAD_MODAL_SHOW`,
      });
    };

    const setRating = (rating: number) => {
      inputRating.value = rating;
    };

    const handleFormSubmit = async () => {
      if (!solution.value || !challenge.value) return;
      await store.dispatch("challenge/RESOLVE_CHALLENGE", {
        _id: challenge.value._id,
        resolution: {
          solution: solution.value._id,
          reflection: inputReflection.value,
          rating: inputRating.value,
          positives: inputPositives.value,
          negatives: inputNegatives.value,
        },
      });
      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_RESOLVED`,
      });
      isResolvingChallenge.value = true;
      router.push({ name: "home" });
    };

    const handleReactivateChallenge = async () => {
      if (!challenge.value) return;
      await store.dispatch("challenges/RESTORE_CHALLENGE", challenge.value._id);
      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_RESTORE`,
      });
    };

    const isLoading = computed(() => store.state.challenge.isLoading);
    const hasPlan = computed(() => store.getters["challenge/getHasSolution"]);

    // only allow users to access the resolution view if they have a plan
    watch(
      isLoading,
      () => {
        if (!isLoading.value && challenge.value?.resolution) {
          inputReflection.value = challenge.value.resolution.reflection;
          inputPositives.value = challenge.value.resolution.positives;
          inputNegatives.value = challenge.value.resolution.negatives;
          inputRating.value = challenge.value.resolution.rating;

          if (!hasPlan.value) {
            router.replace({ name: "challenge-saved" });
          }
        }
      },
      { immediate: true },
    );

    onBeforeRouteLeave(() => {
      // show a warning if:
      // - there are changes in the resolution form
      // - we're not in the process of submitting the form
      if (!hasInitialDetails.value && !isResolvingChallenge.value) {
        const answer = window.confirm(
          "Do you really want to leave? Your changes will be lost!",
        );
        if (!answer) return false;
      }
    });

    return {
      challenge,
      challengeIsActive,
      challengeIsResolved,
      confidenceLevel,
      inputReflection,
      inputRating,
      inputPositives,
      inputNegatives,
      submitIsDisabled,
      hasInitialDetails,
      handleFormSubmit,
      handleReactivateChallenge,
      setRating,
      solutionIdeas,
      isShown,
      showDownloadModal,
      closeModal,
    };
  },
});
