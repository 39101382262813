
import {
  defineComponent,
  PropType,
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { UserData } from "@/store/user/state";
import { useStore } from "@/store";
import CameraIcon from "@/assets/images/icon-camera.svg";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    CameraIcon,
    LoadingIcon,
  },
  props: {
    user: {
      type: Object as PropType<UserData>,
      required: true,
    },
  },
  setup() {
    const store = useStore();

    // dropzone - loosely based on:
    // https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    const dropzoneEl = ref<HTMLElement | null>(null);
    const fileInputEl = ref<HTMLInputElement | null>(null);

    const isUploading = computed(() => store.state.user.isUpdatingUserPicture);
    const isHovered = ref(false);

    const handleUploadImage = () => {
      fileInputEl.value?.click();
    };

    const preventDefaults = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const highlight = () => {
      isHovered.value = true;
    };

    const unhighlight = () => {
      isHovered.value = false;
    };

    const handleFileDropped = (e: DragEvent) => {
      const dt = e.dataTransfer;
      const droppedFiles = dt?.files;

      if (droppedFiles && fileInputEl.value?.files) {
        fileInputEl.value.files = droppedFiles;
        uploadFile(fileInputEl.value.files[fileInputEl.value.files.length - 1]);
      }
    };

    const handleUploadFile = () => {
      if (fileInputEl.value?.files) {
        const files = Array.from(fileInputEl.value.files);
        if (files.length) {
          uploadFile(files[files.length - 1]);
        }
      }
    };

    const uploadFile = (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      store.dispatch("user/UPDATE_USER_PICTURE", formData);
      store.dispatch("tracking/TRACK_EVENT", {
        action: `USER_PROFILE_PICTURE_UPDATE`,
      });
    };

    // Prevent defaults
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      onMounted(() => {
        dropzoneEl.value?.addEventListener(eventName, preventDefaults);
      });
      onBeforeUnmount(() => {
        dropzoneEl.value?.removeEventListener(eventName, preventDefaults);
      });
    });

    // Highlight dropzone on drag enter/over
    ["dragenter", "dragover"].forEach((eventName) => {
      onMounted(() => {
        dropzoneEl.value?.addEventListener(eventName, highlight);
      });
      onBeforeUnmount(() => {
        dropzoneEl.value?.removeEventListener(eventName, highlight);
      });
    });

    // Remove highlight on drag leave/drop
    ["dragleave", "drop"].forEach((eventName) => {
      onMounted(() => {
        dropzoneEl.value?.addEventListener(eventName, unhighlight);
      });
      onBeforeUnmount(() => {
        dropzoneEl.value?.removeEventListener(eventName, unhighlight);
      });
    });

    // handle drop
    onMounted(() => {
      dropzoneEl.value?.addEventListener("drop", handleFileDropped);
    });
    onBeforeUnmount(() => {
      dropzoneEl.value?.removeEventListener("drop", handleFileDropped);
    });

    onMounted(() => {
      fileInputEl.value?.addEventListener("change", handleUploadFile);
    });
    onBeforeUnmount(() => {
      fileInputEl.value?.removeEventListener("change", handleUploadFile);
    });

    return {
      isUploading,
      isHovered,
      dropzoneEl,
      fileInputEl,
      handleUploadImage,
    };
  },
});
