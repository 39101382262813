export type Notification = {
  _id: string;
  read: boolean;
  key: string;
  summary: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

// Declare state
export type State = {
  notifications: Notification[];
};

// Create initial state
export const state = {
  notifications: [],
} as State;
