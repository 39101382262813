
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useStore } from "@/store";
import ChallegesListItem from "./ChallengesListItem.vue";
import ChallengesListDropdownFilter from "./ChallengesListDropdownFilter.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import SearchIcon from "@/assets/images/icon-search.svg";
import CloseIcon from "@/assets/images/icon-close.svg";

export default defineComponent({
  components: {
    ChallegesListItem,
    ChallengesListDropdownFilter,
    LoadingIcon,
    SearchIcon,
    CloseIcon,
  },
  props: {
    isResolved: {
      type: Boolean,
      required: true,
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const isLoading = computed(() => store.getters["challenges/getIsLoading"]);

    // filter: text
    const searchText = ref("");
    const resetSearchText = () => {
      searchText.value = "";
    };
    const hasSearchText = computed(() => searchText.value.length > 0);

    // filter: tags
    const filterTags = computed(() =>
      store.getters["challenges/getFilterTags"](props.isResolved),
    );

    const selectedFilterTagOptions = ref<string[]>([]);
    const resetSelectedFilterTagOptions = () => {
      selectedFilterTagOptions.value = [];
    };

    const filterTagOptions = computed(() => {
      return filterTags.value.map((filter) => ({
        label: filter,
        action: () => {
          if (selectedFilterTagOptions.value.includes(filter)) {
            selectedFilterTagOptions.value.splice(
              selectedFilterTagOptions.value.indexOf(filter),
              1,
            );
          } else {
            selectedFilterTagOptions.value.push(filter);
          }
        },
      }));
    });

    // filter: topics
    const filterTopics = computed(() =>
      store.getters["challenges/getFilterTopics"](props.isResolved),
    );

    const selectedFilterTopicOptions = ref<string[]>([]);
    const resetSelectedFilterTopicOptions = () => {
      selectedFilterTopicOptions.value = [];
    };

    const filterTopicOptions = computed(() => {
      return filterTopics.value.map((filter) => ({
        label: filter,
        action: () => {
          if (selectedFilterTopicOptions.value.includes(filter)) {
            selectedFilterTopicOptions.value.splice(
              selectedFilterTopicOptions.value.indexOf(filter),
              1,
            );
          } else {
            selectedFilterTopicOptions.value.push(filter);
          }
        },
      }));
    });

    // filter: subjects
    const filterSubjects = computed(() =>
      store.getters["challenges/getFilterSubjects"](props.isResolved),
    );

    const selectedFilterSubjectOptions = ref<string[]>([]);
    const resetSelectedFilterSubjectOptions = () => {
      selectedFilterSubjectOptions.value = [];
    };

    const filterSubjectOptions = computed(() => {
      return filterSubjects.value.map((filter) => {
        const label =
          store.getters["challenges/getSubjectLabelfromType"](filter);
        return {
          label,
          type: filter,
          action: () => {
            if (selectedFilterSubjectOptions.value.includes(filter)) {
              selectedFilterSubjectOptions.value.splice(
                selectedFilterSubjectOptions.value.indexOf(filter),
                1,
              );
            } else {
              selectedFilterSubjectOptions.value.push(filter);
            }
          },
        };
      });
    });

    const clearFilter = () => {
      resetSelectedFilterTagOptions();
      resetSelectedFilterTopicOptions();
      resetSelectedFilterSubjectOptions();
    };

    // challenges
    const challenges = computed(() => {
      const challenges = store.getters["challenges/getChallenges"](
        props.isResolved,
        searchText.value,
        selectedFilterTagOptions.value,
        selectedFilterTopicOptions.value,
        selectedFilterSubjectOptions.value,
      );
      if (props.limit) {
        return challenges?.slice(0, props.limit);
      }
      return challenges;
    });

    const hasChallenges = computed(
      () => challenges.value.length > 0 && !isLoading.value,
    );

    const getChallenges = () => {
      if (props.isResolved) {
        store.dispatch("challenges/GET_RESOLVED_CHALLENGES");
      } else {
        store.dispatch("challenges/GET_ACTIVE_CHALLENGES");
      }
    };

    onMounted(() => {
      getChallenges();
      store.dispatch("challenges/GET_ALL_SUBJECT_TYPES");
    });

    // as we are using tabs via the router, the component isn't re-rendered
    // and so we also need to watch the (reactive) prop in order to identify
    // when the tab changes, and update/reset content accordingly
    watch(
      () => props.isResolved,
      () => {
        getChallenges();
        resetSearchText();
        resetSelectedFilterTagOptions();
        resetSelectedFilterTopicOptions();
        resetSelectedFilterSubjectOptions();
      },
    );

    return {
      isLoading,
      searchText,
      resetSearchText,
      hasSearchText,
      challenges,
      hasChallenges,
      filterTagOptions,
      selectedFilterTagOptions,
      filterTopicOptions,
      selectedFilterTopicOptions,
      filterSubjectOptions,
      selectedFilterSubjectOptions,
      clearFilter,
    };
  },
});
