import { State as RootState } from "@/store";
import { truncate } from "@/utils/text";
import { GetterTree } from "vuex";
import {
  State as LocalState,
  Resource,
  CaseStudy,
  learningTabs,
} from "./state";

interface GetLearning {
  (type: string | undefined): Resource[] | CaseStudy[];
}

interface GetLoading {
  (type: string | undefined): boolean;
}

interface GetError {
  (type: string | undefined): boolean;
}

interface GetContext {
  (resource: Resource | CaseStudy): string | void;
}

interface GetLearningType {
  (type: string | undefined): string | undefined;
}

// Getters types
export type Getters = {
  getLearningTypeFromSlug(state: LocalState): GetLearningType;
  getLearningByType(state: LocalState): GetLearning;
  getLoadingByType(state: LocalState): GetLoading;
  getErrorByType(state: LocalState): GetError;
  getContextForResource(state: LocalState): GetContext;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  // convert (external) learning URL slug to (internal) learning type
  getLearningTypeFromSlug: () => (slug) => {
    const tab = learningTabs.find((tab) => tab.slug === slug);
    if (tab) {
      return tab.learningType;
    }
  },
  getLearningByType: (state) => (type) => {
    let learning: Resource[] | CaseStudy[] = [];
    switch (true) {
      case type === "papers":
        learning = state.papers;
        break;
      case type === "resources":
        learning = state.resources;
        break;
      case type === "caseStudies":
        learning = state.caseStudies;
        break;
      default:
        learning = [];
        break;
    }
    return learning;
  },
  getLoadingByType: (state) => (type) => {
    let isLoading = false;
    switch (true) {
      case type === "papers":
        isLoading = state.isLoadingPapers;
        break;
      case type === "resources":
        isLoading = state.isLoadingResources;
        break;
      case type === "caseStudies":
        isLoading = state.isLoadingCaseStudies;
        break;
      default:
        isLoading = false;
        break;
    }
    return isLoading;
  },
  getErrorByType: (state) => (type) => {
    let hasError = false;
    switch (true) {
      case type === "papers":
        hasError = state.hasErrorPapers;
        break;
      case type === "resources":
        hasError = state.hasErrorResources;
        break;
      case type === "caseStudies":
        hasError = state.hasErrorCaseStudies;
        break;
      default:
        hasError = false;
        break;
    }
    return hasError;
  },

  getContextForResource: () => (resource) => {
    let context;

    // if snippet and context are both set, identify context in snippet
    // and create a markdown string of the result for rendering
    if (
      "context" in resource &&
      typeof resource.context === "string" &&
      resource.context.length > 0
    ) {
      const search = resource.context.trim();
      const replace = `***${search}***`;
      context = resource.snippet.replace(search, replace);
    }

    // if only the snippet is set, use it
    else if ("snippet" in resource) {
      context = resource.snippet;
    }

    // if challenge is set it's a case study
    else if ("challenge" in resource) {
      context = resource.challenge;
    }

    // limit context length
    if (context) {
      return truncate(context, 600);
    }
  },
};
