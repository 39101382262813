// Action enums
export enum ActionTypes {
  GET_USER = "GET_USER",
  BOOTSTRAP_USER = "BOOTSTRAP_USER",
  DELETE_USER = "DELETE_USER",
  UPDATE_USER_PICTURE = "UPDATE_USER_PICTURE",
  UPDATE_USER_ONBOARDING = "UPDATE_USER_ONBOARDING",
  UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS",
  UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE",
  UPDATE_USER_FLOW = "UPDATE_USER_FLOW",
}
