import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap items-stretch justify-start mt-3 -mx-3" }
const _hoisted_2 = { class: "flex justify-center mt-3" }
const _hoisted_3 = { class: "flex flex-row justify-center mt-8 mb-6" }
const _hoisted_4 = {
  key: 1,
  class: "flex flex-row justify-center my-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChallengeSparksListItem = _resolveComponent("ChallengeSparksListItem")!
  const _component_RefreshIcon = _resolveComponent("RefreshIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Guidance = _resolveComponent("Guidance")!

  return (_ctx.hasSparks)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sparks, (spark, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "w-full sm:w-1/2 lg:w-1/3 p-3"
            }, [
              _createVNode(_component_ChallengeSparksListItem, {
                spark: spark,
                index: index
              }, null, 8, ["spark", "index"])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppButton, {
            id: "refresh-sparks",
            type: "secondary",
            onClick: _ctx.handleRefreshClick
          }, {
            iconBefore: _withCtx(() => [
              _createVNode(_component_RefreshIcon, { class: "mr-2 fill-current" })
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Refresh ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Guidance, null, {
            text: _withCtx(() => [
              _createTextVNode(" You can always edit your challenge to refocus the sparks ")
            ]),
            _: 1
          })
        ])
      ], 64))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_Guidance, null, {
          text: _withCtx(() => [
            _createTextVNode(" Please add more detail to your challenge description so we can generate sparks for you. ")
          ]),
          _: 1
        })
      ]))
}