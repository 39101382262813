import {
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  Module,
} from "vuex";
import { State as LocalState, state } from "./state";
import { Mutations, mutations } from "./mutations";
import { State as RootState } from "@/store";
import { Actions, actions } from "./actions";
import { Namespaced } from "../namespaced";

// State type
export type State = LocalState;

type NamespacedMutations = Namespaced<Mutations, "possibleIdeas">;
type NamespacedActions = Namespaced<Actions, "possibleIdeas">;

// Setup store type
export type Store<S = LocalState> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof NamespacedMutations>(
    key: K,
    payload?: Parameters<NamespacedMutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<NamespacedMutations[K]>;
} & {
  dispatch<K extends keyof NamespacedActions>(
    key: K,
    payload?: Parameters<NamespacedActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<NamespacedActions[K]>;
};

export const possibleIdeas: Module<LocalState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};
