import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, User, Auth0User, Settings } from "./state";

type UserSetting = {
  key: string;
  value: boolean;
};

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_LOADING_USER](state: S, isLoadingUser: boolean): void;
  [MutationTypes.SET_DELETING_USER](state: S, isDeletingUser: boolean): void;
  [MutationTypes.SET_SAVING_PROFILE](state: S, isSavingProfile: boolean): void;
  [MutationTypes.SET_USER](state: S, user: User | null): void;
  [MutationTypes.SET_USER_SETTINGS](state: S, settings: Settings): void;
  [MutationTypes.SET_AUTH0_USER](state: S, auth0user: Auth0User | null): void;
  [MutationTypes.UPDATE_USER_SETTINGS](
    state: S,
    userSetting: UserSetting
  ): void;
  [MutationTypes.SET_USER_PICTURE](state: S, picture: string): void;
  [MutationTypes.SET_UPDATING_USER_PICTURE](
    state: S,
    isUpdatingUserPicture: boolean
  ): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_LOADING_USER](state, isLoadingUser) {
    state.isLoadingUser = isLoadingUser;
  },
  [MutationTypes.SET_DELETING_USER](state, isDeletingUser) {
    state.isDeletingUser = isDeletingUser;
  },
  [MutationTypes.SET_SAVING_PROFILE](state, isSavingProfile) {
    state.isSavingProfile = isSavingProfile;
  },
  [MutationTypes.SET_USER](state: LocalState, user) {
    state.user = user;
  },
  [MutationTypes.SET_USER_SETTINGS](state: LocalState, settings) {
    state.settings = settings;
  },
  [MutationTypes.SET_AUTH0_USER](state: LocalState, auth0user) {
    state.auth0user = auth0user;
  },
  [MutationTypes.UPDATE_USER_SETTINGS](state: LocalState, userSetting) {
    state.settings = {
      ...state.settings,
      [userSetting.key]: userSetting.value,
    };
  },
  [MutationTypes.SET_USER_PICTURE](state: LocalState, picture) {
    if (state.user) {
      state.user.picture = picture;
    }
  },
  [MutationTypes.SET_UPDATING_USER_PICTURE](
    state: LocalState,
    isUpdatingUserPicture
  ) {
    state.isUpdatingUserPicture = isUpdatingUserPicture;
  },
};
