import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import {
  buildSparksSet,
  getTrizPrompts,
  getScamperPrompts,
  getLearningPrompts,
  getGuidance,
} from "@/services/sparks.service";
import { captureException } from "@/utils/errors";

export type LearningPromptsPayload = {
  text: string;
  label: string[];
  ref: string;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_TRIZ_PROMPTS](
    { commit, dispatch }: AugmentedActionContext,
    payload: string,
  ): void;
  [ActionTypes.GET_SCAMPER_PROMPTS](
    { commit, dispatch }: AugmentedActionContext,
    payload: string,
  ): void;
  [ActionTypes.GET_LEARNING_PROMPTS](
    { commit, dispatch }: AugmentedActionContext,
    payload: LearningPromptsPayload,
  ): void;
  [ActionTypes.GET_GUIDANCE](
    { commit }: AugmentedActionContext,
    payload: string,
  ): void;
}

export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_TRIZ_PROMPTS]({ commit, dispatch }, payload) {
    commit(MutationTypes.SET_LOADING_TRIZ_PROMPTS, true);
    return getTrizPrompts(payload)
      .then((response) => {
        commit(MutationTypes.SET_TRIZ_PROMPTS, response.data);
      })
      .catch((error) => {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      })
      .finally(() => {
        commit(MutationTypes.SET_LOADING_TRIZ_PROMPTS, false);
      });
  },
  [ActionTypes.GET_SCAMPER_PROMPTS]({ commit, dispatch }, payload) {
    commit(MutationTypes.SET_LOADING_SCAMPER_PROMPTS, true);
    return getScamperPrompts(payload)
      .then((response) => {
        commit(MutationTypes.SET_SCAMPER_PROMPTS, response.data);
      })
      .catch((error) => {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      })
      .finally(() => {
        commit(MutationTypes.SET_LOADING_SCAMPER_PROMPTS, false);
      });
  },
  [ActionTypes.GET_LEARNING_PROMPTS]({ commit, dispatch }, payload) {
    commit(MutationTypes.SET_LOADING_LEARNING_PROMPTS, true);
    return getLearningPrompts(payload)
      .then((response) => {
        const sparks = buildSparksSet(response.data, "learning");
        commit(MutationTypes.SET_LEARNING_PROMPTS, sparks);
      })
      .catch((error) => {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      })
      .finally(() => {
        commit(MutationTypes.SET_LOADING_LEARNING_PROMPTS, false);
      });
  },
  [ActionTypes.GET_GUIDANCE]({ commit }, payload) {
    const guidanceType = payload;
    return getGuidance(guidanceType)
      .then((response) => {
        switch (guidanceType) {
          case "ideation":
            commit(MutationTypes.SET_IDEATION_GUIDANCE, response.data);
            break;
          case "refinement":
            commit(MutationTypes.SET_REFINEMENT_GUIDANCE, response.data);
            break;
          case "sparks":
            commit(MutationTypes.SET_SPARKS_GUIDANCE, response.data);
            break;
          case "learning":
            commit(MutationTypes.SET_LEARNING_GUIDANCE, response.data);
            break;
          case "ideas":
            commit(MutationTypes.SET_IDEAS_GUIDANCE, response.data);
            break;
          case "plan":
            commit(MutationTypes.SET_PLAN_GUIDANCE, response.data);
            break;
        }
      })
      .catch((error) => {
        captureException(error);
      });
  },
};
