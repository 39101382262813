import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import { LearningPromptsPayload } from "@/store/idea-sparks/actions";
import { Spark } from "@/store/sparks/state";
import { Entity } from "@/store/challenge/state";

/* types */

type Sparks = {
  [key: string]: Spark;
};

/* helpers */

export const buildSparksSet = (sparks: Sparks, type: string): Spark[] => {
  const sparksList: Spark[] = [];
  Object.keys(sparks).forEach((key) => {
    sparks[key].origin = type;
    sparks[key].sparkText = sparks[key].text;
    sparksList.push(sparks[key]);
  });
  return sparksList;
};

/* api calls */

export const getSparks = (
  sparkType: string,
  entities: Entity[],
  label: string[],
  challengeType: string[],
): AxiosPromise => {
  return apiClient.post(`/sparks/${sparkType}`, {
    entities,
    label,
    challengeType,
    lang: "en",
  });
};

export const getLearningPrompts = ({
  text,
  label,
  ref,
}: LearningPromptsPayload): AxiosPromise => {
  return apiClient.post(`/sparks/research`, {
    text,
    label,
    ref,
  });
};

export const getTrizPrompts = (text: string): AxiosPromise => {
  return apiClient.post(`/sparks/idea`, {
    text,
    method: "triz",
    size: 2,
  });
};

export const getScamperPrompts = (ref: string): AxiosPromise => {
  return apiClient.post(`/sparks/idea`, {
    ref,
    method: "scamper",
    size: 2,
  });
};

export const getGuidance = (guidanceType: string): AxiosPromise => {
  return apiClient.post(`/sparks/idea`, {
    method: guidanceType,
  });
};
