import { ActionContext, ActionTree, CommitOptions } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState, Notification } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import {
  getNotifications,
  setNotificationAsRead,
  setAllNotificationsAsRead,
} from "@/services/notifications.service";
import { captureException } from "@/utils/errors";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_NOTIFICATIONS]({ commit }: AugmentedActionContext): void;
  [ActionTypes.SET_NOTIFICATION_AS_READ](
    { dispatch }: AugmentedActionContext,
    payload: string
  ): void;
  [ActionTypes.SET_ALL_NOTIFICATIONS_AS_READ](
    { dispatch }: AugmentedActionContext,
    payload: Notification[]
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_NOTIFICATIONS]({ commit }) {
    return getNotifications()
      .then((response) => {
        const notifications: Notification[] = response.data || [];
        commit(MutationTypes.SET_NOTIFICATIONS, notifications);
      })
      .catch((error) => {
        captureException(error);
      });
  },
  [ActionTypes.SET_NOTIFICATION_AS_READ]({ dispatch }, id) {
    return setNotificationAsRead(id)
      .then(() => {
        dispatch(ActionTypes.GET_NOTIFICATIONS);
      })
      .catch((error) => {
        captureException(error);
      });
  },
  [ActionTypes.SET_ALL_NOTIFICATIONS_AS_READ]({ dispatch }) {
    return setAllNotificationsAsRead()
      .then(() => {
        dispatch(ActionTypes.GET_NOTIFICATIONS);
      })
      .catch((error) => {
        captureException(error);
      });
  },
};
