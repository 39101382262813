
import { defineComponent, PropType } from "vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";

type RibbonType = "grey" | "primary";

export default defineComponent({
  components: {
    ContentBlock,
  },
  props: {
    ribbonType: {
      type: String as PropType<RibbonType>,
      required: false,
      default: "grey",
    },
  },
  setup() {
    return {};
  },
});
