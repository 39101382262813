
import { defineComponent, PropType, computed, onMounted } from "vue";
import { useStore } from "@/store";
import { Idea } from "@/store/challenge/state";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import RefreshIcon from "@/assets/images/icon-refresh.svg";

export default defineComponent({
  components: {
    AppButton,
    LoadingIcon,
    RefreshIcon,
  },
  props: {
    idea: {
      type: Object as PropType<Idea>,
      required: true,
    },
    inputDescription: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isLoading = computed(
      () => store.state.ideaSparks.isLoadingTrizPrompts,
    );

    const trizPrompts = computed(
      () => store.getters["ideaSparks/getTrizPrompts"],
    );

    const fetchPrompts = () => {
      store.dispatch("ideaSparks/GET_TRIZ_PROMPTS", props.inputDescription);

      store.dispatch("tracking/TRACK_EVENT", {
        action: `PROMPTS_TRIZ_FETCH`,
      });
    };

    onMounted(() => {
      store.dispatch("ideaSparks/GET_TRIZ_PROMPTS", props.idea?.description);
    });

    return {
      isLoading,
      fetchPrompts,
      trizPrompts,
    };
  },
});
