import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSpark = _resolveComponent("IconSpark")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_IconSpark, {
          key: 0,
          class: "flex-none w-4 h-4 mr-2.5 mt-1 text-cebai-primary fill-current"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_1, [
      _renderSlot(_ctx.$slots, "text")
    ])
  ], 64))
}