
import { defineComponent } from "vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    LoadingIcon,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "primary",
    },
    padding: {
      type: String,
      required: false,
      default: "default",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
