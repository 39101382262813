
import { defineComponent, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import AppContent from "@/components/layout/app-content/AppContent.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import ContentBlockDivider from "@/components/ui/content-block/ContentBlockDivider.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Toggle from "@/components/ui/toggle/Toggle.vue";

export default defineComponent({
  components: {
    AppContent,
    ContentBlock,
    ContentBlockDivider,
    AppButton,
    Editorial,
    Toggle,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    // cast history state to a string so that we can use it in path
    // if no back history, use home
    const continueLink = computed(() =>
      router.options.history.state.back
        ? String(router.options.history.state.back)
        : "/",
    );

    const preferencesCookies = computed({
      get() {
        // As the cookie could be null, we convert it to a boolean
        return !!store.state.cookies.allowPreferencesCookies;
      },
      set(value) {
        store.dispatch("cookies/SET_PREFERENCES_COOKIE", value);
      },
    });

    const statisticsCookies = computed({
      get() {
        // As the cookie could be null, we convert it to a boolean
        return !!store.state.cookies.allowStatisticsCookies;
      },
      set(value) {
        store.dispatch("cookies/SET_STATISTICS_COOKIE", value);
      },
    });

    const marketingCookies = computed({
      get() {
        // As the cookie could be null, we convert it to a boolean
        return !!store.state.cookies.allowMarketingCookies;
      },
      set(value) {
        store.dispatch("cookies/SET_MARKETING_COOKIE", value);
      },
    });

    const handleContinue = () => {
      // can't return to a new challenge page except home
      if (continueLink.value.includes("new")) {
        router.push({ path: "/" });
      } else {
        router.push({ path: continueLink.value });
      }
    };

    onMounted(() => {
      store.commit("cookies/DISMISS_COOKIE_BANNER");
    });

    return {
      preferencesCookies,
      statisticsCookies,
      marketingCookies,
      handleContinue,
    };
  },
});
