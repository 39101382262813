import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { class: "hidden md:block" }
const _hoisted_3 = {
  key: 1,
  ref: "el",
  class: "relative inline-block z-50"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "bg-cebai-primary w-10 h-10 rounded-full"
}
const _hoisted_6 = {
  key: 0,
  class: "px-4 pb-3 mb-3 border-b border-cebai-primary"
}
const _hoisted_7 = { class: "text-md font-robotoBold whitespace-nowrap text-cebai-grey-dark" }
const _hoisted_8 = { class: "text-sm whitespace-nowrap text-cebai-grey-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_TheHeaderProfileOption = _resolveComponent("TheHeaderProfileOption")!

  return (!_ctx.isAuthenticated)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppButton, {
            type: "secondary",
            class: "mr-2",
            onClick: _withModifiers(_ctx.toggleSignUp, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Sign up ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createVNode(_component_AppButton, {
          type: "primary",
          onClick: _withModifiers(_ctx.toggleLogin, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Log in ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "flex items-center",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleOpenState && _ctx.toggleOpenState(...args)), ["prevent"]))
        }, [
          (_ctx.user?.picture)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "w-10 h-10 object-cover rounded-full",
                src: _ctx.user.picture,
                alt: ""
              }, null, 8, _hoisted_4))
            : (_openBlock(), _createElementBlock("div", _hoisted_5))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute z-10 py-3 inline-block top-full left-0 md:left-auto md:right-0 rounded-xl bg-cebai-base-light transition-all shadow-[0_0_20px_0_rgba(51,51,51,0.1)]", {
        'mt-2 visible opacity-100': _ctx.isOpen,
        '-mt-2 invisible opacity-0': !_ctx.isOpen,
      }])
        }, [
          (_ctx.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.user?.firstName) + " " + _toDisplayString(_ctx.user?.lastName), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.user?.email), 1)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createBlock(_component_TheHeaderProfileOption, {
              key: index,
              label: option.label,
              action: option.action,
              onClick: _ctx.close
            }, null, 8, ["label", "action", "onClick"]))
          }), 128))
        ], 2)
      ], 512))
}