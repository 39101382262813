import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState, GuidedTourData } from "./state";
import { useRoute } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import type { StepEntity } from "v-onboarding/src/types/StepEntity";

// getter arrow function interfaces
interface GetSteps {
  (key: string): StepEntity[];
}

// Getters types
export type Getters = {
  getStepsFromName(
    state: LocalState,
    getters: Getters,
    rootState: RootState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rootGetters: any,
  ): GetSteps;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getStepsFromName: (state, getters, rootState, rootGetters) => (key) => {
    const route = useRoute();
    const auth0 = useAuth0();

    // booleans for conditional steps
    const hasIdeas =
      rootState.challenge.challenge?.ideas &&
      rootState.challenge.challenge.ideas.length > 0;
    const isActive = rootState.challenge.challenge?.status === 1;
    const hasPlan = rootState.challenge.challenge?.solutions?.length;
    const isShownExampleButton = rootState.challenge.isShownExampleButton;
    let hasSparks = false;
    let hasLearning = false;

    // check if any sparks exist in the active tab
    if (key === "challenge-sparks") {
      const slug = route.params.slug;
      const sparkType = rootGetters["sparks/getSparkTypeFromSlug"](slug);
      hasSparks = rootGetters["sparks/getSparksByType"](sparkType).length > 0;
    }
    // check if any learning exists in the active tab
    if (key === "challenge-learning") {
      const slug = route.params.slug;
      const learningType =
        rootGetters["learning/getLearningTypeFromSlug"](slug);
      hasLearning =
        rootGetters["learning/getLearningByType"](learningType).length > 0;
    }

    const guidedTourData: GuidedTourData = {
      "challenge-new": [
        // can only attach to the element when it exists - "Try an example" button
        ...(isShownExampleButton
          ? [
              {
                attachTo: { element: "#button-example" },
                content: {
                  title:
                    "You can click to try an example and press 'Be inspired' to see how it works.",
                  description:
                    "Pro Tip: Use the example to help you write your own challenge.",
                },
                options: {
                  scrollToStep: {
                    enabled: true,
                    options: {
                      behavior: "smooth",
                      block: "center",
                      inline: "nearest",
                    },
                  },
                  popper: {
                    placement: "bottom",
                  },
                },
              },
            ]
          : []),
        {
          attachTo: { element: "#input-subject" },
          content: {
            title: "Identify who is experiencing the problem or situation.",
            description:
              "Pro Tip: It might involve more than just one individual e.g., a coach and a player, or a whole team",
          },
          options: {
            popper: {
              placement: "bottom",
            },
          },
        },
        {
          attachTo: { element: "#input-types" },
          content: {
            title:
              "Select which topic areas your challenge covers e.g. nutrition, motivation etc.",
            description:
              "Pro Tip: Change the topics at a later stage to look at your challenge from different perspectives.",
          },
          options: {
            scrollToStep: {
              enabled: true,
              options: {
                behavior: "smooth",
                block: "center",
                inline: "nearest",
              },
            },
            popper: {
              placement: "bottom",
            },
          },
        },
        {
          attachTo: { element: "#input-description" },
          content: {
            title:
              "Describe the situation in a couple of sentences and include your desired outcome.",
            description:
              "Pro Tip: Keep it anonymous - never use names or identifying descriptions.",
          },
          options: {
            popper: {
              placement: "bottom",
            },
          },
        },
        {
          attachTo: { element: "#how-it-works" },
          content: {
            title:
              "Visit the Sport Sparks website here for details on how to use the app.",
            description:
              "Pro Tip: The video tutorials provide the most in depth step-by-step guide.",
          },
        },
        {
          attachTo: { element: "#more-guidance" },
          content: {
            title:
              "Click here for more guidance on the creativity techniques that Sport Sparks uses.",
            description:
              "Pro Tip: Use the resources and exercises to boost your creative problem solving know-how.",
          },
        },
        {
          attachTo: { element: "#tech-support" },
          content: {
            title: "Raise a request here with the Sport Sparks service desk.",
            description: "Pro Tip: Get in touch, we'd love to hear from you!",
          },
        },
      ],
      "saved-challenges": [
        {
          attachTo: { element: "#heading-saved-challenges" },
          content: {
            title: "You can manage your active and resolved challenges here.",
            description:
              "Pro Tip: Use the search and filtering functions to help you find the relevant challenge(s)",
          },
        },
      ],
      "challenge-sparks": [
        {
          attachTo: { element: "#tab-sparks" },
          content: {
            title:
              "Sparks inspire you with different perspectives to lead you to smarter solutions, faster.",
            description:
              "Pro Tip: Learn more about the sparks in the Sport Sparks website (see links in footer).",
          },
          options: {
            overlay: {
              padding: 6,
              borderRadius: 6,
            },
          },
        },
        {
          attachTo: { element: "#tab-explore" },
          content: {
            title: "Use explore sparks to consider different perspectives.",
            description:
              "Pro Tip: Get out of your comfort zone and keep an open mind!",
          },
          options: {
            overlay: {
              padding: 6,
              borderRadius: 6,
            },
          },
        },
        {
          attachTo: { element: "#tab-imagine" },
          content: {
            title:
              "Use imagine sparks to challenge boundaries and assumptions.",
            description:
              "Pro Tip: Get out of the 'same old, same old' patterns of thinking.",
          },
          options: {
            overlay: {
              padding: 6,
              borderRadius: 6,
            },
          },
        },
        {
          attachTo: { element: "#tab-solve" },
          content: {
            title: "Solve sparks help you to discover smart ideas.",
            description: "Pro Tip: Turbocharge your ideas!",
          },
          options: {
            overlay: {
              padding: 6,
              borderRadius: 6,
            },
          },
        },
        // can only attach to the element when it exists - must have spark results
        ...(hasSparks
          ? [
              {
                attachTo: { element: "#add-spark-0" },
                content: {
                  title:
                    "Click (+) to add an idea based on that spark to 'Your Ideas'.",
                  description:
                    "Pro Tip: Save as many as you like - keep an open mind and explore all the relevant options.",
                },
                options: {
                  overlay: {
                    borderRadius: 22,
                  },
                },
              },
            ]
          : []),
        // can only attach to the element when it exists - must have spark results
        ...(hasSparks
          ? [
              {
                attachTo: { element: "#refresh-sparks" },
                content: {
                  title: "Want more inspiration? Hit refresh for more sparks.",
                  description:
                    "Pro Tip: Editing the challenge can help focus the sparks generation.",
                },
              },
            ]
          : []),
      ],
      "challenge-learning": [
        ...(auth0.isAuthenticated.value
          ? [
              {
                attachTo: { element: "#tab-learning" },
                content: {
                  title: "Tailored know-how from credible, verified sources.",
                  description:
                    "Pro Tip: Look at the academic, resources and case study tabs.",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
            ]
          : [
              {
                attachTo: { element: "#tab-learning" },
                content: {
                  title: "Tailored know-how from credible, verified sources.",
                  description:
                    "Pro Tip: Login or sign up now to access these learning resources and more!",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
            ]),
        ...(auth0.isAuthenticated.value
          ? [
              {
                attachTo: { element: "#tab-academic" },
                content: {
                  title: `${auth0.isAuthenticated}Hyper-relevant coaching content from across academic websites.`,
                  description:
                    "Pro Tip: Click the header to go to the full article.",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
              {
                attachTo: { element: "#tab-practices" },
                content: {
                  title:
                    "Coaching know-how from reliable sports science sources.",
                  description:
                    "Pro Tip: Check out the videos, blogs and other types of resources.",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
              {
                attachTo: { element: "#tab-case-studies" },
                content: {
                  title:
                    "Case studies from elite coaches and verified online resources.",
                  description:
                    "Pro Tip: Clicking on a theme (below the title) will take you to a learning spark and a Google search for the term",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
            ]
          : []),
        // can only attach to the element when it exists - must have learning results
        ...(hasLearning
          ? [
              {
                attachTo: { element: "#add-learning-0" },
                content: {
                  title: "Click (+) to create an idea based on the learning.",
                  description:
                    "Pro Tip: Keep an open mind, the source may help inspire new ideas or ways of thinking about your challenge.",
                },
                options: {
                  overlay: {
                    borderRadius: 22,
                  },
                },
              },
            ]
          : []),
      ],
      "challenge-ideas": [
        // advice for an active challenge. User flow - still in process
        ...(isActive
          ? [
              {
                attachTo: { element: "#button-all-ideas" },
                content: {
                  title:
                    "Your ideas are saved here, review and refine them before creating your plan.",
                  description:
                    "Pro Tip: Use the star to mark your favourite ideas.",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
            ]
          : []),
        // advice for a resolved challenge. User flow - process has ended
        ...(!isActive
          ? [
              {
                attachTo: { element: "#button-all-ideas" },
                content: {
                  title:
                    "Your ideas were saved here. Some of these might be useful to resolve other challenges.",
                  description:
                    "Use the star to mark ideas that could be reused, or have proved useful.",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
            ]
          : []),
        // can only attach to the element when it exists - challenge must have idea(s)
        ...(hasIdeas
          ? [
              {
                attachTo: { element: "#button-download-ideas" },
                content: {
                  title:
                    "You can download and share your ideas with confidence.",
                  description: "Pro Tip: Share your ideas in a few clicks.",
                },
              },
            ]
          : []),
      ],
      "challenge-plan": [
        // advice for an active challenge. User flow - still in process
        ...(isActive
          ? [
              {
                attachTo: { element: "#input-plan" },
                content: {
                  title:
                    "Your action plan is here, ready for you to develop, discuss and deploy!",
                  description:
                    "Pro Tip: The most successful plans are always specific, measurable, attainable, resourced and timed, aka SMART!",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
            ]
          : []),
        // advice for a resolved challenge. User flow - process has ended
        ...(!isActive
          ? [
              {
                attachTo: { element: "#input-plan" },
                content: {
                  title:
                    "You can review this resolved action plan here, as a record of what happened, and what worked.",
                  description:
                    "Pro Tip: Reflect on each element of the plan. Was it successful? How could it have been improved?",
                },
                options: {
                  overlay: {
                    padding: 6,
                    borderRadius: 6,
                  },
                },
              },
            ]
          : []),
        // can only attach to the element when it exists - must be an active challenge with a solution
        ...(isActive && hasPlan
          ? [
              {
                attachTo: { element: "#button-resolution" },
                content: {
                  title:
                    "Once your plan is actioned, hit the resolve button to reflect on how well it went.",
                  description:
                    "Pro Tip: Provide as much feedback as you can, as this will help build a store of useful information for future reference.",
                },
              },
            ]
          : []),
      ],
      "challenge-resolution": [
        ...(isActive
          ? [
              {
                attachTo: { element: "#input-positives" },
                content: {
                  title:
                    "Reflect on how things went with your plan here, both good and bad.",
                  description:
                    "Pro Tip: Reflecting on your plan can help retain valuable learning.",
                },
              },
              {
                attachTo: { element: "#button-resolve-challenge" },
                content: {
                  title:
                    "Resolved challenges appear in the saved challenges page in their own tab.",
                  description:
                    "Pro Tip: Look at your resolved plans from time to time to get useful insights and reminders.",
                },
              },
            ]
          : [
              {
                attachTo: { element: "#button-reactivate" },
                content: {
                  title:
                    "If you want to edit or reopen this challenge, you need to reactiviate it by clicking here.",
                  description:
                    "Pro Tip: Remember to resolve your challenge again after you have completed the updates.",
                },
              },
            ]),
      ],
    };

    const name = key as string;
    return guidedTourData[name];
  },
};
