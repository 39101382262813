import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_2 = { class: "w-8" }
const _hoisted_3 = {
  key: 1,
  class: "relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_CaseStudy = _resolveComponent("CaseStudy", true)!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LoadingIcon)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        (_ctx.caseStudy)
          ? (_openBlock(), _createBlock(_component_CaseStudy, {
              key: 0,
              "case-study": _ctx.caseStudy
            }, null, 8, ["case-study"]))
          : _createCommentVNode("", true)
      ]))
}