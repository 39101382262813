import { Store as VuexStore, DispatchOptions, Module } from "vuex";
import { State as LocalState, state } from "./state";
import { State as RootState } from "@/store";
import { Actions, actions } from "./actions";
import { Namespaced } from "../namespaced";

// State type
export type State = LocalState;

type NamespacedActions = Namespaced<Actions, "tracking">;

// Setup store type
export type Store<S = LocalState> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  dispatch<K extends keyof NamespacedActions>(
    key: K,
    payload?: Parameters<NamespacedActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<NamespacedActions[K]>;
};

export const tracking: Module<LocalState, RootState> = {
  namespaced: true,
  state,
  actions,
};
