import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Resource, CaseStudy, Theme } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_PAPERS](state: S, papers: Resource[]): void;
  [MutationTypes.SET_RESOURCES](state: S, resources: Resource[]): void;
  [MutationTypes.SET_CASE_STUDIES](state: S, caseStudies: CaseStudy[]): void;
  [MutationTypes.SET_LOADING_PAPERS](state: S, isLoadingPapers: boolean): void;
  [MutationTypes.SET_LOADING_RESOURCES](
    state: S,
    isLoadingResources: boolean
  ): void;
  [MutationTypes.SET_LOADING_CASE_STUDIES](
    state: S,
    isLoadingCaseStudies: boolean
  ): void;
  [MutationTypes.SET_ERROR_PAPERS](state: S, hasErrorPapers: boolean): void;
  [MutationTypes.SET_ERROR_RESOURCES](
    state: S,
    hasErrorResources: boolean
  ): void;
  [MutationTypes.SET_ERROR_CASE_STUDIES](
    state: S,
    hasErrorCaseStudies: boolean
  ): void;
  [MutationTypes.SET_THEMES_FOR_PAPER](
    state: S,
    payload: {
      themes: Theme[];
      resource: Resource;
    }
  ): void;
  [MutationTypes.SET_THEMES_FOR_RESOURCE](
    state: S,
    payload: {
      themes: Theme[];
      resource: Resource;
    }
  ): void;
  [MutationTypes.SET_THEMES_FOR_CASE_STUDY](
    state: S,
    payload: {
      themes: Theme[];
      caseStudy: CaseStudy;
    }
  ): void;
  [MutationTypes.CLEAR_LEARNING](state: S): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_PAPERS](state, papers) {
    state.papers = papers;
  },
  [MutationTypes.SET_CASE_STUDIES](state, caseStudies) {
    state.caseStudies = caseStudies;
  },
  [MutationTypes.SET_RESOURCES](state, resources) {
    state.resources = resources;
  },
  [MutationTypes.SET_LOADING_PAPERS](state, isLoadingPapers) {
    state.isLoadingPapers = isLoadingPapers;
  },
  [MutationTypes.SET_LOADING_RESOURCES](state, isLoadingResources) {
    state.isLoadingResources = isLoadingResources;
  },
  [MutationTypes.SET_LOADING_CASE_STUDIES](state, isLoadingCaseStudies) {
    state.isLoadingCaseStudies = isLoadingCaseStudies;
  },
  [MutationTypes.SET_ERROR_PAPERS](state, hasErrorPapers) {
    state.hasErrorPapers = hasErrorPapers;
  },
  [MutationTypes.SET_ERROR_RESOURCES](state, hasErrorResources) {
    state.hasErrorResources = hasErrorResources;
  },
  [MutationTypes.SET_ERROR_CASE_STUDIES](state, hasErrorCaseStudies) {
    state.hasErrorCaseStudies = hasErrorCaseStudies;
  },
  [MutationTypes.SET_THEMES_FOR_PAPER](state, { themes, resource }) {
    const index = state.papers.findIndex(
      (_paper) => JSON.stringify(_paper) === JSON.stringify(resource)
    );
    state.papers[index].themes = themes;
    state.papers[index].isLoading = false;
  },
  [MutationTypes.SET_THEMES_FOR_RESOURCE](state, { themes, resource }) {
    const index = state.resources.findIndex(
      (_resource) => JSON.stringify(_resource) === JSON.stringify(resource)
    );
    state.resources[index].themes = themes;
    state.resources[index].isLoading = false;
  },
  [MutationTypes.SET_THEMES_FOR_CASE_STUDY](state, { themes, caseStudy }) {
    const index = state.caseStudies.findIndex(
      (_caseStudy) => JSON.stringify(_caseStudy) === JSON.stringify(caseStudy)
    );
    state.caseStudies[index].themes = themes;
    state.caseStudies[index].isLoading = false;
  },
  [MutationTypes.CLEAR_LEARNING](state) {
    state.papers = [];
    state.resources = [];
    state.caseStudies = [];
  },
};
