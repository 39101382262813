
import { defineComponent, ref, PropType } from "vue";
import { UserData } from "@/store/user/state";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from "vue-router";
import TheHeaderProfileOption from "./TheHeaderProfileOption.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import { useOnClickOutside } from "@/composables/useOnClickOutside";

export default defineComponent({
  components: {
    TheHeaderProfileOption,
    AppButton,
  },
  props: {
    user: {
      type: Object as PropType<UserData | null>,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const auth0 = useAuth0();

    const el = ref<HTMLElement | null>(null);
    const isOpen = ref(false);
    const toggleOpenState = () => (isOpen.value = !isOpen.value);
    const close = () => (isOpen.value = false);

    const toggleSignUp = async () => {
      auth0.loginWithRedirect({
        authorizationParams: { action: "signup" },
        appState: { target: route.fullPath },
      });
    };

    const toggleLogin = async () => {
      auth0.loginWithRedirect({
        appState: { target: route.fullPath },
      });
    };

    const toggleLogout = () => {
      auth0.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    };

    useOnClickOutside(el, () => {
      isOpen.value = false;
    });

    return {
      el,
      isOpen,
      close,
      toggleOpenState,
      toggleSignUp,
      toggleLogin,
      toggleLogout,
      isAuthenticated: auth0.isAuthenticated,
    };
  },
});
