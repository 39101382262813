
import { defineComponent, PropType, ref, watch, onMounted } from "vue";
import { useStore } from "@/store";
import { Resource, CaseStudy } from "@/store/learning/state";
import Guidance from "@/components/content/guidance/Guidance.vue";
import ChallengeLearningListItemText from "./ChallengeLearningListItemText.vue";
import ChallengeLearningListItemVideo from "./ChallengeLearningListItemVideo.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    Guidance,
    ChallengeLearningListItemText,
    ChallengeLearningListItemVideo,
    AppButton,
    LoadingIcon,
  },
  props: {
    learning: {
      type: Object as PropType<Resource[] | CaseStudy[]>,
      required: true,
    },
    hasLearning: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    // learning fake loading state
    // show load more button if not fake loading
    // don't show load more button if currently fake loading
    const fakeLoading = ref(false);

    // limit learning items, showing only a subset initially
    const availableLearning = ref<(Resource | CaseStudy)[]>([]);
    const visibleLearning = ref<(Resource | CaseStudy)[]>([]);
    const selectAvailableLearning = () => {
      const newLearning = [...props.learning];
      visibleLearning.value = [];
      availableLearning.value = newLearning;
    };
    onMounted(selectAvailableLearning);
    watch(() => props.learning, selectAvailableLearning);

    // reset pagination when the available learning changes
    const pageSize = 12;
    const currentPage = ref(1);
    const resetPagination = () => {
      currentPage.value = 1;
    };
    watch(availableLearning, resetPagination);

    // filter to only show enough learning for the current page
    // delay with fake loading state for all updates after initial render
    // reset when the set of available learning changes
    const selectVisibleLearning = () => {
      const updateVisibleLearning = () => {
        visibleLearning.value = availableLearning.value.slice(
          0,
          pageSize * currentPage.value,
        );
      };
      if (currentPage.value === 1) {
        // no delay for the first page
        updateVisibleLearning();
      } else {
        // add a delay for subsequent updtes
        fakeLoading.value = true;
        setTimeout(() => {
          updateVisibleLearning();
          fakeLoading.value = false;
        }, 1000);
      }
    };
    watch(availableLearning, selectVisibleLearning);
    watch(currentPage, selectVisibleLearning);

    // add load more button listener
    const handleLoadMoreLearning = () => {
      currentPage.value = currentPage.value + 1;

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_LEARNING_LOAD_MORE`,
      });
    };

    // load more visibility
    // update when the API state changes
    // don't show load more button if we have displayed all learning
    const loadMoreIsShown = ref(false);
    const checkLoadMoreState = () => {
      loadMoreIsShown.value =
        currentPage.value * pageSize < availableLearning.value.length;
    };
    watch(visibleLearning, checkLoadMoreState);

    return {
      handleLoadMoreLearning,
      fakeLoading,
      visibleLearning,
      loadMoreIsShown,
    };
  },
});
