import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "border-b-[3px] border-b-cebai-grey-lighter py-4 md:py-0" }
const _hoisted_2 = { class: "flex justify-between md:flex-start items-center" }
const _hoisted_3 = { class: "flex items-center md:flex-row-reverse w-1/3 md:w-auto md:order-3 md:ml-auto" }
const _hoisted_4 = { class: "w-1/3 flex justify-center md:pl-0 md:w-auto md:order-1" }
const _hoisted_5 = { class: "hidden md:block md:order-2" }
const _hoisted_6 = { class: "w-1/3 flex justify-end relative md:hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeaderProfile = _resolveComponent("TheHeaderProfile")!
  const _component_TheHeaderNotifications = _resolveComponent("TheHeaderNotifications")!
  const _component_TheHeaderHelpIcon = _resolveComponent("TheHeaderHelpIcon")!
  const _component_TheHeaderLogo = _resolveComponent("TheHeaderLogo")!
  const _component_TheHeaderNav = _resolveComponent("TheHeaderNav")!
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_TheHeaderNavDropdown = _resolveComponent("TheHeaderNavDropdown")!
  const _component_ContentBlock = _resolveComponent("ContentBlock")!
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_ModalContent = _resolveComponent("ModalContent")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_UserSettings = _resolveComponent("UserSettings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_ContentBlock, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_TheHeaderProfile, {
                user: _ctx.userData,
                options: _ctx.dropdownOptions
              }, null, 8, ["user", "options"]),
              _createVNode(_component_TheHeaderNotifications),
              (_ctx.hasTour)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "hidden md:flex ml-3 md:ml-0 md:mr-3",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTakeATour && _ctx.handleTakeATour(...args)))
                  }, [
                    _createVNode(_component_TheHeaderHelpIcon)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_TheHeaderLogo)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_TheHeaderNav, { links: _ctx.navLinks }, null, 8, ["links"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.hasTour)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "ml-3 md:ml-0 md:mr-3",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTakeATour && _ctx.handleTakeATour(...args)))
                  }, [
                    _createVNode(_component_TheHeaderHelpIcon)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "ml-2 text-cebai-primary align-bottom",
                onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleNavDropdownToggle && _ctx.handleNavDropdownToggle(...args)), ["stop"]))
              }, [
                _createVNode(_component_MenuIcon, { class: "fill-current w-10 h-10" })
              ]),
              _createVNode(_component_TheHeaderNavDropdown, {
                links: _ctx.navLinks,
                "is-dropdown-shown": _ctx.isNavDropdownShown
              }, null, 8, ["links", "is-dropdown-shown"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShown.profile,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isShown.profile) = $event)),
      close: () => _ctx.closeModal('profile')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ModalContent, {
          close: () => _ctx.closeModal('profile'),
          title: "Edit profile"
        }, {
          default: _withCtx(() => [
            (_ctx.userData)
              ? (_openBlock(), _createBlock(_component_UserProfile, {
                  key: 0,
                  "close-modal": () => _ctx.closeModal('profile'),
                  user: _ctx.userData
                }, null, 8, ["close-modal", "user"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["close"])
      ]),
      _: 1
    }, 8, ["modelValue", "close"]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShown.settings,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isShown.settings) = $event)),
      close: () => _ctx.closeModal('settings')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ModalContent, {
          close: () => _ctx.closeModal('settings'),
          title: "Settings"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UserSettings, { logout: _ctx.logout }, null, 8, ["logout"])
          ]),
          _: 1
        }, 8, ["close"])
      ]),
      _: 1
    }, 8, ["modelValue", "close"])
  ], 64))
}