import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { Onboarding, Settings, State as LocalState, UserData } from "./state";

interface GetOnboardingStatus {
  (name: string): boolean | undefined;
}

// Getters types
export type Getters = {
  getUserOnboardingByName(state: LocalState): GetOnboardingStatus;
  getUserData(state: LocalState): UserData | null;
  getUserSettings(state: LocalState): Settings;
  getUserOnboarding(state: LocalState): Onboarding | undefined;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getUserOnboardingByName: (state) => (name: string) => {
    return state.user?.onboarding[name];
  },
  getUserData: (state) => {
    // retrieve just the user data we need from the state
    // some data comes from the API, some comes from auth0
    return {
      firstName: state.user?.firstName,
      lastName: state.user?.lastName,
      jobTitle: state.user?.jobTitle,
      email: state.auth0user?.email,

      // may come from API, default to auth0 picture if not set
      picture: state.user?.picture || state.auth0user?.picture,
    };
  },
  getUserSettings: (state) => {
    return state.settings;
  },
  getUserOnboarding: (state) => {
    return state.user?.onboarding;
  },
};
