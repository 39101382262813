import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowUpIcon = _resolveComponent("ArrowUpIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sticky z-50 w-full flex justify-end bottom-0 p-3 transition pointer-events-none", { invisible: !_ctx.isShownScrollToTop }])
  }, [
    _createElementVNode("button", {
      class: "pointer-events-auto rounded-full cursor-pointer transition flex items-center justify-center border-2 p-1 border-cebai-grey-dark hover:border-cebai-base-dark text-cebai-grey-dark hover:text-cebai-base-dark",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleScrollToTop && _ctx.handleScrollToTop(...args)))
    }, [
      _createVNode(_component_ArrowUpIcon, { class: "fill-current" })
    ])
  ], 2))
}