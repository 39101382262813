import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuidancePrompts = _resolveComponent("GuidancePrompts")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_GuidedTour = _resolveComponent("GuidedTour")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_GuidancePrompts, { type: "sparks" })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Tabs, {
      "active-tab": _ctx.activeTab,
      "uses-router": true,
      "has-content": false,
      tabs: _ctx.tabs,
      "tab-type": "underlined"
    }, null, 8, ["active-tab", "tabs"]),
    _createVNode(_component_router_view),
    _createVNode(_component_GuidedTour, { name: "challenge-sparks" })
  ], 64))
}