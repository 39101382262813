
import { defineComponent, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import ChallengeNavigation from "@/components/content/challenge-navigation/ChallengeNavigation.vue";
import ChallengeHeader from "@/components/content/challenge-header/ChallengeHeader.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import ChallengeIdeasList from "@/components/content/challenge-ideas-list/ChallengeIdeasList.vue";
import GuidedTour from "@/components/ui/guided-tour/GuidedTour.vue";

export default defineComponent({
  components: {
    ContentBlock,
    ChallengeNavigation,
    ChallengeHeader,
    ChallengeIdeasList,
    GuidedTour,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const isLoading = computed(() => store.state.challenge.isLoading);
    const ideaCount = computed(() => store.getters["challenge/getIdeaCount"]);
    const hasPlan = computed(() => store.getters["challenge/getHasSolution"]);

    // only allow users to access the ideas view if they have ideas
    watch(
      isLoading,
      () => {
        if (!isLoading.value) {
          if (ideaCount.value < 1 && !hasPlan.value) {
            router.replace({ name: "challenge-saved" });
          }
        }
      },
      { immediate: true },
    );

    return {};
  },
});
