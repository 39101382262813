// Mutations enums
export enum MutationTypes {
  SET_PAPERS = "SET_PAPERS",
  SET_RESOURCES = "SET_RESOURCES",
  SET_CASE_STUDIES = "SET_CASE_STUDIES",
  SET_LOADING_PAPERS = "SET_LOADING_PAPERS",
  SET_LOADING_RESOURCES = "SET_LOADING_RESOURCES",
  SET_LOADING_CASE_STUDIES = "SET_LOADING_CASE_STUDIES",
  SET_ERROR_PAPERS = "SET_ERROR_PAPERS",
  SET_ERROR_RESOURCES = "SET_ERROR_RESOURCES",
  SET_ERROR_CASE_STUDIES = "SET_ERROR_CASE_STUDIES",
  SET_THEMES_FOR_PAPER = "SET_THEMES_FOR_PAPER",
  SET_THEMES_FOR_RESOURCE = "SET_THEMES_FOR_RESOURCE",
  SET_THEMES_FOR_CASE_STUDY = "SET_THEMES_FOR_CASE_STUDY",
  CLEAR_LEARNING = "CLEAR_LEARNING",
}
