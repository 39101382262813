import { ActionContext, ActionTree } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import {
  getUser,
  bootstrapUser,
  deleteUser,
  updateUserPicture,
  updateUserOnboarding,
  updateUserSettings,
  updateUserProfile,
  updateUserFlow,
} from "@/services/user.service";
import { captureException } from "@/utils/errors";

export type UserUpsert = {
  key: string;
  data: {
    value: boolean;
  };
};

export type ProfileForm = {
  onboarding?: boolean;
  data: {
    firstName: string;
    lastName: string;
    jobTitle?: string;
    displayName?: string;
  };
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_USER]({ commit }: AugmentedActionContext): void;
  [ActionTypes.BOOTSTRAP_USER]({ commit }: AugmentedActionContext): void;
  [ActionTypes.DELETE_USER]({
    commit,
    dispatch,
  }: AugmentedActionContext): Promise<boolean | void>;
  [ActionTypes.UPDATE_USER_PICTURE](
    { dispatch }: AugmentedActionContext,
    payload: FormData
  ): void;
  [ActionTypes.UPDATE_USER_ONBOARDING](
    { dispatch }: AugmentedActionContext,
    payload: UserUpsert
  ): void;
  [ActionTypes.UPDATE_USER_SETTINGS](
    { commit, dispatch }: AugmentedActionContext,
    payload: UserUpsert
  ): void;
  [ActionTypes.UPDATE_USER_PROFILE](
    { commit }: AugmentedActionContext,
    payload: ProfileForm
  ): void;
  [ActionTypes.UPDATE_USER_FLOW](
    { commit, dispatch }: AugmentedActionContext,
    payload: UserUpsert
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_USER]({ commit }) {
    commit(MutationTypes.SET_LOADING_USER, true);
    return getUser()
      .then((response) => {
        const user = response.data.user;
        const auth0 = response.data._auth0;

        // capture just the data we need from Auth0 rather than the whole object
        const auth0user = {
          email: auth0?.email,
          picture: auth0?.picture,
        };

        commit(MutationTypes.SET_USER, user);
        commit(MutationTypes.SET_USER_SETTINGS, user?.settings);
        commit(MutationTypes.SET_AUTH0_USER, auth0user);
      })
      .catch((error) => {
        captureException(error);
        commit(MutationTypes.SET_USER, null);
        commit(MutationTypes.SET_AUTH0_USER, null);
      })
      .finally(() => {
        commit(MutationTypes.SET_LOADING_USER, false);
      });
  },
  [ActionTypes.BOOTSTRAP_USER]({ dispatch }) {
    return bootstrapUser()
      .then(async (response) => {
        // get newly created user following bootstrap
        dispatch(ActionTypes.GET_USER);

        // if we have a new user set the settings defaults
        if (response.data.updated === 1) {
          await updateUserSettings({
            key: "notifications_app",
            data: { value: true },
          });
          await updateUserSettings({
            key: "notifications_email",
            data: { value: true },
          });
          await updateUserSettings({
            key: "notifications_marketing",
            data: { value: true },
          });
        }
      })
      .catch((error) => {
        captureException(error);
      });
  },
  [ActionTypes.DELETE_USER]({ commit, dispatch }) {
    commit(MutationTypes.SET_DELETING_USER, true);
    return deleteUser()
      .then(() => {
        return new Promise<boolean>((resolve) => {
          resolve(true);
        });
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR_DELETE_USER", {
          root: true,
        });
      })
      .finally(() => {
        commit(MutationTypes.SET_DELETING_USER, false);
      });
  },
  [ActionTypes.UPDATE_USER_PICTURE]({ commit }, payload) {
    commit(MutationTypes.SET_UPDATING_USER_PICTURE, true);
    return updateUserPicture(payload)
      .then((response) => {
        // prefetch image before setting its value in the state so we don't
        // have a flash of the old image prior to the new one appearing
        const { url } = response.data;
        const img = new Image();
        img.src = url;
        img.addEventListener("load", () => {
          commit(MutationTypes.SET_USER_PICTURE, url);
          commit(MutationTypes.SET_UPDATING_USER_PICTURE, false);
        });
      })
      .catch((error) => {
        commit(MutationTypes.SET_UPDATING_USER_PICTURE, false);
        captureException(error);
      });
  },
  [ActionTypes.UPDATE_USER_ONBOARDING]({ dispatch }, payload) {
    return updateUserOnboarding(payload)
      .then(() => {
        // get user following onboarding update
        dispatch(ActionTypes.GET_USER);
      })
      .catch((error) => {
        captureException(error);
      });
  },
  [ActionTypes.UPDATE_USER_SETTINGS]({ commit, dispatch }, payload) {
    // update UI after slight delay
    setTimeout(() => {
      commit(MutationTypes.UPDATE_USER_SETTINGS, {
        key: payload.key,
        value: payload.data.value,
      });
    }, 200);

    return updateUserSettings(payload)
      .then((response) => {
        // update settings with response data
        commit(MutationTypes.SET_USER_SETTINGS, response.data);

        if (payload.key === "display_dark_mode") {
          dispatch("preferences/UPDATE_DARK_MODE", payload.data.value, {
            root: true,
          });
        }
      })
      .catch((error) => {
        commit(MutationTypes.UPDATE_USER_SETTINGS, {
          key: payload.key,
          value: !payload.data.value,
        });
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR_SETTINGS", {
          root: true,
        });
      });
  },
  [ActionTypes.UPDATE_USER_PROFILE]({ commit, dispatch }, payload) {
    commit(MutationTypes.SET_SAVING_PROFILE, true);
    return updateUserProfile(payload)
      .then((response) => {
        const user = response.data;
        commit(MutationTypes.SET_USER, user);

        // don't send a toast when onboarding
        if (!payload.onboarding) {
          dispatch("messages/ADD_INFO_MESSAGE", "PROFILE_UPDATED", {
            root: true,
          });
        }
      })
      .catch((error) => {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
        commit(MutationTypes.SET_USER, null);
      })
      .finally(() => {
        commit(MutationTypes.SET_SAVING_PROFILE, false);
      });
  },
  [ActionTypes.UPDATE_USER_FLOW]({ dispatch }, payload) {
    return updateUserFlow(payload)
      .then(() => {
        // get user following flow update
        dispatch(ActionTypes.GET_USER);
      })
      .catch((error) => {
        captureException(error);
      });
  },
};
