import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["button group", {
      [`button--type-${_ctx.type}`]: _ctx.type,
      [`button--disabled`]: _ctx.disabled,
    }]),
    href: _ctx.href
  }, [
    _renderSlot(_ctx.$slots, "iconBefore"),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _renderSlot(_ctx.$slots, "iconAfter")
  ], 10, _hoisted_1))
}