export type PossibleIdea = {
  id: number;
  content: string;
};

// Declare state
export type State = {
  ideas: PossibleIdea[];
  isLoadingPossibleIdeas: boolean;
};

// Create initial state
export const state = {
  ideas: [],
  isLoadingPossibleIdeas: false,
} as State;
