import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState, Challenge } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import {
  getChallenges,
  getAllChallengeTypes,
  getAllSubjectTypes,
  getUserChallengeTypes,
  getUserSubjectTypes,
  getUserChallengeTags,
  getOutcomeTags,
  restoreChallenge,
  addChallenge,
} from "@/services/challenges.service";
import { captureException } from "@/utils/errors";

export type ChallengeForm = {
  title: string;
  subject: string;
  description: string;
  additional: string;
  types: string[];
  tags: string[];
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_ACTIVE_CHALLENGES]({
    commit,
    dispatch,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_RESOLVED_CHALLENGES]({
    commit,
    dispatch,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_ALL_CHALLENGE_TYPES]({
    commit,
    dispatch,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_ALL_SUBJECT_TYPES]({
    commit,
    dispatch,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_USER_CHALLENGE_TYPES]({
    commit,
    dispatch,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_USER_SUBJECT_TYPES]({
    commit,
    dispatch,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_USER_CHALLENGE_TAGS]({
    commit,
    dispatch,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_OUTCOME_TAGS](
    { commit, dispatch }: AugmentedActionContext,
    payload: string,
  ): void;
  [ActionTypes.RESTORE_CHALLENGE](
    { commit, dispatch }: AugmentedActionContext,
    payload: string,
  ): void;
  [ActionTypes.ADD_CHALLENGE](
    { commit, dispatch }: AugmentedActionContext,
    payload: ChallengeForm,
  ): Promise<string | void>;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_ACTIVE_CHALLENGES]({ dispatch, commit }) {
    commit(MutationTypes.SET_ACTIVE_CHALLENGES, []);
    commit(MutationTypes.SET_LOADING_ACTIVE_CHALLENGES, true);

    return getChallenges("active")
      .then((response) => {
        commit(MutationTypes.SET_LOADING_ACTIVE_CHALLENGES, false);
        commit(MutationTypes.SET_ACTIVE_CHALLENGES, response.data);
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
        commit(MutationTypes.SET_LOADING_ACTIVE_CHALLENGES, false);
        commit(MutationTypes.SET_ACTIVE_CHALLENGES, []);
      });
  },
  [ActionTypes.GET_RESOLVED_CHALLENGES]({ commit, dispatch }) {
    commit(MutationTypes.SET_RESOLVED_CHALLENGES, []);
    commit(MutationTypes.SET_LOADING_RESOLVED_CHALLENGES, true);

    return getChallenges("resolved")
      .then((response) => {
        commit(MutationTypes.SET_LOADING_RESOLVED_CHALLENGES, false);
        commit(MutationTypes.SET_RESOLVED_CHALLENGES, response.data);
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
        commit(MutationTypes.SET_LOADING_RESOLVED_CHALLENGES, false);
        commit(MutationTypes.SET_RESOLVED_CHALLENGES, []);
      });
  },
  [ActionTypes.GET_ALL_CHALLENGE_TYPES]({ commit, dispatch }) {
    return getAllChallengeTypes()
      .then((response) => {
        commit(MutationTypes.SET_CHALLENGE_TYPES, response.data);
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      });
  },
  [ActionTypes.GET_ALL_SUBJECT_TYPES]({ commit, dispatch }) {
    return getAllSubjectTypes()
      .then((response) => {
        commit(MutationTypes.SET_SUBJECT_TYPES, response.data);
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      });
  },
  [ActionTypes.GET_USER_CHALLENGE_TYPES]({ commit, dispatch }) {
    return getUserChallengeTypes()
      .then((response) => {
        commit(MutationTypes.SET_CHALLENGE_TYPES, response.data);
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      });
  },
  [ActionTypes.GET_USER_SUBJECT_TYPES]({ commit, dispatch }) {
    return getUserSubjectTypes()
      .then((response) => {
        commit(MutationTypes.SET_SUBJECT_TYPES, response.data);
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      });
  },
  [ActionTypes.GET_USER_CHALLENGE_TAGS]({ commit, dispatch }) {
    return getUserChallengeTags()
      .then((response) => {
        commit(MutationTypes.SET_CHALLENGE_TAGS, response.data);
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      });
  },
  [ActionTypes.GET_OUTCOME_TAGS]({ commit, dispatch }, payload) {
    const outcomeTagType = payload;

    return getOutcomeTags(payload)
      .then((response) => {
        if (outcomeTagType === "helps") {
          commit(MutationTypes.SET_OUTCOME_TAGS_HELPS, response.data);
        } else if (outcomeTagType === "prevents") {
          commit(MutationTypes.SET_OUTCOME_TAGS_PREVENTS, response.data);
        }
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      });
  },
  [ActionTypes.RESTORE_CHALLENGE]({ dispatch }, payload) {
    const challengeId = payload;
    return restoreChallenge(challengeId)
      .then(() => {
        dispatch(ActionTypes.GET_ACTIVE_CHALLENGES);
        dispatch(ActionTypes.GET_RESOLVED_CHALLENGES);
        dispatch("challenge/GET_CHALLENGE", challengeId, { root: true });
        dispatch("messages/ADD_INFO_MESSAGE", "CHALLENGE_REACTIVATED", {
          root: true,
        });
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      });
  },
  [ActionTypes.ADD_CHALLENGE]({ dispatch }, payload) {
    return addChallenge(payload)
      .then((data) => {
        const challenge: Challenge = data.data;
        dispatch("messages/ADD_INFO_MESSAGE", "CHALLENGE_CREATED", {
          root: true,
        });
        return new Promise<string>((resolve) => {
          resolve(challenge._id);
        });
      })
      .catch(function (error) {
        captureException(error);
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      });
  },
};
