
import { defineComponent, PropType, computed, ref } from "vue";
import { useStore } from "@/store";
import { Spark } from "@/store/sparks/state";
import PossibleIdeas from "./PossibleIdeas.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";

export default defineComponent({
  components: {
    AppButton,
    PossibleIdeas,
  },
  props: {
    closeModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
    spark: {
      type: Object as PropType<Spark>,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    notes: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    // values may be passed through to populate the form -
    // convert them to references to track their values
    const inputDescription = ref(props.spark?.idea || props.description || "");

    const inputNotes = ref(props.notes || "");

    const origin = props.spark?.origin ? props.spark?.origin : "own";
    const sparkText = props.spark?.sparkText ? props.spark?.sparkText : "";
    const stem = props.spark?.stem ? props.spark?.stem : {};

    // provide possible ideas for all sparks types except learning sparks (these
    // feature in the learning tab not the sparks tab)
    const hasPossibleIdeas = props.spark && props.spark?.origin !== "learning";

    // check if the original description (idea stem) has changed
    const hasInitialDescription = computed(
      () => inputDescription.value === props.spark?.idea,
    );

    const submitIsDisabled = computed(() => {
      return !inputDescription.value;
    });

    const isLoading = computed(() => store.state.challenge.isLoading);

    // functions for possible ideas
    const adoptPossibleIdea = (possibleIdea: string) => {
      inputDescription.value = possibleIdea;
    };
    const handleResetDescription = () => {
      inputDescription.value = props.spark?.idea;
    };

    const handleFormSubmit = async () => {
      // if we are adding, we won't have an existing idea
      await store.dispatch("challenge/ADD_IDEA", {
        description: inputDescription.value,
        notes: inputNotes.value,
        origin,
        sparkText,
        stem,
      });
      props.closeModal();

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_IDEA_CREATE`,
      });
    };

    return {
      inputDescription,
      inputNotes,
      hasInitialDescription,
      submitIsDisabled,
      isLoading,
      handleFormSubmit,
      hasPossibleIdeas,
      adoptPossibleIdea,
      handleResetDescription,
    };
  },
});
