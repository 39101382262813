import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { Challenge, SubjectType } from "../challenges/state";
import { State as LocalState, Solution, Idea } from "./state";

type GetIdeas = {
  (showFavouritesOnly: boolean): Idea[] | null;
};

type GetIdea = {
  (id: string): Idea | null | undefined;
};

type SubjectOption = {
  label: string;
  options: SubjectType[];
};

type getOtherIdeasRandom = {
  (description: Idea["description"]): Idea[] | undefined;
};

// Getters types
export type Getters = {
  getChallenge(state: LocalState): Challenge | null;
  getIdeas(state: LocalState): GetIdeas;
  getIdeaById(state: LocalState): GetIdea;
  getOtherIdeasRandom(state: LocalState): getOtherIdeasRandom;
  getIdeaCount(state: LocalState): number;
  getHasSolution(state: LocalState): boolean;
  getSolution(state: LocalState): Solution | null;
  getSolutionText(state: LocalState): string | null;
  getSolutionIdeas(state: LocalState): Idea[];
  getSolutionConfidence(state: LocalState): number;
  getHasResolution(state: LocalState): boolean;
  getIsActive(state: LocalState): boolean;
  getIsResolved(state: LocalState): boolean;
  getIdeaLabels(state: LocalState): SubjectOption[];
  getDescriptionWithTypes(state: LocalState): string;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getChallenge: (state) => {
    return state.challenge;
  },
  getIdeas: (state) => (showFavouritesOnly) => {
    if (!state.challenge || !state.challenge.ideas) {
      return null;
    }

    if (showFavouritesOnly) {
      return state.challenge.ideas.filter((idea) => idea.isFavorite);
    }

    return state.challenge.ideas;
  },
  getIdeaById: (state) => (id) => {
    if (!state.challenge || !state.challenge.ideas) {
      return null;
    }
    return state.challenge.ideas.find((idea) => idea._id === id);
  },
  getOtherIdeasRandom: (state) => (description) => {
    if (!state.challenge || !state.challenge.ideas) {
      return undefined;
    }

    // filter out selected idea
    const nonSelectedIdeas = state.challenge?.ideas?.filter(
      (idea) => idea.description !== description,
    );

    // return randomised
    return [...nonSelectedIdeas].sort(() => 0.5 - Math.random());
  },
  getIdeaLabels: (state) => {
    if (!state.challenge || !state.challenge.ideas) {
      return [];
    }

    // split ideas by favourited state
    const starredIdeas = state.challenge?.ideas?.filter(
      (idea) => idea.isFavorite,
    );

    const unstarredIdeas = state.challenge?.ideas?.filter(
      (idea) => !idea.isFavorite,
    );

    const starredLabel = starredIdeas.length
      ? "Favourites"
      : "Favourites - None";
    const unstarredLabel = unstarredIdeas.length
      ? "Non-Favourites"
      : "Non-Favourites - None";

    return [
      {
        label: starredLabel,
        options: starredIdeas.map((idea) => ({
          value: idea._id,
          label: idea.description,
        })),
      },
      {
        label: unstarredLabel,
        options: unstarredIdeas.map((idea) => ({
          value: idea._id,
          label: idea.description,
        })),
      },
    ];
  },
  getSolutionIdeas: (state) => {
    if (
      state.challenge?.ideas &&
      state.challenge?.solutions &&
      state.challenge?.solutions.length
    ) {
      const solutionIdeasIds = state.challenge?.solutions[0].ideas;
      const ideasList = state.challenge?.ideas;
      const solutionIdeasList = ideasList.filter((idea) => {
        return solutionIdeasIds.includes(idea._id);
      });
      return solutionIdeasList;
    }
    return [];
  },
  getIdeaCount: (state) => {
    return state.challenge?.ideas?.length || 0;
  },
  getHasSolution: (state) => {
    if (state.challenge?.solutions && state.challenge?.solutions.length) {
      return true;
    }
    return false;
  },
  getSolution: (state) => {
    if (state.challenge?.solutions && state.challenge?.solutions.length) {
      return state.challenge?.solutions[0];
    }
    return null;
  },
  getSolutionText: (state) => {
    if (state.challenge?.solutions && state.challenge?.solutions.length) {
      return state.challenge?.solutions[0].description;
    }
    return null;
  },
  getSolutionConfidence: (state) => {
    if (state.challenge?.solutions && state.challenge?.solutions.length) {
      // old challenges won't have confidenceLevel - allow for undefined.
      return state.challenge?.solutions[0].confidenceLevel === undefined
        ? -1
        : state.challenge?.solutions[0].confidenceLevel;
    }
    return -1;
  },
  getHasResolution: (state) => {
    // challenges without a resolution do not include the resolution property.
    // old challenges from previous versions can have resolution as a string
    // which we need to ignore
    if (
      state.challenge?.resolution !== undefined &&
      typeof state.challenge?.resolution !== "string"
    ) {
      return true;
    }
    return false;
  },
  getIsActive: (state) => {
    return state.challenge?.status === 1;
  },
  getIsResolved: (state) => {
    return state.challenge?.status === 0;
  },
  getDescriptionWithTypes: (state) => {
    // including the challenge type(s) improves the learning results
    const description = state.challenge?.description || "";
    const types = state.challenge?.types.join(" ") || "";

    return description + " " + types;
  },
};
