
import { defineComponent, PropType, computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { CaseStudy } from "@/store/learning/state";
import { useModal } from "@/composables/useModal";
import AppContent from "@/components/layout/app-content/AppContent.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import CreateIdea from "@/components/content/create-idea/CreateIdea.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import Markdown from "@/components/ui/markdown/Markdown.vue";
import ContentBlockDivider from "@/components/ui/content-block/ContentBlockDivider.vue";
import AddIcon from "@/assets/images/icon-add.svg";
import ArrowRightIcon from "@/assets/images/icon-arrow-right.svg";

export default defineComponent({
  components: {
    AppContent,
    ContentBlock,
    ModalContent,
    Editorial,
    CreateIdea,
    AppButton,
    AppButtonRouterLink,
    Markdown,
    ContentBlockDivider,
    AddIcon,
    ArrowRightIcon,
  },
  props: {
    caseStudy: {
      type: Object as PropType<CaseStudy>,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const backLink = computed(
      () => router.options.history.state.back as string,
    );

    // we only show the "Add idea" button if a challenge exists in the store
    const challenge = computed(() => store.getters["challenge/getChallenge"]);

    // modals
    const modalState = reactive({
      addIdea: false,
    });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const showAddIdeaModal = () => {
      showModal("addIdea");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CASE_STUDY_IDEA_ADD_MODAL_SHOW`,
      });
    };

    return {
      backLink,
      challenge,
      isShown,
      showModal,
      closeModal,
      showAddIdeaModal,
    };
  },
});
