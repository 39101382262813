
import { defineComponent, computed, PropType } from "vue";
import { useStore } from "@/store";
import { Idea } from "@/store/challenge/state";
import { generatePdf, OutputType } from "@/services/pdf.service";
import { downloadPdfFile, copyToClipboard } from "@/services/download.service";
import { captureException } from "@/utils/errors";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import DownloadIcon from "@/assets/images/icon-download.svg";

export default defineComponent({
  components: {
    AppButton,
    DownloadIcon,
  },
  props: {
    ideas: {
      type: Object as PropType<Idea[]>,
      required: true,
    },
    outputType: {
      type: String as PropType<OutputType>,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();

    const challenge = computed(() => store.state.challenge.challenge);

    const handleDownloadPdf = () => {
      generatePdf(challenge.value, props.ideas, props.outputType)
        .then((response) => {
          // create Blob and send to utils fn
          const file = new Blob([response.data], { type: "application/pdf" });
          downloadPdfFile(file, "Sport_Sparks_Export.pdf");

          store.dispatch("tracking/TRACK_EVENT", {
            action: `DOWNLOAD_PDF`,
          });

          props.closeModal();
        })
        .catch((error) => {
          captureException(error);
        });
    };

    const handleCopyToClipboard = async () => {
      if (!challenge.value) return;
      await copyToClipboard(challenge.value, props.ideas, props.outputType);

      store.dispatch("tracking/TRACK_EVENT", {
        action: `DOWNLOAD_COPY_TO_CLIPBOARD`,
      });

      props.closeModal();
    };

    return {
      handleCopyToClipboard,
      handleDownloadPdf,
    };
  },
});
