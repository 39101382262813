import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import {
  UpdateableChallenge,
  IdeaForm,
  UpdateableIdea,
  FavoriteIdea,
  SolutionForm,
  UpdateableSolution,
  ResolutionForm,
  GeneratePlan,
} from "@/store/challenge/actions";
import { ChallengeForm } from "@/store/challenges/actions";

/* api calls */

export const getChallenge = (id: string): AxiosPromise => {
  return apiClient.get(`/challenges/${id}`);
};

export const updateChallenge = (payload: UpdateableChallenge): AxiosPromise => {
  return apiClient.put(`/challenges/${payload._id}`, payload.data);
};

export const getAIChallengeTitle = (challengeText: string): AxiosPromise => {
  return apiClient.post(`/challenges/title`, { challenge_text: challengeText });
};

export const addIdea = (
  payload: IdeaForm,
  challengeId: string,
): AxiosPromise => {
  return apiClient.post(`/challenges/${challengeId}/ideas`, payload);
};

export const deleteIdea = (id: string, challengeId: string): AxiosPromise => {
  return apiClient.delete(`/challenges/${challengeId}/ideas/${id}`);
};

export const updateIdea = (
  payload: UpdateableIdea,
  challengeId: string,
): AxiosPromise => {
  return apiClient.put(
    `/challenges/${challengeId}/ideas/${payload._id}`,
    payload.data,
  );
};

export const addSolution = (
  payload: SolutionForm,
  challengeId: string,
): AxiosPromise => {
  return apiClient.post(`/challenges/${challengeId}/solutions`, payload);
};

export const deleteSolution = (
  id: string,
  challengeId: string,
): AxiosPromise => {
  return apiClient.delete(`/challenges/${challengeId}/solutions/${id}`);
};

export const updateSolution = (
  payload: UpdateableSolution,
  challengeId: string,
): AxiosPromise => {
  return apiClient.put(
    `/challenges/${challengeId}/solutions/${payload._id}`,
    payload.data,
  );
};

export const setIdeaFavorite = (
  payload: FavoriteIdea,
  challengeId: string,
): AxiosPromise => {
  return apiClient.patch(
    `/challenges/${challengeId}/ideas/${payload._id}`,
    payload.data,
  );
};

export const getChallenges = (status: string): AxiosPromise => {
  return apiClient.get(`/challenges/${status}`);
};

export const getAllChallengeTypes = (): AxiosPromise => {
  return apiClient.get(`/challenges/types`);
};

export const getAllSubjectTypes = (): AxiosPromise => {
  return apiClient.get(`/challenges/subjects`);
};

export const getUserChallengeTypes = (): AxiosPromise => {
  return apiClient.get(`/challenges/user-types`);
};

export const getUserSubjectTypes = (): AxiosPromise => {
  return apiClient.get(`/challenges/user-subjects`);
};

export const getUserChallengeTags = (): AxiosPromise => {
  return apiClient.get(`/challenges/user-tags`);
};

export const getOutcomeTags = (type: string): AxiosPromise => {
  return apiClient.get(`/challenges/outcome-tags/${type}`);
};

export const resolveChallenge = (payload: ResolutionForm): AxiosPromise => {
  return apiClient.patch(
    `/challenges/${payload._id}/resolve`,
    payload.resolution,
  );
};

export const restoreChallenge = (challengeId: string): AxiosPromise => {
  return apiClient.patch(`/challenges/${challengeId}/activate`);
};

export const addChallenge = (payload: ChallengeForm): AxiosPromise => {
  return apiClient.post(`/challenges`, payload);
};

export const deleteChallenge = (id: string): AxiosPromise => {
  return apiClient.delete(`/challenges/${id}`);
};

export const generatePlan = (payload: GeneratePlan): AxiosPromise => {
  return apiClient.post(`/nlp/plan`, payload);
};
