
import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "@/store";
import Guidance from "@/components/content/guidance/Guidance.vue";
import ChallengeSparksList from "@/components/content/challenge-sparks-list/ChallengeSparksList.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    Guidance,
    ChallengeSparksList,
    LoadingIcon,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const challenge = computed(() => store.getters["challenge/getChallenge"]);
    const entities = computed(() => store.state.challenge.entities);

    // convert (external) spark URL slug to (internal) spark type
    const sparkType = computed(() =>
      store.getters["sparks/getSparkTypeFromSlug"](props.slug),
    );

    const isLoading = computed(() =>
      store.getters["sparks/getLoadingSparksByType"](sparkType.value),
    );

    const sparks = computed(() =>
      store.getters["sparks/getSparksByType"](sparkType.value),
    );

    const getSparks = async () => {
      if (!challenge.value) return;

      store.commit("sparks/SET_LOADING", {
        sparkType: sparkType.value,
        loading: true,
      });

      // first, extract entities from the challenge text
      // if no entities are returned, we can't get sparks
      const hasEntities = await store.dispatch("challenge/GET_ENTITIES");
      if (hasEntities) {
        store.dispatch("sparks/GET_SPARKS", sparkType.value);
      } else {
        store.commit("sparks/SET_LOADING", {
          sparkType: sparkType.value,
          loading: false,
        });
      }
    };

    const refreshSparks = async () => {
      // refreshing sparks doesn't need the entities to be generated again
      store.dispatch("sparks/GET_SPARKS", sparkType.value);
    };

    const hasSparks = computed(
      () => sparks.value.length > 0 && !isLoading.value,
    );

    const hasError = computed(() =>
      store.getters["sparks/getSparksErrorByType"](sparkType.value),
    );

    // Make an API request when tab is initially rendered, and if the
    // challenge is updated once the component has rendered, as the initial
    // challenge API request may not have returned when this component is
    // first rendered
    onMounted(() => {
      if (sparks.value.length === 0 && !isLoading.value) {
        getSparks();
      }
    });
    watch(challenge, getSparks);

    // as we are using tabs via the router, we also need to watch the (reactive)
    // prop in order to identify the different tabs
    watch(
      () => props.slug,
      () => {
        if (sparks.value.length === 0 && !isLoading.value) {
          // in case no entities were extracted previously
          if (entities.value.length === 0) {
            getSparks();
          } else {
            refreshSparks();
          }
        }
      },
    );

    return {
      isLoading,
      sparks,
      getSparks,
      refreshSparks,
      hasSparks,
      hasError,
    };
  },
});
