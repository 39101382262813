import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";

/* api calls */

export const getNotifications = (): AxiosPromise => {
  return apiClient.get(`/user/notifications/all`);
};

export const setNotificationAsRead = (id: string): AxiosPromise => {
  return apiClient.patch(`/user/notifications/${id}`);
};

export const setAllNotificationsAsRead = (): AxiosPromise => {
  return apiClient.patch(`/user/notifications/all`);
};
