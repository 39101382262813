
import { defineComponent, computed, ref, reactive } from "vue";
import { useStore } from "@/store";
import { useModal } from "@/composables/useModal";
import AutoResizeTextarea from "@/components/ui/auto-resize-textarea/AutoResizeTextarea.vue";
import Multiselect from "@/components/ui/multiselect/Multiselect.vue";
import AppContent from "@/components/layout/app-content/AppContent.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Markdown from "@/components/ui/markdown/Markdown.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonLink from "@/components/ui/app-button/AppButtonLink.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import Tab from "@/components/ui/tabs/Tab.vue";
import TabContent from "@/components/ui/tabs/TabContent.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import Toggle from "@/components/ui/toggle/Toggle.vue";
import PlaceholderSvgImage from "@/assets/images/_placeholder.svg";

export default defineComponent({
  components: {
    AppContent,
    ContentBlock,
    Editorial,
    AutoResizeTextarea,
    AppButton,
    AppButtonLink,
    AppButtonRouterLink,
    Toggle,
    ModalContent,
    Markdown,
    Tabs,
    Tab,
    TabContent,
    Multiselect,
    LoadingIcon,
    PlaceholderSvgImage,
  },
  setup() {
    const store = useStore();

    // alerts
    const addInfo = () =>
      store.dispatch("messages/ADD_INFO_MESSAGE", "CHALLENGE_CREATED", {
        root: true,
      });
    const addSuccess = () =>
      store.dispatch("messages/ADD_SUCCESS_MESSAGE", "CHALLENGE_RESOLVED", {
        root: true,
      });
    const addError = () =>
      store.dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", {
        root: true,
      });

    // buttons
    const isLoadingButton = ref(false);
    const handleLoadingButton = () => {
      isLoadingButton.value = true;
      setTimeout(() => {
        isLoadingButton.value = false;
      }, 1000);
    };

    // toggle
    const toggleOne = ref(false);
    const toggleTwo = ref(true);
    const toggleThree = ref(false);
    const toggleFour = ref(false);

    // modal
    const modalState = reactive({ exampleModal: false });
    const { isShown, showModal, closeModal } = useModal(modalState);

    // markdown
    const markdownContent = `
## h2 title

- unordered
- list

a paragraph of text

### h3 title

some text with __bold__ and *italic*`;

    // svgs
    const svgPath = "_placeholder.svg";

    // tabs
    const tabs = [
      {
        slug: "tab-one",
        label: "Tab one content",
      },
      {
        slug: "tab-two",
        label: "Tab two content",
      },
      {
        slug: "tab-three",
        label: "Tab three content",
      },
      {
        slug: "tab-four",
        label: "Tab four content",
      },
    ];

    const activeTab = computed(() => tabs[0].slug);

    // forms
    const inputTextValue = ref("");
    const textareaFixedValue = ref("");
    const textareaResizeableValue = ref("");
    const checkboxValue = ref(false);
    const searchTextValue = ref("");
    const selectOptions = computed(() => [
      "lorem",
      "ipsum",
      "dolor sit",
      "amet",
    ]);
    const selectValue = ref(selectOptions.value[0]);
    const multiselectOptions = computed(() => [
      "lorem",
      "ipsum",
      "dolor sit",
      "amet",
    ]);
    const multiselectValue = ref([]);
    const multiselectTagOptions = computed(() => [
      "lorem",
      "ipsum",
      "dolor sit",
      "amet",
    ]);
    const multiselectTagValues = ref([]);

    const resizeableTextarea = ref<typeof AutoResizeTextarea | null>(null);

    // loading
    const isLoading = ref(true);
    const toggleLoadingState = () => (isLoading.value = !isLoading.value);

    const throwSentryError = () => {
      // eslint-disable-next-line no-console
      console.error("Error thrown");

      throw new Error("Test Sentry error from the component library");
    };

    return {
      // alerts
      addInfo,
      addSuccess,
      addError,

      // buttons
      isLoadingButton,
      handleLoadingButton,

      // toggle
      toggleOne,
      toggleTwo,
      toggleThree,
      toggleFour,

      // modal
      isShown,
      showModal,
      closeModal,

      // markdown
      markdownContent,

      // svgs
      svgPath,

      // tabs
      tabs,
      activeTab,

      // forms
      inputTextValue,
      textareaFixedValue,
      textareaResizeableValue,
      checkboxValue,
      searchTextValue,
      selectOptions,
      selectValue,
      multiselectOptions,
      multiselectValue,
      multiselectTagOptions,
      multiselectTagValues,

      // resizeable text area
      resizeableTextarea,

      // loading
      isLoading,
      toggleLoadingState,

      // sentry error testing
      throwSentryError,
    };
  },
});
