import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownArrowIcon = _resolveComponent("DropdownArrowIcon")!
  const _component_Multiselect = _resolveComponent("Multiselect", true)!

  return (_openBlock(), _createBlock(_component_Multiselect, { classes: _ctx.inputClasses }, {
    caret: _withCtx(({ handleCaretClick, isOpen }) => [
      _createElementVNode("div", {
        "aria-hidden": "true",
        class: _normalizeClass(["mr-1.5 sm:mr-3 transition-all duration-300", {
          'rotate-180': isOpen,
          'rotate-0': !isOpen,
        }]),
        style: _normalizeStyle({
          pointerEvents: isOpen ? 'auto' : 'none',
        }),
        onClick: handleCaretClick
      }, [
        _createVNode(_component_DropdownArrowIcon, { class: "text-cebai-primary fill-current" })
      ], 14, _hoisted_1)
    ]),
    _: 1
  }, 8, ["classes"]))
}