import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";
import { Theme, Entities } from "@/store/learning/state";

/* helpers */

export const buildThemeSet = (entities: Entities): Theme[] => {
  const themeList: Theme[] = [];
  Object.keys(entities).forEach((key) => {
    for (const [i, value] of entities[key].entries()) {
      themeList.push({
        id: key + "-" + i,
        label: value,
        type: key,
      });
    }
  });
  return [...themeList].sort(() => 0.5 - Math.random()).splice(0, 15);
};

/* api calls */

export const getLearning = (
  learningType: string,
  query: string
): AxiosPromise => {
  return apiClient.post(`/search/${learningType}`, {
    query,
    lang: "en",
  });
};

export const getEntitiesNlp = (text: string): AxiosPromise => {
  return apiClient.post(`/nlp/keywords`, { text });
};

export const getCaseStudy = (id: string): AxiosPromise => {
  return apiClient.get(`/search/case-studies/${id}`);
};
