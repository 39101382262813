import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState, Resource, CaseStudy } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { kebabize } from "@/utils/text";
import { buildThemeSet, getLearning } from "@/services/learning.service";
import { getEntitiesNlp } from "@/services/entities.service";
import { captureException } from "@/utils/errors";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_LEARNING](
    { commit, dispatch }: AugmentedActionContext,
    payload: string,
  ): void;
  [ActionTypes.GET_LEARNING_ENTITIES](
    { commit, dispatch }: AugmentedActionContext,
    payload: Resource,
  ): void;
  [ActionTypes.CLEAR_LEARNING]({ commit }: AugmentedActionContext): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_LEARNING]({ commit, dispatch, rootGetters }, payload) {
    const learningType = payload;
    switch (learningType) {
      case "papers":
        commit(MutationTypes.SET_LOADING_PAPERS, true);
        commit(MutationTypes.SET_PAPERS, []);
        commit(MutationTypes.SET_ERROR_PAPERS, false);
        break;
      case "resources":
        commit(MutationTypes.SET_LOADING_RESOURCES, true);
        commit(MutationTypes.SET_RESOURCES, []);
        commit(MutationTypes.SET_ERROR_RESOURCES, false);
        break;
      case "caseStudies":
        commit(MutationTypes.SET_LOADING_CASE_STUDIES, true);
        commit(MutationTypes.SET_CASE_STUDIES, []);
        commit(MutationTypes.SET_ERROR_CASE_STUDIES, false);
        break;
    }

    const query = rootGetters["challenge/getDescriptionWithTypes"];

    // convert camelCase learningType value used in the app to a kebab-case
    // version which is used in the api
    // (e.g. convert 'caseStudies' to 'case-studies')
    const apiLearningType = kebabize(learningType);

    return getLearning(apiLearningType, query)
      .then((response) => {
        const learning = response.data;
        const learningList: Resource[] = [];
        const caseStudiesList: CaseStudy[] = [];

        switch (learningType) {
          case "papers":
          case "resources":
            learning.forEach((item: Resource) => {
              const resource = {
                description: `One or more ideas based on the article '${item.title}'`,
                title: item.title,
                uri: item.uri,
                snippet: item.snippet || "",
                context: item.context || "",
                thumbnail: item.thumbnail || undefined,
                duration: item.duration || undefined,
                public_metrics: item.public_metrics || undefined,
                type: item.type || "text",
                origin: item.origin,
                source: item.source || "",
                entities: item.entities || undefined,
                themes: item.entities ? buildThemeSet(item.entities) : [],
                isLoading: !item.entities,
                learningType,
              };
              learningList.push(resource);

              // track the retrieval of any UK Coaching resource
              if (
                resource.source === "uk_coaching" ||
                resource.uri?.includes("ukcoaching.org")
              ) {
                dispatch(
                  "tracking/TRACK_EVENT",
                  {
                    action: `RESOURCE_RETRIEVED_UK_COACHING`,
                    eventParams: { url: resource.uri },
                  },
                  {
                    root: true,
                  },
                );
              }
            });
            switch (learningType) {
              case "papers":
                commit(MutationTypes.SET_PAPERS, learningList);
                break;
              case "resources":
                commit(MutationTypes.SET_RESOURCES, learningList);
                break;
            }
            break;
          case "caseStudies":
            learning.forEach((item: CaseStudy) => {
              const caseStudy = {
                description: `One or more ideas based on the case study '${item.title}'`,
                id: item.id,
                title: item.title,
                challenge: item.challenge,
                actions: item.actions,
                outcome: item.outcome,
                published: item.published,
                challenge_area: item.challenge_area,
                focus_type: item.focus_type,
                score: item.score,
                tips: item.tips ? item.tips : "",
                different_action: item.different_action
                  ? item.different_action
                  : "",
                type: item.type,
                entities: item.entities || undefined,
                themes: item.entities ? buildThemeSet(item.entities) : [],
                isLoading: !item.entities,
                learningType,
              };
              caseStudiesList.push(caseStudy);
            });
            commit(MutationTypes.SET_CASE_STUDIES, caseStudiesList);
            break;
        }
      })
      .catch((error) => {
        captureException(error);
        switch (learningType) {
          case "papers":
            commit(MutationTypes.SET_ERROR_PAPERS, true);
            break;
          case "resources":
            commit(MutationTypes.SET_ERROR_RESOURCES, true);
            break;
          case "caseStudies":
            commit(MutationTypes.SET_ERROR_CASE_STUDIES, true);
            break;
        }
        dispatch("messages/ADD_ERROR_MESSAGE", "ERROR", { root: true });
      })
      .finally(() => {
        switch (learningType) {
          case "papers":
            commit(MutationTypes.SET_LOADING_PAPERS, false);
            break;
          case "resources":
            commit(MutationTypes.SET_LOADING_RESOURCES, false);
            break;
          case "caseStudies":
            commit(MutationTypes.SET_LOADING_CASE_STUDIES, false);
            break;
        }
      });
  },
  [ActionTypes.GET_LEARNING_ENTITIES]({ commit }, payload) {
    const resource = payload;

    // don't use GPT for entities (useGPT = false)
    getEntitiesNlp(`${resource.snippet} ${resource.title}`, "false")
      .then((response) => {
        const themes = [...response.data]
          .sort(() => 0.5 - Math.random())
          .splice(0, 15);
        switch (resource.learningType) {
          case "papers":
            commit(MutationTypes.SET_THEMES_FOR_PAPER, {
              themes,
              resource,
            });
            break;
          case "resources":
            commit(MutationTypes.SET_THEMES_FOR_RESOURCE, {
              themes,
              resource,
            });
            break;
        }
      })
      .catch((error) => {
        // fail silently, don't display an error to the user
        captureException(error);
      });
  },
  [ActionTypes.CLEAR_LEARNING]({ commit }) {
    commit(MutationTypes.CLEAR_LEARNING);
  },
};
