
import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "@/store";
import { camelToLowerCase } from "@/utils/text";
import Guidance from "@/components/content/guidance/Guidance.vue";
import ChallengeLearningList from "@/components/content/challenge-learning-list/ChallengeLearningList.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    Guidance,
    ChallengeLearningList,
    LoadingIcon,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const challenge = computed(() => store.getters["challenge/getChallenge"]);

    // convert (external) learning URL slug to (internal) learning type
    const learningType = computed(() =>
      store.getters["learning/getLearningTypeFromSlug"](props.slug),
    );

    // convert any camelCase learning types to lower case format for display
    const formattedLearningType = computed(() =>
      camelToLowerCase(learningType.value || ""),
    );

    const isLoading = computed(() =>
      store.getters["learning/getLoadingByType"](learningType.value),
    );

    const learning = computed(() =>
      store.getters["learning/getLearningByType"](learningType.value),
    );

    const getLearning = () => {
      if (!challenge.value) return;
      store.dispatch("learning/GET_LEARNING", learningType.value);
    };

    const hasLearning = computed(
      () => learning.value.length > 0 && !isLoading.value,
    );

    const hasError = computed(() =>
      store.getters["learning/getErrorByType"](learningType.value),
    );

    // Make an API request when tab is initially rendered, and if the
    // challenge is updated once the component has rendered, as the initial
    // challenge API request may not have returned when this component is
    // first rendered
    onMounted(() => {
      if (learning.value.length === 0 && !isLoading.value) {
        getLearning();
      }
    });
    watch(challenge, getLearning);

    // as we are using tabs via the router, we also need to watch the (reactive)
    // prop in order to identify the different tabs
    watch(
      () => props.slug,
      () => {
        if (learning.value.length === 0 && !isLoading.value) {
          getLearning();
        }
      },
    );

    return {
      isLoading,
      learning,
      hasLearning,
      formattedLearningType,
      hasError,
    };
  },
});
