
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "@/store";
import CaseStudy from "@/components/content/case-study/CaseStudy.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    CaseStudy,
    LoadingIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();

    const isLoading = computed(() => store.state.caseStudy.isLoading);
    const caseStudy = computed(() => store.state.caseStudy.caseStudy);

    onMounted(() => {
      store.dispatch("caseStudy/GET_CASE_STUDY", props.id);
    });

    return {
      isLoading,
      caseStudy,
    };
  },
});
