
import { defineComponent } from "vue";
import IconSpark from "@/assets/images/icon-spark.svg";

export default defineComponent({
  components: {
    IconSpark,
  },
  props: {
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
