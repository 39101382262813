
import { defineComponent, PropType, reactive } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import { Spark } from "@/store/sparks/state";
import { useModal } from "@/composables/useModal";
import CreateIdea from "@/components/content/create-idea/CreateIdea.vue";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AddIcon from "@/assets/images/icon-add.svg";

export default defineComponent({
  components: {
    CreateIdea,
    AddIcon,
    ModalContent,
    AppButton,
  },
  props: {
    spark: {
      type: Object as PropType<Spark>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const auth0 = useAuth0();
    const route = useRoute();
    const store = useStore();

    // modal
    const modalState = reactive({ addIdea: false });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const showIdeaModal = () => {
      showModal("addIdea");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_SPARK_IDEA_ADD_MODAL_SHOW`,
      });
    };

    const toggleLogin = async () => {
      auth0.loginWithRedirect({
        appState: { target: route.fullPath },
      });
    };

    const toggleSignUp = async () => {
      auth0.loginWithRedirect({
        authorizationParams: { action: "signup" },
        appState: { target: route.fullPath },
      });
    };

    return {
      isAuthenticated: auth0.isAuthenticated,
      toggleLogin,
      toggleSignUp,
      isShown,
      closeModal,
      showIdeaModal,
    };
  },
});
