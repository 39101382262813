
import { defineComponent } from "vue";
import ScrollToTop from "@/components/ui/scroll-to-top/ScrollToTop.vue";

export default defineComponent({
  components: {
    ScrollToTop,
  },
  setup() {
    return {};
  },
});
