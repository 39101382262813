
import { defineComponent, computed, watch } from "vue";
import { useStore } from "@/store";
import { useToast } from "vue-toastification";
import ToastContent from "@/components/ui/toast-content/ToastContent.vue";

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const toast = useToast();

    const message = computed(() => store.getters["messages/getLatestMessage"]);

    watch(message, () => {
      if (message.value) {
        const content = {
          component: ToastContent,
          props: {
            title: message.value.message?.title,
            body: message.value.message?.body,
          },
        };
        toast[message.value.type](content);
      }
    });

    return {
      message,
    };
  },
});
