import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState, toasts } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { captureMessage } from "@/utils/errors";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.ADD_INFO_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;
  [ActionTypes.ADD_SUCCESS_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;
  [ActionTypes.ADD_ERROR_MESSAGE](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;
  [ActionTypes.HIDE_MESSAGE]({ commit }: AugmentedActionContext): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.ADD_INFO_MESSAGE]({ commit }, key) {
    if (!(key in toasts)) {
      captureMessage(
        `Incorrect info message key ${key} provided for toastification`
      );
      return;
    }

    const message = toasts[key];
    commit(MutationTypes.ADD_MESSAGE, {
      message,
      type: "info",
    });
  },
  [ActionTypes.ADD_SUCCESS_MESSAGE]({ commit }, key) {
    if (!(key in toasts)) {
      captureMessage(
        `Incorrect success message key ${key} provided for toastification`
      );
      return;
    }

    const message = toasts[key];
    commit(MutationTypes.ADD_MESSAGE, {
      message,
      type: "success",
    });
  },
  [ActionTypes.ADD_ERROR_MESSAGE]({ commit }, key) {
    if (!(key in toasts)) {
      captureMessage(
        `Incorrect error message key ${key} provided for toastification`
      );
      return;
    }

    const message = toasts[key];
    commit(MutationTypes.ADD_MESSAGE, {
      message,
      type: "error",
    });
  },
  [ActionTypes.HIDE_MESSAGE]({ commit }) {
    commit(MutationTypes.HIDE_MESSAGE);
  },
};
