import { ActionContext, ActionTree } from "vuex";
import { optIn, optOut, event, pageview, PageView } from "vue-gtag";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { ActionTypes } from "./action-types";

type TrackEvent = {
  action: string;
  // EventParams types are not exported from the vue-gtag library
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventParams?: any;
};

// Actions context
type AugmentedActionContext = Omit<
  ActionContext<LocalState, RootState>,
  "commit"
>;

// Actions contracts
export interface Actions {
  [ActionTypes.UPDATE_TRACKING_STATE]({
    rootGetters,
    rootState,
  }: AugmentedActionContext): void;
  [ActionTypes.TRACK_PAGE_VIEW](
    { dispatch }: AugmentedActionContext,
    payload: PageView
  ): void;
  [ActionTypes.TRACK_EVENT](
    { dispatch }: AugmentedActionContext,
    payload: TrackEvent
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.UPDATE_TRACKING_STATE]({ rootGetters, rootState }) {
    if (!rootGetters["cookies/getIsTrackingAvailable"]) return;

    // enable/disable analytics, based on statistics cookie value
    if (rootState.cookies.allowStatisticsCookies) {
      optIn();
    } else {
      optOut();
    }
  },
  [ActionTypes.TRACK_PAGE_VIEW]({ rootGetters, rootState }, payload) {
    if (
      rootGetters["cookies/getIsTrackingAvailable"] &&
      rootState.cookies.allowStatisticsCookies
    ) {
      pageview(payload);
    }
  },
  [ActionTypes.TRACK_EVENT]({ rootGetters, rootState }, payload) {
    if (
      rootGetters["cookies/getIsTrackingAvailable"] &&
      rootState.cookies.allowStatisticsCookies
    ) {
      event(payload.action, payload.eventParams);
    }
  },
};
