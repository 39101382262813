import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-wrap items-stretch justify-start mt-3 -mx-3" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col items-center justify-center my-4"
}
const _hoisted_3 = { class: "w-8" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center mt-3"
}
const _hoisted_5 = { class: "flex flex-row justify-center mt-8 mb-6" }
const _hoisted_6 = {
  key: 1,
  class: "flex flex-row justify-center my-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChallengeLearningListItemVideo = _resolveComponent("ChallengeLearningListItemVideo")!
  const _component_ChallengeLearningListItemText = _resolveComponent("ChallengeLearningListItemText")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Guidance = _resolveComponent("Guidance")!

  return (_ctx.hasLearning)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleLearning, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "w-full sm:w-1/2 lg:w-1/3 p-3"
            }, [
              (item.type === 'video')
                ? (_openBlock(), _createBlock(_component_ChallengeLearningListItemVideo, {
                    key: 0,
                    item: item,
                    index: index
                  }, null, 8, ["item", "index"]))
                : (_openBlock(), _createBlock(_component_ChallengeLearningListItemText, {
                    key: 1,
                    item: item,
                    index: index
                  }, null, 8, ["item", "index"]))
            ]))
          }), 128))
        ]),
        (_ctx.fakeLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_LoadingIcon)
              ])
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.loadMoreIsShown)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_AppButton, {
                      type: "secondary",
                      onClick: _ctx.handleLoadMoreLearning
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Load more ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ], 64)),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Guidance, null, {
            text: _withCtx(() => [
              _createTextVNode(" You can always edit your challenge to refocus the results ")
            ]),
            _: 1
          })
        ])
      ], 64))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createVNode(_component_Guidance, null, {
          text: _withCtx(() => [
            _createTextVNode(" Please add more detail to your challenge description so we can find resources for you. ")
          ]),
          _: 1
        })
      ]))
}