import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChallengeNavigation = _resolveComponent("ChallengeNavigation")!
  const _component_ChallengeHeader = _resolveComponent("ChallengeHeader")!
  const _component_ContentBlock = _resolveComponent("ContentBlock")!
  const _component_ChallengeIdeasList = _resolveComponent("ChallengeIdeasList")!
  const _component_GuidedTour = _resolveComponent("GuidedTour")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ContentBlock, null, {
      default: _withCtx(() => [
        _createVNode(_component_ChallengeNavigation),
        _createVNode(_component_ChallengeHeader, { "is-editable": true })
      ]),
      _: 1
    }),
    _createVNode(_component_ChallengeIdeasList),
    _createVNode(_component_GuidedTour, { name: "challenge-ideas" })
  ], 64))
}