
import { defineComponent, PropType, ref, reactive, computed } from "vue";
import { useStore } from "@/store";
import { Resource, CaseStudy } from "@/store/learning/state";
import { truncate, calculateDuration } from "@/utils/text";
import { useModal } from "@/composables/useModal";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Markdown from "@/components/ui/markdown/Markdown.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import CreateIdea from "@/components/content/create-idea/CreateIdea.vue";
import AddIcon from "@/assets/images/icon-add.svg";
import VideoIcon from "@/assets/images/icon-video.svg";
import ClockIcon from "@/assets/images/icon-clock.svg";

export default defineComponent({
  components: {
    AppButton,
    Markdown,
    ModalContent,
    CreateIdea,
    AddIcon,
    VideoIcon,
    ClockIcon,
  },
  props: {
    item: {
      type: Object as PropType<Resource | CaseStudy>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    // modal
    const modalState = reactive({ addIdea: false });
    const { isShown, showModal, closeModal } = useModal(modalState);

    const duration = computed(() =>
      "duration" in props.item && props.item.duration
        ? calculateDuration(props.item.duration)
        : "",
    );

    // calculate context
    const context = computed(() =>
      store.getters["learning/getContextForResource"](props.item),
    );
    const isShowingContext = ref(false);
    const toggleContext = () => {
      isShowingContext.value = !isShowingContext.value;

      if (isShowingContext.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: `CHALLENGE_LEARNING_VIDEO_IDEA_SHOW_CONTEXT`,
        });
      }
    };

    const showIdeaModal = () => {
      showModal("addIdea");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_LEARNING_VIDEO_IDEA_ADD_MODAL_SHOW`,
      });
    };

    return {
      truncate,
      context,
      duration,
      isShowingContext,
      toggleContext,
      isShown,
      showIdeaModal,
      showModal,
      closeModal,
    };
  },
});
