
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
export default defineComponent({
  components: {
    AppButton,
    AppButtonRouterLink,
    Editorial,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isCookieBannerDismissed = computed(
      () => store.state.cookies.isCookieBannerDismissed,
    );

    // use selected values
    const acceptAllCookies = () => {
      store.dispatch("cookies/ACCEPT_ALL_COOKIES");
      store.dispatch("tracking/TRACK_PAGE_VIEW", route);
    };

    return {
      isCookieBannerDismissed,
      acceptAllCookies,
    };
  },
});
