import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Spark, SparkType } from "./state";

type SparksPayload = {
  sparkType: SparkType;
  sparks: Spark[];
};

type LoadingPayload = {
  sparkType: SparkType;
  loading: boolean;
};

type ErrorPayload = {
  sparkType: SparkType;
  error: boolean;
};

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_SPARKS](state: S, payload: SparksPayload): void;
  [MutationTypes.SET_LOADING](state: S, payload: LoadingPayload): void;
  [MutationTypes.SET_ERROR](state: S, payload: ErrorPayload): void;
  [MutationTypes.CLEAR_SPARKS](state: S): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_SPARKS](state, payload) {
    state.sparks = { ...state.sparks, [payload.sparkType]: payload.sparks };
  },
  [MutationTypes.SET_LOADING](state, payload) {
    state.isLoading = {
      ...state.isLoading,
      [payload.sparkType]: payload.loading,
    };
  },
  [MutationTypes.SET_ERROR](state, payload) {
    state.hasError = {
      ...state.hasError,
      [payload.sparkType]: payload.error,
    };
  },
  [MutationTypes.CLEAR_SPARKS](state) {
    Object.keys(state.sparks).forEach((key) => {
      state.sparks[key] = [];
    });
  },
};
