import { nextTick } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

import { useStore } from "@/store";
import { setPageTitle } from "@/utils/dom";

import RootLayout from "@/components/layout/root/Root.vue";
import HomeView from "@/components/views/home/Home.vue";

import NewChallengeView from "@/components/views/new-challenge/NewChallenge.vue";
import Challenge from "@/components/content/challenge-form/Challenge.vue";
import Ideas from "@/components/content/challenge-form/Ideas.vue";
import Plan from "@/components/content/challenge-form/Plan.vue";
import Resolution from "@/components/content/challenge-form/Resolution.vue";
import Inspiration from "@/components/content/challenge-form/Inspiration.vue";
import InspirationLearning from "@/components/content/challenge-form/InspirationLearning.vue";
import InspirationLearningTab from "@/components/content/challenge-form/InspirationLearningTab.vue";
import InspirationSparks from "@/components/content/challenge-form/InspirationSparks.vue";
import InspirationSparksTab from "@/components/content/challenge-form/InspirationSparksTab.vue";

import SavedChallengesList from "@/components/content/saved-challenges-list/SavedChallengesList.vue";
import ChallengesList from "@/components/content/challenges-list/ChallengesList.vue";
import SavedChallengesView from "@/components/views/saved-challenges/SavedChallenges.vue";
import SavedChallengeView from "@/components/views/saved-challenge/SavedChallenge.vue";

import CaseStudyView from "@/components/views/case-study/CaseStudy.vue";
import ErrorView from "@/components/views/error/Error.vue";
import CookiePolicyView from "@/components/views/cookie-policy/CookiePolicy.vue";
import ComponentLibraryView from "@/components/views/component-library/ComponentLibrary.vue";

declare module "vue-router" {
  interface Breadcrumb {
    title: string;
    name?: string;
  }
  interface RouteMeta {
    pageTitle?: string;
    hasTour?: boolean;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: RootLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
        meta: {
          pageTitle: "Home",
        },
      },
      {
        path: "challenges/new/",
        name: "challenges-new",
        component: NewChallengeView,
        redirect: () => ({ name: "challenge-new-challenge" }),
        meta: {
          challengeType: "new",
        },
        children: [
          {
            path: "",
            name: "challenge-new-challenge",
            component: Challenge,
            meta: {
              pageTitle: "New Challenge",
              hasTour: true,
            },
          },
          {
            path: "inspiration/",
            name: "challenge-new-inspiration",
            component: Inspiration,
            meta: {
              pageTitle: "Inspiration",
            },
            children: [
              {
                path: "sparks/",
                name: "challenge-new-inspiration-sparks",
                component: InspirationSparks,
                meta: {
                  maintainScrollPosition: true,
                },
                children: [
                  {
                    path: ":slug/",
                    name: "challenge-new-inspiration-sparks-tab",
                    component: InspirationSparksTab,
                    meta: {
                      maintainScrollPosition: true,
                      hasTour: true,
                    },
                    props: (route) => {
                      return { slug: route.params.slug || "" };
                    },
                  },
                ],
              },
              {
                path: "learning/",
                name: "challenge-new-inspiration-learning",
                component: InspirationLearning,
                meta: {
                  maintainScrollPosition: true,
                },
                children: [
                  {
                    path: ":slug/",
                    name: "challenge-new-inspiration-learning-tab",
                    component: InspirationLearningTab,
                    meta: {
                      maintainScrollPosition: true,
                      hasTour: true,
                    },
                    props: (route) => {
                      return { slug: route.params.slug || "" };
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "challenges/",
        name: "challenges-saved",
        component: SavedChallengesView,
        redirect: () => ({ name: "saved-challenges-list" }),
        children: [
          {
            path: "",
            name: "saved-challenges-list",
            component: SavedChallengesList,
            meta: {
              pageTitle: "Saved Challenges",
              hasTour: true,
            },
            children: [
              {
                path: ":slug/",
                name: "saved-challenges-tab",
                component: ChallengesList,
                props: (route) => {
                  return {
                    isResolved: route.params.slug === "resolved",
                    hasFilter: true,
                  };
                },
              },
            ],
          },
          {
            path: ":id/",
            name: "challenge-saved",
            component: SavedChallengeView,
            redirect: () => ({ name: "challenge-saved-inspiration" }),
            meta: {
              challengeType: "saved",
            },
            children: [
              {
                path: "inspiration/",
                name: "challenge-saved-inspiration",
                beforeEnter: [authGuard],
                component: Inspiration,
                meta: {
                  pageTitle: "Inspiration",
                },
                children: [
                  {
                    path: "sparks/",
                    name: "challenge-saved-inspiration-sparks",
                    component: InspirationSparks,
                    meta: {
                      maintainScrollPosition: true,
                    },
                    children: [
                      {
                        path: ":slug/",
                        name: "challenge-saved-inspiration-sparks-tab",
                        component: InspirationSparksTab,
                        meta: {
                          maintainScrollPosition: true,
                          hasTour: true,
                        },
                        props: (route) => {
                          return { slug: route.params.slug || "" };
                        },
                      },
                    ],
                  },
                  {
                    path: "learning/",
                    name: "challenge-saved-inspiration-learning",
                    component: InspirationLearning,
                    meta: {
                      maintainScrollPosition: true,
                    },
                    children: [
                      {
                        path: ":slug/",
                        name: "challenge-saved-inspiration-learning-tab",
                        component: InspirationLearningTab,
                        meta: {
                          maintainScrollPosition: true,
                          hasTour: true,
                        },
                        props: (route) => {
                          return { slug: route.params.slug || "" };
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "ideas/",
                name: "challenge-saved-ideas",
                beforeEnter: [authGuard],
                component: Ideas,
                meta: {
                  pageTitle: "Ideas",
                  hasTour: true,
                },
              },
              {
                path: "plan/",
                name: "challenge-saved-plan",
                beforeEnter: [authGuard],
                component: Plan,
                meta: {
                  pageTitle: "Plan",
                  hasTour: true,
                },
              },
              {
                path: "resolution/",
                name: "challenge-saved-resolution",
                beforeEnter: [authGuard],
                component: Resolution,
                meta: {
                  pageTitle: "Resolution",
                  hasTour: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "case-study/:id/",
        name: "case-study",
        component: CaseStudyView,
        meta: {
          breadcrumb: [{ title: "Case Study" }],
          heading: "Case Study",
        },
        props: (route) => {
          return { id: route.params.id || "" };
        },
      },
      {
        path: "cookie-policy/",
        name: "cookie-policy",
        component: CookiePolicyView,
        meta: {
          pageTitle: "Cookies",
        },
      },
      {
        path: "component-library/",
        name: "component-library",
        component: ComponentLibraryView,
        meta: {
          pageTitle: "Component Library",
        },
      },
    ],
  },
  {
    path: "/error/",
    name: "error",
    component: ErrorView,
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // maintain current scroll position when we are linking to a route where
    // we don't want to adjust the scroll position
    // (and when the user hasn't clicked the browser's back/next buttons)
    if (!savedPosition && to?.meta?.maintainScrollPosition) return;

    // if a saved position is not set, use default
    if (!savedPosition) {
      savedPosition = {
        left: 0,
        top: 0,
      };
    }

    // animate the scroll to top
    // add a slight delay as without it the scroll is temperamental/inconsistent
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ ...savedPosition, behavior: "smooth" });
      }, 10);
    });
  },
});

// update HTML <title> content on route navigation
router.beforeEach(async (to, from, next) => {
  await nextTick();
  const title = to.meta.pageTitle ? to.meta.pageTitle : "";
  setPageTitle(title);
  next();
});

router.afterEach((to) => {
  const store = useStore();
  store.dispatch("tracking/TRACK_PAGE_VIEW", to.path);
});

export default router;
