
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "@/store";
import GuidedTourContent from "./GuidedTourContent.vue";

export default defineComponent({
  components: {
    GuidedTourContent,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const startTour = computed(() => store.state.guidedTour.startTour);

    const guidedTourContentRef = ref<InstanceType<
      typeof GuidedTourContent
    > | null>(null);

    watch(startTour, () => {
      if (startTour.value) {
        guidedTourContentRef.value?.start();

        store.commit("guidedTour/SET_TOUR_STARTED");

        store.dispatch("tracking/TRACK_EVENT", {
          action: `TOUR_START_${props.name.toUpperCase()}`,
        });
      }
    });

    return {
      guidedTourContentRef,
    };
  },
});
