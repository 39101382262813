import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserSignupContent = _resolveComponent("UserSignupContent")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    modelValue: _ctx.isShown.userSignup,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isShown.userSignup) = $event)),
    close: () => _ctx.closeModal('userSignup'),
    options: _ctx.userSignupModalOptions
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UserSignupContent, {
        "close-modal": () => _ctx.closeModal('userSignup')
      }, null, 8, ["close-modal"])
    ]),
    _: 1
  }, 8, ["modelValue", "close", "options"]))
}