import { ActionContext, ActionTree } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_PREFERENCES_COOKIE](
    { commit, dispatch }: AugmentedActionContext,
    value: boolean
  ): void;
  [ActionTypes.SET_STATISTICS_COOKIE](
    { dispatch }: AugmentedActionContext,
    value: boolean
  ): void;
  [ActionTypes.SET_MARKETING_COOKIE](
    { dispatch }: AugmentedActionContext,
    value: boolean
  ): void;
  [ActionTypes.ACCEPT_ALL_COOKIES]({ dispatch }: AugmentedActionContext): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_PREFERENCES_COOKIE]({ commit, dispatch }, value) {
    commit(MutationTypes.SET_COOKIE_VALUE, {
      cookie: "allowPreferencesCookies",
      value,
    });
    dispatch(
      "tracking/TRACK_EVENT",
      { action: `SET_PREFERENCE_COOKIE_TO_${value.toString().toUpperCase()}` },
      { root: true }
    );
  },
  [ActionTypes.SET_STATISTICS_COOKIE]({ commit, dispatch }, value) {
    commit(MutationTypes.SET_COOKIE_VALUE, {
      cookie: "allowStatisticsCookies",
      value,
    });
    dispatch("tracking/UPDATE_TRACKING_STATE", null, { root: true });
    dispatch(
      "tracking/TRACK_EVENT",
      { action: `SET_STATISTICS_COOKIE_TO_${value.toString().toUpperCase()}` },
      { root: true }
    );
  },
  [ActionTypes.SET_MARKETING_COOKIE]({ commit, dispatch }, value) {
    commit(MutationTypes.SET_COOKIE_VALUE, {
      cookie: "allowMarketingCookies",
      value,
    });
    dispatch(
      "tracking/TRACK_EVENT",
      { action: `SET_MARKETING_COOKIE_TO_${value.toString().toUpperCase()}` },
      { root: true }
    );
  },
  [ActionTypes.ACCEPT_ALL_COOKIES]({ commit, dispatch }) {
    commit(MutationTypes.DISMISS_COOKIE_BANNER);
    dispatch(ActionTypes.SET_STATISTICS_COOKIE, true);
    dispatch(ActionTypes.SET_PREFERENCES_COOKIE, true);
    dispatch(ActionTypes.SET_MARKETING_COOKIE, true);
  },
};
