// A cookie may be explicitly set, but on initial load will not be set yet.
// We will only use cookies that are explicitly allowed, but this enables us
// to potentially differentiate between disallowed and as-yet-unspecified
// cookies.
type CookieState = boolean | null;

export type Cookies =
  | "allowPreferencesCookies"
  | "allowStatisticsCookies"
  | "allowMarketingCookies";

// Declare state
export type State = {
  isCookieBannerDismissed: boolean;
  allowPreferencesCookies: CookieState;
  allowStatisticsCookies: CookieState;
  allowMarketingCookies: CookieState;
};

// Create initial state
export const state = {
  isCookieBannerDismissed: false,
  allowPreferencesCookies: null,
  allowStatisticsCookies: null,
  allowMarketingCookies: null,
} as State;
