import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api-client";

/* api calls */

export const getEntitiesNlp = (text: string, useGPT: string): AxiosPromise => {
  return apiClient.post(`/nlp/keywords`, {
    text,
    useGPT,
    method: "cebai-topics",
  });
};
