// Action enums
export enum ActionTypes {
  GET_CHALLENGE = "GET_CHALLENGE",
  UPDATE_CHALLENGE = "UPDATE_CHALLENGE",
  GET_AI_CHALLENGE_TITLE = "GET_AI_CHALLENGE_TITLE",
  ADD_IDEA = "ADD_IDEA",
  DELETE_IDEA = "DELETE_IDEA",
  UPDATE_IDEA = "UPDATE_IDEA",
  SET_IDEA_FAVORITE = "SET_IDEA_FAVORITE",
  ADD_SOLUTION = "ADD_SOLUTION",
  DELETE_SOLUTION = "DELETE_SOLUTION",
  UPDATE_SOLUTION = "UPDATE_SOLUTION",
  RESOLVE_CHALLENGE = "RESOLVE_CHALLENGE",
  DELETE_CHALLENGE = "DELETE_CHALLENGE",
  GENERATE_PLAN = "GENERATE_PLAN",
  GET_ENTITIES = "GET_ENTITIES",
}
