import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggle = _resolveComponent("Toggle")!

  return (_openBlock(), _createBlock(_component_Toggle, {
    modelValue: _ctx.setting,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.setting) = $event)),
    label: _ctx.label,
    "help-text": _ctx.helpText
  }, null, 8, ["modelValue", "label", "help-text"]))
}