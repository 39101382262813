import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "w-5 h-5 absolute left-1/2 -ml-2.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button group", {
      [`button--type-${_ctx.type}`]: _ctx.type,
      [`button--padding-${_ctx.padding}`]: _ctx.padding,
      [`button--disabled`]: _ctx.disabled,
    }]),
    disabled: _ctx.isLoading
  }, [
    _withDirectives(_createElementVNode("span", _hoisted_2, [
      _createVNode(_component_LoadingIcon, { class: "fill-current" })
    ], 512), [
      [_vShow, _ctx.isLoading]
    ]),
    _renderSlot(_ctx.$slots, "iconBefore"),
    _createElementVNode("span", {
      class: _normalizeClass(["button__text", { invisible: _ctx.isLoading }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _createElementVNode("span", {
      class: _normalizeClass({ invisible: _ctx.isLoading })
    }, [
      _renderSlot(_ctx.$slots, "iconAfter")
    ], 2)
  ], 10, _hoisted_1))
}