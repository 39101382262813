import { createAuth0 } from "@auth0/auth0-vue";

// enable useRefreshTokens and localstorage so that page refresh works
// in Safari, and other browsers blocking third-party cookies by default.
// nb "Allow Offline Access" must be set in the auth0 tenant API settings.
// https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276

export const auth0 = createAuth0({
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
  authorizationParams: {
    audience: "https://backend.sportsparks.cebai",
    redirect_uri: process.env.VUE_APP_BASE_URL,
  },
});
