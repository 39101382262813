
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    router.replace({ name: "challenges-new" });
    return {};
  },
});
