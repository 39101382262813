// Mutations enums
export enum MutationTypes {
  SET_LOADING_USER = "SET_LOADING_USER",
  SET_DELETING_USER = "SET_DELETING_USER",
  SET_SAVING_PROFILE = "SET_SAVING_PROFILE",
  SET_USER = "SET_USER",
  SET_USER_SETTINGS = "SET_USER_SETTINGS",
  SET_AUTH0_USER = "SET_AUTH0_USER",
  UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS",
  SET_USER_PICTURE = "SET_USER_PICTURE",
  SET_UPDATING_USER_PICTURE = "SET_UPDATING_USER_PICTURE",
}
