import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-28 mb-2 flex justify-center" }
const _hoisted_2 = { class: "w-8" }
const _hoisted_3 = { class: "mb-40 text-center" }
const _hoisted_4 = {
  key: 1,
  class: "flex flex-row justify-center mt-8 mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_Guidance = _resolveComponent("Guidance")!
  const _component_ChallengeLearningList = _resolveComponent("ChallengeLearningList")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_LoadingIcon)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, "Fetching " + _toDisplayString(_ctx.formattedLearningType) + "...", 1)
      ], 64))
    : (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Guidance, null, {
            text: _withCtx(() => [
              _createTextVNode(" Sorry, something went wrong and we have no " + _toDisplayString(_ctx.formattedLearningType) + " for you. ", 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createBlock(_component_ChallengeLearningList, {
          key: 2,
          learning: _ctx.learning,
          "has-learning": _ctx.hasLearning
        }, null, 8, ["learning", "has-learning"]))
}