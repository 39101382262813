import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState, Prompt } from "./state";
import { Spark } from "../sparks/state";

interface RandomGuidance {
  (type: string | undefined): Prompt[];
}

// Getters types
export type Getters = {
  getScamperPrompts(state: LocalState): Prompt[];
  getTrizPrompts(state: LocalState): Prompt[];
  getLearningPrompts(state: LocalState): Spark[];
  getGuidance(state: LocalState): RandomGuidance;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getScamperPrompts: (state) => {
    return state.scamperPrompts;
  },
  getTrizPrompts: (state) => {
    return state.trizPrompts;
  },
  getLearningPrompts: (state) => {
    return [...state.learningPrompts]
      .sort(() => 0.5 - Math.random())
      .splice(0, 1);
  },
  getGuidance: (state) => (type) => {
    // note that property-style getters are cached until state changes, therefore we
    // need to use a method-style getter here (passing an argument, in this case 'type').
    // This getter does not cache, and will run each time to return a new random prompt.
    let guidance: Prompt[] = [];
    switch (true) {
      case type === "ideation":
        guidance = [...state.ideationGuidance]
          .sort(() => 0.5 - Math.random())
          .splice(0, 1);
        break;
      case type === "refinement":
        guidance = [...state.refinementGuidance]
          .sort(() => 0.5 - Math.random())
          .splice(0, 1);
        break;
      case type === "sparks":
        guidance = [...state.sparksGuidance]
          .sort(() => 0.5 - Math.random())
          .splice(0, 1);
        break;
      case type === "learning":
        guidance = [...state.learningGuidance]
          .sort(() => 0.5 - Math.random())
          .splice(0, 1);
        break;
      case type === "ideas":
        guidance = [...state.ideasGuidance]
          .sort(() => 0.5 - Math.random())
          .splice(0, 1);
        break;
      case type === "plan":
        guidance = [...state.planGuidance]
          .sort(() => 0.5 - Math.random())
          .splice(0, 1);
        break;
      default:
        guidance = [];
        break;
    }
    return guidance;
  },
};
