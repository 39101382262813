import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Challenge, SubjectType } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_LOADING_ACTIVE_CHALLENGES](
    state: S,
    isLoading: boolean,
  ): void;
  [MutationTypes.SET_LOADING_RESOLVED_CHALLENGES](
    state: S,
    isLoading: boolean,
  ): void;
  [MutationTypes.SET_ACTIVE_CHALLENGES](
    state: S,
    challenges: Challenge[],
  ): void;
  [MutationTypes.SET_RESOLVED_CHALLENGES](
    state: S,
    challenges: Challenge[],
  ): void;
  [MutationTypes.SET_CHALLENGE_TYPES](state: S, challengeTypes: string[]): void;
  [MutationTypes.SET_SUBJECT_TYPES](
    state: S,
    subjectTypes: SubjectType[],
  ): void;
  [MutationTypes.SET_CHALLENGE_TAGS](state: S, challengeTags: string[]): void;
  [MutationTypes.CLEAR_CHALLENGE_TAGS](state: S): void;
  [MutationTypes.SET_OUTCOME_TAGS_HELPS](
    state: S,
    outcomeTagsHelps: string[],
  ): void;
  [MutationTypes.SET_OUTCOME_TAGS_PREVENTS](
    state: S,
    outcomeTagsPrevents: string[],
  ): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_LOADING_ACTIVE_CHALLENGES](state, isLoading) {
    state.isLoadingActiveChallenges = isLoading;
  },
  [MutationTypes.SET_LOADING_RESOLVED_CHALLENGES](state, isLoading) {
    state.isLoadingResolvedChallenges = isLoading;
  },
  [MutationTypes.SET_ACTIVE_CHALLENGES](state, challenges) {
    state.activeChallenges = challenges;
  },
  [MutationTypes.SET_RESOLVED_CHALLENGES](state, challenges) {
    state.resolvedChallenges = challenges;
  },
  [MutationTypes.SET_CHALLENGE_TYPES](state, challengeTypes) {
    state.challengeTypes = challengeTypes;
  },
  [MutationTypes.SET_SUBJECT_TYPES](state, subjectTypes) {
    state.subjectTypes = subjectTypes;
  },
  [MutationTypes.SET_CHALLENGE_TAGS](state, challengeTags) {
    state.challengeTags = challengeTags;
  },
  [MutationTypes.CLEAR_CHALLENGE_TAGS](state) {
    state.challengeTags = [];
  },
  [MutationTypes.SET_OUTCOME_TAGS_HELPS](state, outcomeTagsHelps) {
    state.outcomeTagsHelps = outcomeTagsHelps;
  },
  [MutationTypes.SET_OUTCOME_TAGS_PREVENTS](state, outcomeTagsPrevents) {
    state.outcomeTagsPrevents = outcomeTagsPrevents;
  },
};
