// see: https://logaretm.com/blog/my-favorite-5-vuejs-composables/#useonclickoutside
import { Ref } from "vue";
import { useEventListener } from "./useEventListener";

export function useOnClickOutside(
  rootEl: Ref<HTMLElement | null>,
  callback: () => void,
) {
  // `mousedown` or `mouseup` here makes it easier to not trigger the callback immediately
  // if you want to use `click` you need to call `stopPropagation` on the trigger element.
  useEventListener(window, "mousedown", (e: Event) => {
    const clickedEl = e.target as HTMLElement;

    // skip if the root element contains the clicked element
    if (rootEl.value?.contains(clickedEl)) {
      return;
    }
    // otherwise execute the action
    callback();
  });
}
