
import { defineComponent, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";
import { useAuth0 } from "@auth0/auth0-vue";
import GuidedTour from "@/components/ui/guided-tour/GuidedTour.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import GuidancePrompts from "@/components/content/guidance/GuidancePrompts.vue";

export default defineComponent({
  components: {
    GuidedTour,
    Tabs,
    GuidancePrompts,
  },
  props: {
    slug: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const auth0 = useAuth0();

    const challengeType = computed(() => route.meta.challengeType);

    const tabs = computed(() => [
      {
        slug: "explore",
        label: "Explore",
        route: {
          name: `challenge-${challengeType.value}-inspiration-sparks-tab`,
          params: { slug: "explore" },
        },
      },
      {
        slug: "imagine",
        label: "Imagine",
        route: {
          name: `challenge-${challengeType.value}-inspiration-sparks-tab`,
          params: { slug: "imagine" },
        },
      },
      {
        slug: "solve",
        label: "Solve",
        route: {
          name: `challenge-${challengeType.value}-inspiration-sparks-tab`,
          params: { slug: "solve" },
        },
      },
    ]);

    const redirectToDefaultTab = () => {
      if (
        route.name === `challenge-${challengeType.value}-inspiration-sparks` &&
        !route.params.slug
      ) {
        router.replace({
          name: `challenge-${challengeType.value}-inspiration-sparks-tab`,
          params: { slug: "explore" },
        });
      }
    };

    // ensure that a tab is displayed by selecting the first as default
    watch(() => route.params.slug, redirectToDefaultTab, { immediate: true });

    const activeTab = computed(() => (route.params.slug as string) || "");

    onMounted(() => {
      if (auth0.isAuthenticated.value) {
        store.dispatch("ideaSparks/GET_GUIDANCE", "sparks");
      }
    });

    return {
      isAuthenticated: auth0.isAuthenticated,
      tabs,
      activeTab,
    };
  },
});
