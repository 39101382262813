import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { CaseStudy } from "../learning/state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_LOADING](state: S, isLoading: boolean): void;
  [MutationTypes.SET_CASE_STUDY](state: S, caseStudy: CaseStudy | null): void;
  [MutationTypes.CLEAR_CASE_STUDY](state: S): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MutationTypes.SET_CASE_STUDY](state, caseStudy) {
    state.caseStudy = caseStudy;
  },
  [MutationTypes.CLEAR_CASE_STUDY](state) {
    state.caseStudy = null;
  },
};
