
import { defineComponent, computed } from "vue";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    helpText: {
      type: String,
      required: false,
      default: null,
    },
    unselectedLabel: {
      type: String,
      required: false,
      default: null,
    },
    selectedLabel: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      },
    });

    return {
      value,
    };
  },
});
