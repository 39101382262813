import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, PossibleIdea } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_LOADING_POSSIBLE_IDEAS](
    state: S,
    isLoadingPossibleIdeas: boolean
  ): void;
  [MutationTypes.SET_POSSIBLE_IDEAS](state: S, ideas: PossibleIdea[]): void;
  [MutationTypes.CLEAR_POSSIBLE_IDEAS](state: S): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_LOADING_POSSIBLE_IDEAS](state, isLoadingPossibleIdeas) {
    state.isLoadingPossibleIdeas = isLoadingPossibleIdeas;
  },
  [MutationTypes.SET_POSSIBLE_IDEAS](state, ideas) {
    state.ideas = ideas;
  },
  [MutationTypes.CLEAR_POSSIBLE_IDEAS](state) {
    state.ideas = [];
  },
};
