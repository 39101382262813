import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Notification } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_NOTIFICATIONS](
    state: S,
    notifications: Notification[]
  ): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },
};
