// based on https://medium.com/@ankurr.singhal/create-your-own-custom-vue-hooks-useelementsize-d20d6a73aef5

import { Ref, ref, watch } from "vue";

export function useElementSize(element: Ref<HTMLElement | null>) {
  const elementWidth = ref(0);
  const elementHeight = ref(0);

  let resizeObserver: ResizeObserver | undefined;

  // Invoke the disconnect method on the ResizeObserver
  const disconnect = () => {
    if (resizeObserver !== undefined) {
      resizeObserver.disconnect();
      resizeObserver = undefined;
    }
  };

  // Set up a ResizeObserver and start observing changes to the element.
  // The observer can observe multiple elements at once (hence the entries array),
  // but we are only interested in the first entry and the property contentRect.
  // Set our width and height refs to the width and height of the contentRect.
  const connect = (element: HTMLElement) => {
    disconnect();
    resizeObserver = new ResizeObserver((entries) => {
      const rect = entries[0]?.contentRect;
      if (rect) {
        elementWidth.value = rect.width;
        elementHeight.value = rect.height;
      }
    });

    resizeObserver.observe(element);
  };

  // As soon as we have a valid element that is not undefined or null
  // connect it, else disconnect.
  watch(element, (el) => {
    if (el && el !== null) {
      connect(el);
    } else {
      disconnect();
    }
  });

  return {
    elementWidth,
    elementHeight,
  };
}
