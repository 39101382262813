import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentBlock = _resolveComponent("ContentBlock")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'bg-cebai-grey-lightest': _ctx.ribbonType === 'grey',
      'bg-cebai-primary/10': _ctx.ribbonType === 'primary',
    })
  }, [
    _createVNode(_component_ContentBlock, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ], 2))
}