
import { defineComponent, ref, computed } from "vue";
import { useAutoResizeTextarea } from "@/composables/useAutoResizeTextarea";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    maxRows: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const textareaValue = computed({
      get() {
        return props.modelValue;
      },
      set(textareaValue) {
        emit("update:modelValue", textareaValue);
      },
    });

    const textareaEl = ref<HTMLTextAreaElement | null>(null);

    // define event handler for textarea changes
    const handleTextareaChange = (event: Event) =>
      (event.target as HTMLTextAreaElement).value;

    const focus = () => {
      textareaEl.value?.focus();
    };

    // allow the textarea caret to be manually moved, for example after its
    // value has been set programatically.
    // Also allow the textarea to be automatically scrolled back to the top if
    // the caret is being moved to the start of the field.
    const scrollToTop = () => {
      if (textareaEl.value) {
        textareaEl.value.focus();
        textareaEl.value.setSelectionRange(0, 0);
        textareaEl.value.scrollTop = 0;
        textareaEl.value.blur();
      }
    };

    useAutoResizeTextarea(textareaEl, textareaValue, props.maxRows);

    return {
      textareaEl,
      textareaValue,
      handleTextareaChange,
      focus,
      scrollToTop,
    };
  },
});
