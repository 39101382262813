
import { defineComponent, PropType } from "vue";
import { useStore } from "@/store";
import { Spark } from "@/store/sparks/state";
import ChallengeSparksListItem from "@/components/content/challenge-sparks-list/ChallengeSparksListItem.vue";
import Guidance from "@/components/content/guidance/Guidance.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import RefreshIcon from "@/assets/images/icon-refresh.svg";

export default defineComponent({
  components: {
    ChallengeSparksListItem,
    Guidance,
    AppButton,
    RefreshIcon,
  },
  props: {
    sparks: {
      type: Object as PropType<Spark[]>,
      required: true,
    },
    hasSparks: {
      type: Boolean,
      required: true,
    },
    handleRefresh: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const handleRefreshClick = () => {
      props.handleRefresh();

      store.dispatch("tracking/TRACK_EVENT", {
        action: `CHALLENGE_SPARK_LIST_REFRESH`,
      });
    };
    return {
      handleRefreshClick,
    };
  },
});
