import { ref } from "vue";
import { useEventListener } from "./useEventListener";

export function useScrollToTop() {
  const isShownScrollToTop = ref(false);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEventListener(window, "scroll", () => {
    if (window.scrollY > 300) {
      isShownScrollToTop.value = true;
    } else {
      isShownScrollToTop.value = false;
    }
  });

  return { isShownScrollToTop, handleScrollToTop };
}
