import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-full flex flex-col border-[3px] border-cebai-grey-light rounded-2xl" }
const _hoisted_2 = { class: "flex flex-wrap items-start p-6" }
const _hoisted_3 = {
  key: 0,
  class: "h-8 w-8 my-1 mr-2"
}
const _hoisted_4 = { class: "flex flex-1 justify-between min-w-[82%]" }
const _hoisted_5 = { class: "mr-3 text-lg" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "pb-3 px-6 text-sm"
}
const _hoisted_8 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_9 = { class: "px-6 py-4 flex-1" }
const _hoisted_10 = { class: "flex items-center justify-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoIcon = _resolveComponent("VideoIcon")!
  const _component_AddIcon = _resolveComponent("AddIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ClockIcon = _resolveComponent("ClockIcon")!
  const _component_CreateIdea = _resolveComponent("CreateIdea")!
  const _component_ModalContent = _resolveComponent("ModalContent")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.item.origin === 'youtube')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_VideoIcon, { class: "fill-current" })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, [
            _createElementVNode("a", {
              class: "block",
              href: _ctx.item.uri,
              target: "_blank"
            }, _toDisplayString(_ctx.item.title), 9, _hoisted_6)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_AppButton, {
              id: `add-learning-${_ctx.index}`,
              type: "outline",
              padding: "narrow",
              onClick: _ctx.showIdeaModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AddIcon, { class: "fill-current" })
              ]),
              _: 1
            }, 8, ["id", "onClick"])
          ])
        ])
      ]),
      (_ctx.context)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.isShowingContext)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                  _createVNode(_component_Markdown, { source: _ctx.context }, null, 8, ["source"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "text-sm text-cebai-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleContext && _ctx.toggleContext(...args)))
            }, [
              (_ctx.isShowingContext)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" Hide context ")
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Show context ")
                  ], 64))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_ClockIcon, { class: "fill-current mr-2" }),
          _createElementVNode("span", null, _toDisplayString(_ctx.duration), 1)
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShown.addIdea,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isShown.addIdea) = $event)),
      close: () => _ctx.closeModal('addIdea')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ModalContent, {
          close: () => _ctx.closeModal('addIdea'),
          title: "Develop your idea"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CreateIdea, {
              "close-modal": () => _ctx.closeModal('addIdea'),
              description: _ctx.item.description,
              notes: _ctx.item.uri
            }, null, 8, ["close-modal", "description", "notes"])
          ]),
          _: 1
        }, 8, ["close"])
      ]),
      _: 1
    }, 8, ["modelValue", "close"])
  ], 64))
}