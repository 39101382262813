
import { defineComponent } from "vue";
import Multiselect from "@vueform/multiselect";
import DropdownArrowIcon from "@/assets/images/icon-dropdown-triangle.svg";

export default defineComponent({
  components: {
    Multiselect,
    DropdownArrowIcon,
  },
  setup() {
    // Customise styles for multiselect inputs
    // For full options, see:
    // https://github.com/vueform/multiselect#using-classes-prop
    const inputClasses = {
      container:
        "relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border-2 border-cebai-grey-lighter text-base rounded-xl leading-snug outline-none py-1 md:py-2",
      containerActive: "border-cebai-primary",
      search:
        "w-full absolute inset-0 outline-none appearance-none outline-none box-border border-0 text-base rounded pt-2 pl-4 sm:pl-5",
      tagsSearch:
        "absolute inset-0 border-0 outline-none appearance-none p-0 text-base box-border w-full bg-transparent",
      placeholder:
        "flex items-center h-full absolute left-0 top-0 pointer-events-none leading-snug pl-4 sm:pl-5 text-cebai-grey-dark",
      singleLabel:
        "flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none leading-snug pl-4 sm:pl-5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5",
      groupLabel:
        "flex text-xs uppercase items-center justify-start text-left py-3 px-3 cursor-default leading-normal bg-cebai-grey-lightest text-cebai-grey-dark",
      option:
        "flex items-center justify-start box-border text-left text-cebai-grey-dark cursor-pointer text-base leading-snug py-4 px-5 bg-cebai-grey-lightest hover:bg-cebai-grey-light mt-1",
      optionSelected: "bg-cebai-grey-light",
      optionPointed: "bg-cebai-grey-light",
      optionSelectedPointed: "bg-cebai-grey-light",
      tags: "flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-3 sm:pl-4 rtl:pl-0 rtl:pr-2",
      tag: "bg-cebai-grey-lighter text-cebai-base-dark text-sm font-robotoMedium py-1.5 pl-2.5 rounded-lg mr-2 mb-1 flex items-center",
      tagRemoveIcon: "multiselect-tag-remove-icon opacity-30",
      tagWrapperBreak: "whitespace-normal break-normal",
      dropdown:
        "max-h-52 absolute -left-px -right-px -bottom-1 translate-y-full overflow-y-scroll z-50 flex flex-col rounded-md bg-cebai-base-light",
      dropdownHidden: "hidden",
      noOptions:
        "py-4 px-5 text-cebai-grey-dark bg-cebai-grey-lightest text-left text-base leading-snug rtl:text-right",
      noResults:
        "py-4 px-5 text-cebai-grey-dark bg-cebai-grey-lightest text-left text-base leading-snug rtl:text-right",
    };

    return {
      inputClasses,
    };
  },
});
