
import { defineComponent, PropType } from "vue";

type ActionType = (payload: MouseEvent) => void;

export default defineComponent({
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    action: {
      type: Function as PropType<ActionType>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
