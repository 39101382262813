import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "hidden md:flex flex-row text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: link.name,
          class: _normalizeClass({ 'mr-4 lg:mr-8 transition-all': index !== _ctx.links.length - 1 })
        }, [
          _createVNode(_component_router_link, {
            to: { name: link.name }
          }, {
            default: _withCtx(({ isActive }) => [
              _createElementVNode("span", {
                class: _normalizeClass(["block px-2 pt-8 pb-6 border-b-[5px] -mb-[4px] text-xl font-robotoMedium transition", {
              'border-transparent text-cebai-grey-dark hover:text-cebai-base-dark':
                !isActive,
              'border-b-cebai-primary text-cebai-base-dark': isActive,
            }])
              }, _toDisplayString(link.label), 3)
            ]),
            _: 2
          }, 1032, ["to"])
        ], 2))
      }), 128))
    ])
  ]))
}