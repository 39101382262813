
import { defineComponent, ref, computed, reactive } from "vue";
import { useStore } from "@/store";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from "vue-router";
import { useModal } from "@/composables/useModal";
import { HeaderLink } from "@/types/components";
import { useEventListener } from "@/composables/useEventListener";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import UserProfile from "@/components/content/user-profile/UserProfile.vue";
import UserSettings from "@/components/content/user-settings/UserSettings.vue";
import TheHeaderLogo from "./TheHeaderLogo.vue";
import TheHeaderNav from "./TheHeaderNav.vue";
import TheHeaderProfile from "./TheHeaderProfile.vue";
import TheHeaderNavDropdown from "./TheHeaderNavDropdown.vue";
import TheHeaderNotifications from "./TheHeaderNotifications.vue";
import TheHeaderHelpIcon from "./TheHeaderHelpIcon.vue";
import MenuIcon from "@/assets/images/icon-menu.svg";

export default defineComponent({
  components: {
    ContentBlock,
    TheHeaderLogo,
    TheHeaderNav,
    TheHeaderProfile,
    TheHeaderNavDropdown,
    TheHeaderNotifications,
    TheHeaderHelpIcon,
    ModalContent,
    UserProfile,
    UserSettings,
    MenuIcon,
  },
  setup() {
    const store = useStore();

    const auth0 = useAuth0();

    const route = useRoute();

    // modals
    const modalState = reactive({
      profile: false,
      settings: false,
    });

    const { isShown, showModal, closeModal } = useModal(modalState);

    const userData = computed(() => store.getters["user/getUserData"]);

    const handleProfile = () => {
      showModal("profile");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `USER_PROFILE_MODAL_SHOW`,
      });
    };

    const handleSettings = () => {
      showModal("settings");

      store.dispatch("tracking/TRACK_EVENT", {
        action: `USER_SETTINGS_MODAL_SHOW`,
      });
    };

    const logout = () => {
      auth0.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
      store.commit("challenge/CLEAR_CHALLENGE");
    };

    const dropdownOptions = [
      { label: "Profile", action: handleProfile },
      { label: "Settings", action: handleSettings },
      { label: "Logout", action: logout },
    ];

    const navLinks: HeaderLink[] = [
      {
        name: "challenges-saved",
        label: "Saved Challenges",
      },
    ];

    const isNavDropdownShown = ref(false);

    const handleNavDropdownToggle = () => {
      isNavDropdownShown.value = !isNavDropdownShown.value;
    };

    const closeNavDropdown = () => {
      isNavDropdownShown.value = false;
    };
    useEventListener(window, "click", closeNavDropdown);

    const hasTour = computed(() => route.meta.hasTour || false);

    const handleTakeATour = () => {
      store.commit("guidedTour/SET_START_TOUR");
    };

    return {
      navLinks,
      isNavDropdownShown,
      hasTour,
      handleTakeATour,
      handleNavDropdownToggle,
      userData,
      dropdownOptions,
      isShown,
      showModal,
      closeModal,
      logout,
    };
  },
});
