// Action enums
export enum ActionTypes {
  GET_ACTIVE_CHALLENGES = "GET_ACTIVE_CHALLENGES",
  GET_RESOLVED_CHALLENGES = "GET_RESOLVED_CHALLENGES",
  GET_ALL_CHALLENGE_TYPES = "GET_ALL_CHALLENGE_TYPES",
  GET_ALL_SUBJECT_TYPES = "GET_ALL_SUBJECT_TYPES",
  GET_USER_CHALLENGE_TYPES = "GET_USER_CHALLENGE_TYPES",
  GET_USER_SUBJECT_TYPES = "GET_USER_SUBJECT_TYPES",
  GET_USER_CHALLENGE_TAGS = "GET_USER_CHALLENGE_TAGS",
  GET_OUTCOME_TAGS = "GET_OUTCOME_TAGS",
  RESTORE_CHALLENGE = "RESTORE_CHALLENGE",
  ADD_CHALLENGE = "ADD_CHALLENGE",
}
