
import { defineComponent, ref, computed, ComponentPublicInstance } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "@/store";
import {
  useVOnboarding,
  VOnboardingStep,
  VOnboardingWrapper,
} from "v-onboarding";
import type { VOnboardingWrapperOptions } from "v-onboarding/src/types/VOnboardingWrapper";
import GuidedTourStep from "./GuidedTourStep.vue";
import { snakeize } from "@/utils/text";

export default defineComponent({
  components: {
    VOnboardingStep,
    VOnboardingWrapper,
    GuidedTourStep,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const auth0 = useAuth0();
    const store = useStore();

    const steps = computed(() =>
      store.getters["guidedTour/getStepsFromName"](props.name),
    );

    const wrapper = ref<ComponentPublicInstance<
      typeof VOnboardingWrapper
    > | null>(null);
    // methods include start, finish, goToStep.
    const { start, finish } = useVOnboarding(wrapper);

    const handleFinish = () => {
      if (auth0.isAuthenticated.value) {
        const nameKey = `onboarded_${snakeize(props.name)}`;
        const hasOnboarded =
          store.getters["user/getUserOnboardingByName"](nameKey);

        // If finish for the first time set onboarded to true
        if (!hasOnboarded) {
          store.dispatch("user/UPDATE_USER_ONBOARDING", {
            key: nameKey,
            data: { value: true },
          });
        }
      }
      finish(); // finish on-boarding
    };

    // default options
    const options: VOnboardingWrapperOptions = {
      popper: {
        placement: "top",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 20],
            },
          },
        ],
      },
      overlay: {
        enabled: false,
      },
    };

    return {
      steps,
      wrapper,
      handleFinish,
      start,
      options,
    };
  },
});
