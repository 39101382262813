
import { defineComponent, onBeforeMount } from "vue";
import { useStore } from "@/store";
import AppContent from "@/components/layout/app-content/AppContent.vue";

export default defineComponent({
  components: {
    AppContent,
  },
  setup() {
    const store = useStore();

    // Ensure sparks and learning aren't stored from a previous challenge
    onBeforeMount(() => {
      store.commit("sparks/CLEAR_SPARKS");
      store.commit("learning/CLEAR_LEARNING");
    });

    return {};
  },
});
