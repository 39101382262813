
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();

    const isLoading = computed(() => store.state.challenge.isLoading);
    const challengeType = computed(() => route.meta.challengeType);
    const challengeIsResolved = computed(
      () => store.getters["challenge/getIsResolved"],
    );
    const challengeIsActive = computed(
      () => store.getters["challenge/getIsActive"],
    );
    const hasIdeas = computed(
      () =>
        store.state.challenge.challenge?.ideas &&
        store.state.challenge.challenge.ideas.length > 0,
    );
    const hasPlan = computed(() => store.getters["challenge/getHasSolution"]);
    const ideaCount = computed(() => store.getters["challenge/getIdeaCount"]);
    const hasResolution = computed(
      () => store.getters["challenge/getHasResolution"],
    );

    // identify partial route names - e.g. routes that also use tabs
    const routeIncludes = (term: string) => {
      const routeName = route.name as string;
      if (routeName?.includes(term)) {
        return routeName;
      }
    };

    const getInspirationState = () => {
      switch (route.name as string) {
        case routeIncludes("challenge-saved-inspiration"):
          return "present";
        case "challenge-saved-ideas":
        case "challenge-saved-plan":
        case "challenge-saved-resolution":
          return "past";
      }
    };
    const getIdeasState = () => {
      switch (route.name) {
        case routeIncludes("challenge-saved-inspiration"):
          if (hasIdeas.value || hasPlan.value) {
            return "past";
          } else {
            return "future";
          }
        case "challenge-saved-ideas":
          return "present";
        case "challenge-saved-plan":
        case "challenge-saved-resolution":
          return "past";
      }
    };
    const getPlanState = () => {
      switch (route.name) {
        case routeIncludes("challenge-saved-inspiration"):
        case "challenge-saved-ideas":
          if (hasPlan.value) {
            return "past";
          } else {
            return "future";
          }
        case "challenge-saved-plan":
          return "present";
        case "challenge-saved-resolution":
          return "past";
      }
    };
    const getResolutionState = () => {
      switch (route.name) {
        case routeIncludes("challenge-saved-inspiration"):
        case "challenge-saved-ideas":
        case "challenge-saved-plan":
          if (challengeIsResolved.value || hasResolution.value) {
            return "past";
          } else {
            return "future";
          }
        case "challenge-saved-resolution":
          return "present";
      }
    };

    const crumbs = computed(() => {
      if (challengeType.value === "new") {
        return [
          {
            name: "challenge-new-inspiration",
            title: "Inspiration",
            state: "present",
          },
          {
            name: "challenge-new-ideas",
            title: `Ideas (${ideaCount.value})`,
            state: "future",
          },
          {
            name: "challenge-new-plan",
            title: "Plan",
            state: "future",
          },
          {
            name: "challenge-new-resolution",
            title: "Resolution",
            state: "future",
          },
        ];
      } else {
        const menu = [
          {
            name: "challenge-saved-ideas",
            title: `Ideas (${ideaCount.value})`,
            state: getIdeasState(),
          },
          {
            name: "challenge-saved-plan",
            title: "Plan",
            state: getPlanState(),
          },
          {
            name: "challenge-saved-resolution",
            title: "Resolution",
            state: getResolutionState(),
          },
        ];
        if (challengeIsActive.value) {
          menu.unshift({
            name: "challenge-saved-inspiration",
            title: "Inspiration",
            state: getInspirationState(),
          });
        }
        return menu;
      }
    });

    return {
      crumbs,
      isLoading,
      hasResolution,
    };
  },
});
