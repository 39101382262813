import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2 mb-8 text-lg md:text-xl" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-cebai-grey-mid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crumbs, (crumb) => {
            return (_openBlock(), _createElementBlock("li", {
              key: crumb.name,
              class: "inline-block mr-2 after:content-['/'] after:text-cebai-grey-mid after:inline-block after:ml-2 last:after:content-['']"
            }, [
              (crumb.state === 'future')
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(crumb.title), 1))
                : (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: { name: crumb.name },
                    class: _normalizeClass({
            'text-cebai-primary font-robotoMedium': crumb.state === 'present',
            'text-cebai-grey-dark hover:text-cebai-base-dark font-robotoMedium':
              crumb.state === 'past',
          })
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(crumb.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "class"]))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}