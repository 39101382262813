
import { defineComponent, PropType } from "vue";
import { HeaderLink } from "@/types/components";
import CloseIcon from "@/assets/images/icon-close.svg";

export default defineComponent({
  components: {
    CloseIcon,
  },
  props: {
    links: {
      type: Array as PropType<HeaderLink[]>,
      required: true,
    },
    isDropdownShown: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
