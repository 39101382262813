import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState, sparksTabs, Spark } from "./state";

interface GetSparkType {
  (type: string): string;
}

interface GetSparks {
  (type: string): Spark[];
}

interface GetLoading {
  (type: string): boolean;
}

interface GetError {
  (type: string): boolean;
}

// Getters types
export type Getters = {
  getSparkTypeFromSlug(state: LocalState): GetSparkType;
  getSparksByType(state: LocalState): GetSparks;
  getLoadingSparksByType(state: LocalState): GetLoading;
  getSparksErrorByType(state: LocalState): GetError;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  // convert (external) spark URL slug to (internal) spark type
  getSparkTypeFromSlug: () => (slug) => {
    const tab = sparksTabs.find((tab) => tab.slug === slug);
    if (tab) {
      return tab.sparkType;
    }
    return "";
  },
  getSparksByType: (state) => (type) => {
    return state.sparks[type] || [];
  },
  getLoadingSparksByType: (state) => (type) => {
    return state.isLoading[type];
  },
  getSparksErrorByType: (state) => (type) => {
    return state.hasError[type];
  },
};
