
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "@/store";
import Toggle from "@/components/ui/toggle/Toggle.vue";

export default defineComponent({
  components: {
    Toggle,
  },
  props: {
    settingsDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    settingsKey: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const userSettings = computed(() => store.getters["user/getUserSettings"]);

    const isSelected = computed(() =>
      props.settingsKey in userSettings.value
        ? userSettings.value?.[props.settingsKey]
        : props.settingsDefault,
    );

    const setting = ref(isSelected.value);

    watch(setting, () => {
      store.dispatch("user/UPDATE_USER_SETTINGS", {
        key: props.settingsKey,
        data: { value: setting.value },
      });

      store.dispatch("tracking/TRACK_EVENT", {
        action: `USER_SETTING_${props.settingsKey.toUpperCase()}_UPDATE_TO_${(!isSelected.value)
          .toString()
          .toUpperCase()}`,
      });
    });

    return {
      setting,
    };
  },
});
