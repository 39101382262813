export type LearningTab = {
  label: string;
  slug: string;
  learningType: string;
};

export const learningTabs: LearningTab[] = [
  {
    label: "Academic",
    slug: "academic",
    learningType: "papers",
  },
  {
    label: "Practices",
    slug: "practices",
    learningType: "resources",
  },
  {
    label: "Case Studies",
    slug: "case-studies",
    learningType: "caseStudies",
  },
];

export type Theme = {
  id: string;
  label: string;
  type: string;
};

export type Entities = {
  [key: string]: string[];
};

export type TwitterData = {
  reply_count: number;
  retweet_count: number;
  like_count: number;
  quote_count: number;
};

export type Resource = {
  id?: never;
  description: string;
  title: string;
  uri: string;
  snippet: string;
  context?: string;
  origin: string;
  published?: string;
  author?: string;
  thumbnail?: string;
  type?: string;
  source?: string;
  duration?: string;
  public_metrics?: TwitterData;
  entities: Entities;
  themes: Theme[];
  isLoading?: boolean;
  learningType: string;
};

export type CaseStudy = {
  id: string;
  description: string;
  title: string;
  challenge: string;
  actions: string;
  outcome: string;
  source?: never;
  uri?: never;
  origin?: never;
  public_metrics?: never;
  published: string;
  challenge_area: string[];
  focus_type: string[];
  score: number;
  tips?: string;
  different_action?: string;
  type: string;
  entities: Entities;
  themes: Theme[];
  isLoading?: boolean;
  learningType: string;
};

// Declare state
export type State = {
  isLoadingPapers: boolean;
  isLoadingResources: boolean;
  isLoadingCaseStudies: boolean;
  hasErrorPapers: boolean;
  hasErrorResources: boolean;
  hasErrorCaseStudies: boolean;
  papers: Resource[];
  resources: Resource[];
  caseStudies: CaseStudy[];
};

// Create initial state
export const state = {
  isLoadingPapers: false,
  isLoadingResources: false,
  isLoadingCaseStudies: false,
  hasErrorPapers: false,
  hasErrorResources: false,
  hasErrorCaseStudies: false,
  papers: [],
  resources: [],
  caseStudies: [],
} as State;
