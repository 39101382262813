import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col mb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Guidance = _resolveComponent("Guidance")!

  return (_ctx.hasGuidance)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prompts, (prompt, promptIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: promptIndex,
            class: "flex"
          }, [
            _createVNode(_component_Guidance, { icon: true }, {
              text: _withCtx(() => [
                _createTextVNode(_toDisplayString(prompt.text), 1)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}